import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    // Customizable Area Start
    Grid,
    Divider,
    Modal,
    TextField
    // Customizable Area End
} from "@material-ui/core";
import SuccesModalScreen from "./SuccessModalScreen.web"
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { createTheme, withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import Switch from "@material-ui/core/Switch";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End


import DashboardController, { Props, TempKpis } from "./TemplateController";
import { Field, Formik } from "formik";


export default class EditTemplate extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            attributes: {
                name = '',
                description = '',
                kpis: { data: kpis = [] } = {},

            } = {},
        } = this.state.showTemplateData;

        // Now, you can access the kpis array and its elements like this:
        let kpiNames = {}


        this.state.tempKpis.forEach((kpi: TempKpis, index: number) => {
            kpiNames = {
                ...kpiNames,
                [`kpiname${index}`]: kpi.name,
                [`kpidescription${index}`]: kpi.description,
                [`points${index}`]: kpi.weightage,
                [`calculation${index}`]: kpi.calculation,
                [`status${index}`]: kpi.status,
            }
        });

        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box style={webStyle.mainWrapper} >
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid className="first" style={webStyle.subWrapper} item>
                            <p style={webStyle.para}>
                                <ArrowBackIosIcon style={webStyle.arrow} />
                                Edit Template
                            </p>

                        </Grid>

                    </Grid>
                </Box>
                <Formik
                    enableReinitialize
                    initialValues={{
                        templateName: name,
                        templateDescription: description,
                        ...kpiNames,

                    }}
                    onSubmit={(values) => {

                        this.handleUpdateKpiForm(values)
                    }}
                >
                    {({ handleSubmit, handleChange, values, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>

                            <Grid container spacing={2} style={webStyle.marginTop20}>
                                <Grid item xs={6} style={webStyle.paddingLeft}>
                                    <Typography style={webStyle.fieldLabel} >Template Name</Typography>
                                    <Field
                                        type="text"
                                        name="templateName"
                                        as={TextareaAutosize}
                                        onChange={handleChange}
                                        data-testid="templateName"
                                        aria-label="empty textarea"
                                        placeholder="template name"
                                        style={webStyle.inputField}
                                    />
                                </Grid>
                                <Grid item xs={6} style={{ paddingLeft: "32px", paddingRight: "34px" }}>
                                    <Typography style={{ color: "#000", fontSize: "18px", fontWeight: 700 }}>Template Description</Typography>

                                    <Field
                                        type="text"
                                        name="templateDescription"
                                        as={TextareaAutosize}
                                        data-testid="templateDescription"
                                        onChange={handleChange}
                                        aria-label="minimum height"
                                        minRows={3}
                                        placeholder="template description"
                                        style={webStyle.inputDescription}
                                    />
                                </Grid>

                                <Grid item xs={12} style={webStyle.listHeader}>
                                    <Grid item style={webStyle.listLable}>
                                        <Typography variant="h6">KPI List</Typography>
                                    </Grid>
                                    <Grid item style={webStyle.listButton}>
                                        <Button data-testid="addKpi" onClick={() => this.handleOpenAddKpiModel()} variant="contained" style={webStyle.listBtnContainer}>Add KPI</Button>
                                    </Grid>
                                </Grid>
                                <Box style={webStyle.parent2} >
                                    <Grid container justifyContent="space-between" alignItems="center" style={{ height: "100%", }}>
                                        <Grid className="second" style={webStyle.progressContiner} item>
                                            <CircularProgressbar value={44} text={`${100}%`} strokeWidth={10}
                                                styles={buildStyles({
                                                    pathColor: `#FDD001`,
                                                    textColor: '#333',
                                                    trailColor: '#d6d6d6',
                                                })}
                                            />
                                        </Grid>

                                        <Grid container style={webStyle.flexCol as React.CSSProperties}>
                                            <Grid item>
                                                <Typography style={webStyle.label2}>Total Weightage</Typography>
                                            </Grid>
                                            <Grid className="third" style={webStyle.description2} item>
                                                This Weightage Is The Total Sum Of All The KPIS Available In A Template, And Is Affected On Addition And Reduction Of KPI. This Total Always Needs To Be 100.
                                            </Grid>
                                        </Grid>



                                    </Grid>
                                </Box>

                            </Grid>
                            <Divider
                                style={webStyle.dividerCustom}
                            />

                            {this.state.tempKpis.map((kpi: TempKpis, index: number) => (
                                <Box style={webStyle.parent3}>
                                    <Box key={index} className="kpi-wrapper" style={{ display: "flex", flexWrap: "wrap", margin: "auto", padding: "0 35px" }}>
                                        <Box className="kpi-name-block" style={{ padding: "0 12px", width: "100%", flex: "0 0 auto", maxWidth: "20%" }}>
                                            <Typography style={{ color: "#000", fontSize: "18px", fontWeight: 700 }}>
                                                KPI Name
                                            </Typography>

                                            <Field
                                                style={{
                                                    padding: "16px",
                                                    height: "56px",
                                                    width: "100%",
                                                    // maxWidth: "199px",
                                                    marginTop: "16px",
                                                    borderRadius: "12px",
                                                    background: "#F3F4F5",
                                                    border: "0.25px solid #979797"
                                                }}
                                                data-testid="kpiName"
                                                onChange={handleChange}
                                                type="text"
                                                name={`kpiname${index}`}
                                                aria-label="empty textarea"
                                                placeholder="kpi name"
                                                disabled={true}
                                            />

                                        </Box>
                                        <Box className="kpi-des-block" style={{ padding: "0 12px", width: "100%", flex: "0 0 auto", maxWidth: "20%" }}>
                                            <Typography style={{ color: "#000", fontSize: "18px", fontWeight: 700 }}>
                                                KPI Description
                                            </Typography>

                                            <Field
                                                style={{
                                                    height: "100%",
                                                    width: "100%",
                                                    // maxWidth:"411px",
                                                    borderRadius: "12px",
                                                    background: "#F3F4F5",
                                                    border: "0.25px solid #979797",
                                                    marginTop: "16px",
                                                    padding: "10px",

                                                }}
                                                disabled={true}
                                                as="div" // Use div instead of TextareaAutosize to render HTML
                                                dangerouslySetInnerHTML={{ __html: values[`kpidescription${index}` as keyof typeof values] }}
                                                data-testid="kpiDescription"
                                                onChange={handleChange}
                                                type="text"
                                                name={`kpidescription${index}`}
                                                aria-label="minimum height"
                                                placeholder="kpi description"
                                            />
                                        </Box>
                                        <Box className="kpi-weightes-block" style={{ padding: "0 12px", width: "100%", flex: "0 0 auto", maxWidth: "10%" }} >
                                            <Typography style={{ color: "#000", fontSize: "18px", fontWeight: 700, marginBottom: "16px" }}>
                                                Weightage
                                            </Typography>

                                            <div className="empty-typografy" style={{ position: "relative", maxWidth: "265px", width: "100%" }}>
                                                <div style={{
                                                    padding: "16px 16px 16px 80px",
                                                    height: "70px",
                                                    width: "100%",
                                                    borderRadius: "12px",
                                                    background: "#F3F4F5",
                                                    border: "0.25px solid #979797"
                                                }} aria-label="empty textarea" />
                                                <Field
                                                    label="10 points"
                                                    name={`points${index}`}
                                                    data-testid="points"
                                                    onChange={handleChange}
                                                    disabled={true}
                                                    style={{
                                                        overflow: "unset",
                                                        width: "71px",
                                                        height: "30px",
                                                        borderRadius: "4px",
                                                        border: "0.5px solid #FDD100",
                                                        background: "#FFF",
                                                        position: "absolute",
                                                        top: "50%",
                                                        left: "50%",
                                                        
                                                        transform: "translate(-39px, -50%)"
                                                    }} />
                                            </div>
                                        </Box>
                                        <Box className="kpi-weightes-block" style={{ padding: "0 12px", width: "100%", flex: "0 0 auto", maxWidth: "20%" }} >
                                            <Typography style={{ color: "#000", fontSize: "18px", fontWeight: 700, marginBottom: "16px" }}>
                                                Goal Calculation
                                            </Typography>

                                            <Field
                                                label="10 points"
                                                name={`calculation${index}`}
                                                data-testid="calculation"
                                                onChange={handleChange}
                                                disabled={true}
                                                style={{
                                                    padding: "16px 16px",
                                                    height: "70px",
                                                    width: "100%",
                                                    borderRadius: "12px",
                                                    background: "#F3F4F5",
                                                    border: "0.25px solid #979797"
                                                }} />
                                        </Box>
                                        <Box className="kpi-weightes-block" style={{ padding: "25px 0px", width: "100%", flex: "0 0 auto", maxWidth: "10%" }} >
                                            <div className="empty-typografy" style={{ position: "relative", maxWidth: "265px", width: "100%" }}>
                                                <Typography style={{ color: "#000", fontSize: "18px", fontWeight: 700, marginBottom: "16px" }}>
                                                </Typography>
                                                <Field
                                                    label="10 points"
                                                    name={`status${index}`}
                                                    data-testid="status"
                                                    onChange={handleChange}
                                                    style={{
                                                        padding: "16px 53px",
                                                        height: "70px",
                                                        color:values[`status${index}` as keyof typeof values]  === "Preparing" ? "#312DFF" : "#1FA625",
                                                        width: "100%",
                                                        borderRadius: "12px",
                                                        background:values[`status${index}` as keyof typeof values]  === "Preparing" ? "#9999FF" : "#91FFA3",
                                                        border:values[`status${index}` as keyof typeof values] === "Preparing" ? "0.25px solid #9191FF": "0.25px solid #65AE63"
                                                    }} />
                                            </div>
                                        </Box>
                                        <Box className="kpi-weightes-block" style={{ padding: "25px 12px", width: "100%", flex: "0 0 auto", maxWidth: "10%" }} >
                                            <MoreVertIcon fontSize="large"/>
                                        </Box>

                                    </Box>
                                </Box>
                            ))}


                            <div style={webStyle.editformButtons}>
                                <Button type="submit" data-testid="doneButton" style={webStyle.buttonFormSubmit} disabled={isSubmitting}
                                >Submit</Button>
                                <div style={webStyle.buttonFormCancel}>
                                    <Button
                                        variant="outlined"
                                        style={{ borderRadius: "7px", width: "114%" }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </form>)}
                </Formik>
                <Modal
                    style={{
                        overflowY: 'scroll',
                        maxHeight: '100%',
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        justifyItems: "center"
                    }}

                    open={this.state.togglemodel}
                    onClose={this.handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div style={webStyle.paper as React.CSSProperties}>

                        <h2 id="simple-modal-title" style={{ color: "#000", paddingTop: 40, paddingBottom: 20 }}>Add KPI</h2>
                        <div>
                            <Typography style={{ fontWeight: "bold", color: "#000" }}>KPI Name</Typography>
                        </div>
                        <div style={{ paddingTop: "10px", maxWidth: "369px", width: "100%" }}>
                            <TextField
                                // id="outlined-multiline-static"
                                style={{ borderRadius: "20px", background: "#F3F4F5", borderWidth: 1, borderColor: "" }}
                                fullWidth
                                name="title"
                                variant="outlined"
                                value={this.state.templateData.name}
                            />
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                            <Typography style={{ fontWeight: "bold", color: "#000" }}>
                                KPI Description{" "}
                            </Typography>
                        </div>
                        <div style={{ paddingTop: "10px" }}>
                            <TextField
                                // id="outlined-multiline-static"
                                style={{ borderRadius: "20px", background: "#F3F4F5" }}
                                value={this.state.templateData.description}
                                fullWidth

                                name="description"
                                multiline
                                rows={4}
                                variant="outlined"
                            />
                        </div>


                        {this.state.tempKpis.map((feature: TempKpis, index: number) => (
                            <React.Fragment key={index}>

                                <div style={{ paddingTop: "10px" }}>
                                    <Typography style={{ fontWeight: "bold", color: "#000" }}>KPI Name</Typography>
                                </div>
                                <div style={{ paddingTop: "10px", maxWidth: "369px", width: "100%" }}>
                                    <TextField
                                        data-test-id={`feature-${index}`}
                                        id={`feature-${index}`}
                                        style={{ backgroundColor: "#f1f3f5" }}
                                        fullWidth
                                        variant="outlined"
                                        value={kpis.name}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleFeatureChange(index, e.target.value)}

                                    />
                                </div>
                                <div style={{ paddingTop: "10px" }}>
                                    <Typography style={{ fontWeight: "bold", color: "#000" }}>KPI Description</Typography>
                                </div>
                                <div style={{ paddingTop: "10px" }}>
                                    <TextField
                                        data-test-id={`description-${index}`}
                                        id={`description-${index}`}
                                        style={{ backgroundColor: "#f1f3f5" }}
                                        fullWidth
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        value={kpis.description}
                                        onChange={(e: any) => this.handleDescriptionChange(index, e.target.value)}
                                    />
                                </div>
                            </React.Fragment>
                        ))}

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                paddingTop: "10px",
                            }}
                        >
                            <button
                                data-test-id='addFeatureButton'
                                onClick={this.handleAddFeature} // Define handleAddFeature function
                                style={webStyle.addFeatureButton as React.CSSProperties}
                            >
                                + Add KPI
                            </button>
                        </div>



                        <div style={webStyle.footerStyle}>
                            <Button data-test-id="submit"  style={webStyle.buttonsubmit}
                            >Submit</Button>
                            <div style={webStyle.buttoncancel}>
                                <Button
                                    onClick={this.handleClose}
                                    data-testid="closeButton"
                                    variant="outlined"
                                    style={{ borderRadius: "7px", width: "114%" }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <SuccesModalScreen
                    open={this.state.open}
                    onClose={this.handleOpenModal}
                    message="KPI Has Been Successfully Added." navigation={undefined} id={""} />
                <Modal
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                    open={this.state.disableKpiModel}
                    onClose={this.handleOpenKpiModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <>
                        <div style={webStyle.kpiModel as React.CSSProperties}>

                            <Typography style={{
                                color: "#000",
                                fontSize: "18px",
                                fontStyle: "normal", fontWeight: 700, lineHeight: "normal", padding: "72px 0px 79px 66.93px"
                            }}>Are you sure you want to disable/enable {this.state.selectedKPIName}? </Typography>
                            <div style={webStyle.KpiModelfooterStyle}>
                                <Button data-testid="disableSwitchResponse" style={webStyle.buttonsubmit}
                                >Submit</Button>
                                <div style={webStyle.buttoncancel}>
                                    <Button

                                        onClick={this.handleOpenKpiModal}
                                        variant="outlined"
                                        style={{
                                            width: "100%",
                                            maxWidth: "101px",
                                            borderRadius: "8px",
                                            height: "40px",
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </>
                </Modal>

            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const webStyle = {
    mainWrapper: {
        background: "#F3F4F5", height: "64px", marginTop: "32px", marginRight: "-20px"
    },
    arrow: { marginRight: "42px" },
    marginTop20: { marginTop: '20px' },
    inputField: {
        padding: "16px",
        width: "100%",
        marginTop: "16px",
        borderRadius: "12px",
        background: "#F3F4F5",
        border: " 0.5px solid #F3F4F5"
    },
    inputDescription: {
        height: "138px",
        width: "727px",
        borderRadius: "12px",
        background: "#F3F4F5",
        border: " 0.5px solid #F3F4F5",
        marginTop: "16px",
        padding: "10px",
    },
    dividerCustom: {
        width: "100%",
        height: "1px",
        strokeWidth: "1px",
        stroke: "#DBDBDB",
        margin: "56px 0px 32px", // Adjust the margin as needed
    },
    description2: { color: "#000", fontSize: "18px", fontWeight: 400 },
    flexCol: { flex: "1", flexDirection: "column" },
    label2: { color: "black", fontSize: "21px", fontWeight: 100, marginBottom: "24px" },
    parent2: { width: "100%", padding: "45px", margin: "28px 32px", background: "#fff", borderRadius: "11px", boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.09)" },
    parent3: { width: "96%", padding: "1% 0 4% 0", margin: "28px 32px", background: "#fff", borderRadius: "11px", boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.09)" },
    listLable: { display: 'flex', alignItems: 'center' },
    progressContiner: { marginRight: "30px", width: '70px', height: "70px", flex: "0 0 80px" },
    listButton: { alignItems: 'center', justifyContent: 'flex-end' },
    listBtnContainer: { width: "135px", height: "38px", background: "#FFD11A", color: "#000", fontSize: "18px", fontWeight: 700 },
    listHeader: { marginTop: '32px', width: "100%", height: "64px", background: "#F3F4F5", display: "flex", justifyContent: "space-between", padding: "13px 32px" },
    fieldLabel: { color: "#000", fontSize: "18px", fontWeight: 700 },
    paddingLeft: { paddingLeft: "34px" },
    para: { color: "black", fontSize: "18px", fontWeight: 400, paddingLeft: "14px", display: "flex", alignItems: "center" },
    subWrapper: {
        padding: '0 0px 21px 38px',
        width: "100%",
        maxWidth: "80%",
        justifyContent: "flex-start",
    },
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
    paper: {
        position: "absolute",
        width: 600,
        backgroundColor: theme.palette.background.paper,
        // boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "550px",
        overflowY: "auto",
        overflowX: "hidden",
    },
    paperModel: {
        position: "absolute",
        width: 600,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    kpiModel: {
        position: "absolute",

        backgroundColor: theme.palette.background.paper,
        borderRadius: "12px",

        padding: theme.spacing(2, 4, 3),
        width: "583px",
        height: "288px",



        boxShadow: "0px 1px 14px 0px rgba(0, 0,0,0.7)"
    },
    addFeatureButton: {
        backgroundColor: "#f7da08",
        height: "50px",
        width: "26%",
        fontWeight: "bold",
        borderRadius: "13px",
        border: "none"
    },
    editformButtons: {
        display: "flex",
        justifyContent: "flex-start",
        marginTop: "1rem",
        padding: "12px 0px 12px 32px"
    },


    footerStyle: {
        display: "flex",
        justifyContent: "flex-start",
        marginTop: "1rem",
        paddingTop: "50px"
    },
    KpiModelfooterStyle: {
        display: "flex",
        justifyContent: "center",
    },
    buttonFormSubmit: {
        backgroundColor: "#f7da08",
        borderRadius: "8px",
        width: "100%",
        maxWidth: "101px",
        height: "40px"
    },
    buttonFormCancel: {
        paddingLeft: "18px",
        width: "100%",
        maxWidth: "101px",
        borderRadius: "8px",
        height: "40px",

    },
    buttonsubmit: {
        backgroundColor: "#f7da08",
        borderRadius: "7px",
        width: "19%"
    },
    buttoncancel: {
        paddingLeft: "18px"
    },
};
export const AntSwitch = withStyles(theme => ({
    root: {
        width: 40,
        height: 20,
        padding: 0,
        display: "flex"
    },
    switchBase: {
        padding: 2,
        // color: theme.palette.grey[500],
        "&$checked": {
            transform: "translateX(20px)",
            color: theme.palette.common.white,
            "& + $track": {
                opacity: 1,
                backgroundColor: "#6192c8",
                borderColor: theme.palette.primary.main
            }
        }
    },
    thumb: {
        width: 15,
        height: 17,
        boxShadow: "none"
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 1,
        opacity: 1,
        backgroundColor: "#bcbeca"
    },
    checked: {}
}))(Switch);
// Customizable Area End
