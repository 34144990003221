import React from "react";

import {
    Box,
    Typography,
    Grid,
    // Customizable Area Start
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Tooltip,
    Checkbox
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";


const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

// Customizable Area End

import AddKpiController, {
    Props
} from "./AddKpiController";

export default class AddKpi extends AddKpiController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <Box style={webStyles.maincontainer}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid className="first" style={webStyles.leftContainer} item>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item style={webStyles.rowDisplay}>
                                <ArrowBackIosIcon style={{ fontSize: "26px" }} />
                                <span
                                    style={webStyles.labelStyle1}
                                >
                                    Edit Template
                                </span>
                            </Grid>
                            <Grid item style={webStyles.rowDisplay}>
                                <ArrowBackIosIcon style={webStyles.labelStyle1} />
                                <span style={webStyles.labelStyle as React.CSSProperties}>
                                    Add KPI
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="second" style={webStyles.emptyContainer} item />
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    style={webStyles.informationContainer as React.CSSProperties}
                >
                    <Grid
                        className="first"
                        item
                        style={webStyles.informationSubContainer}
                        xs={12}
                    >
                        <FormControl component="fieldset">
                            <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                <span style={webStyles.labelStyle as React.CSSProperties}>Type</span>
                                <Typography variant="body1" style={{ color: "red" }}>*</Typography>
                            </FormLabel>
                            <RadioGroup aria-label="type" data-test-id="radiogroupInput" name="type" value={this.state.type} onChange={this.handleChange} row>
                                <FormControlLabel value="core" control={<Radio style={webStyles.themeColor} />} label="Core" />
                                <FormControlLabel value="advance" control={<Radio style={webStyles.themeColor} />} label="Advance" />
                                <FormControlLabel value="timing" control={<Radio style={webStyles.themeColor} />} label="Timing" />
                                <FormControlLabel value="report" control={<Radio style={webStyles.themeColor} />} label="Report" />
                            </RadioGroup>
                        </FormControl>

                    </Grid>
                    <Grid
                        className="second"
                        item
                        style={webStyles.informationSubContainer}
                        xs={12}
                    >
                        <FormControl component="fieldset">
                            <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                <span style={webStyles.labelStyle as React.CSSProperties}>Basic Details</span>
                                <Typography variant="body1" style={{ color: "red" }}>*</Typography>
                            </FormLabel>
                            <Grid container style={{ display: "flex" }} xs={12}>
                                <Grid item xs={4}>
                                    <div className="form-block" id="signupBlock3">
                                        <form>
                                            <div
                                                className="form-field"
                                                id="signupform"
                                                style={webStyles.topThirty}
                                            >
                                                <span style={webStyles.labelStyle as React.CSSProperties}>Title</span>
                                                <div
                                                    className="control"
                                                    id="signupfieldcontrol"
                                                    style={webStyles.marginLeft0}
                                                >
                                                    <input
                                                        type="text"
                                                        data-test-id="txtInputEmail"
                                                        style={webStyles.inputField}
                                                        className="control-input"
                                                        placeholder="KPI-1"
                                                        disabled
                                                        name="email"
                                                        id="pronounce-control"
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Grid>
                                {this.state.type === "core" &&
                                    <Grid item xs={4}>
                                        <div className="form-block" id="signupBlock3">
                                            <form>
                                                <div
                                                    className="form-field"
                                                    id="signupform"
                                                    style={webStyles.topThirty}
                                                >
                                                    <span style={webStyles.labelStyle as React.CSSProperties}>Description</span>
                                                    <div
                                                        className="control"
                                                        id="signupfieldcontrol"
                                                        style={webStyles.marginLeft0}
                                                    >
                                                        <textarea
                                                            data-test-id="txtInputDescription"
                                                            value={this.state.description}
                                                            onChange={this.handleDescriptionChange}
                                                            style={webStyles.inputField}
                                                            rows={5}
                                                            className="control-input"
                                                            placeholder="Type something here..."
                                                            maxLength={1000}
                                                            name="brief_about_employee"
                                                            id="brief_about_employee-control"
                                                        />
                                                        <div style={{ textAlign: "end", width: "90%" }}>
                                                            {this.state.description.length}/1000
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </Grid>
                                }
                                <Grid item xs={4}>
                                    <div className="form-block" id="signupBlock3">
                                        <form>
                                            <div
                                                className="form-field"
                                                id="signupform"
                                                style={webStyles.topThirty}
                                            >
                                                <span style={webStyles.labelStyle as React.CSSProperties}>Points(Weightage)</span>
                                                <div
                                                    className="control"
                                                    id="signupfieldcontrol"
                                                    style={webStyles.marginLeft0}
                                                >
                                                    <input
                                                        type="text"
                                                        data-test-id="txtInputEmail"
                                                        style={webStyles.inputField}
                                                        className="control-input"
                                                        placeholder="20 points"
                                                        name="email"
                                                        id="pronounce-control"
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </Grid>
                            </Grid>
                        </FormControl>

                    </Grid>
                    {this.state.type === "core" &&
                        <>
                            <Grid
                                className="third"
                                item
                                style={webStyles.informationSubContainer}
                                xs={12}
                            >
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                        <span style={webStyles.labelStyle as React.CSSProperties}>Calculation Type</span>
                                        <Typography variant="body1" style={{ color: "red" }}>*</Typography>
                                        <LightTooltip
                                            title={
                                                <Typography style={{ width: "180px", color: "black" }}>
                                                    Monthly Goal is only applicable for Automatic Calculation Type
                                                </Typography>
                                            }
                                            placement="right-start"
                                            arrow
                                        >
                                            <div style={webStyles.infIcon}>
                                                <span style={webStyles.labelStyle1}>i</span>
                                            </div>
                                        </LightTooltip>
                                    </FormLabel>
                                    <div className="checkbox" style={webStyles.rowDisplay}>
                                        <Checkbox
                                            checked={this.state.automatic}
                                            onChange={this.handleAutomatic}
                                            style={webStyles.themeColor}
                                            name="Automatic"
                                            data-test-id='btnautomatic'
                                        />

                                        <label >Automatic</label>
                                    </div>
                                </FormControl>

                            </Grid>
                            {this.state.automatic &&
                                <>
                                    <Grid
                                        className="first"
                                        item
                                        style={webStyles.informationSubContainer}
                                        xs={12}
                                    >
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                                <span style={webStyles.labelStyle as React.CSSProperties}>Monthly Goal Details</span>
                                                <Typography variant="body1" style={{ color: "red" }}>*</Typography>
                                            </FormLabel>
                                        </FormControl>
                                        <Grid container style={{ display: "flex" }} xs={12}>
                                            <Grid item xs={3}>
                                                <div className="form-block" id="signupBlock3">
                                                    <form>
                                                        <div
                                                            className="form-field"
                                                            id="signupform"
                                                            style={webStyles.topThirty}
                                                        >
                                                            <span style={webStyles.labelStyle as React.CSSProperties}>Goal Type</span>
                                                            <div
                                                                className="control"
                                                                id="signupfieldcontrol"
                                                                style={webStyles.marginLeft0}
                                                            >
                                                                <select
                                                                    data-test-id="txtInputGoal"
                                                                    name="role"
                                                                    id="role"
                                                                    style={webStyles.inputField}
                                                                    onChange={(event) => this.handleGoal(event)}
                                                                    value={this.state.goaltype}

                                                                >
                                                                    <option value="">Select Goal Type</option>
                                                                    <option id="number" value="number">
                                                                        Number
                                                                    </option>
                                                                    <option id="number" value="percentage">
                                                                        Percentage
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </Grid>
                                            {this.state.goaltype === "number" &&
                                                <Grid item xs={3}>
                                                    <div className="form-block" id="signupBlock3">
                                                        <form>
                                                            <div
                                                                className="form-field"
                                                                id="signupform"
                                                                style={webStyles.topThirty}
                                                            >
                                                                <span style={webStyles.labelStyle as React.CSSProperties}>Calculation Formulas</span>
                                                                <div
                                                                    className="control"
                                                                    id="signupfieldcontrol"
                                                                    style={webStyles.marginLeft0}
                                                                >
                                                                    <select
                                                                        data-test-id="txtInputRole"
                                                                        name="role"
                                                                        id="role"
                                                                        style={webStyles.inputField}

                                                                    >
                                                                        <option value="">Select Formula</option>
                                                                        <option id="sum" value="sum">
                                                                            Sum
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>

                                                </Grid>
                                            }
                                            <Grid item xs={6}>
                                                <div className="form-block" id="signupBlock3">
                                                    <form>
                                                        <div
                                                            className="form-field"
                                                            id="signupform"
                                                            style={webStyles.topThirty}
                                                        >
                                                            {this.state.goaltype === "number" ?
                                                                <span style={webStyles.labelStyle as React.CSSProperties}>Goal Value (In numbers)</span>
                                                                :
                                                                <span style={webStyles.labelStyle as React.CSSProperties}>Goal Value (In percentage)</span>
                                                            }
                                                            <div
                                                                className="control"
                                                                id="signupfieldcontrol"
                                                                style={webStyles.marginLeft0}
                                                            >
                                                                <input
                                                                    type="text"
                                                                    data-test-id="txtInputEmail"
                                                                    style={webStyles.inputField}
                                                                    className="control-input"
                                                                    placeholder="1,000,000"
                                                                    name="email"
                                                                    id="pronounce-control"
                                                                />
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        className="first"
                                        item
                                        style={webStyles.informationSubContainer}
                                        xs={12}
                                    >
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                                <span style={webStyles.labelStyle as React.CSSProperties}>Analytics and Run rate</span>
                                                <Typography variant="body1" style={{ color: "red" }}>*</Typography>
                                            </FormLabel>
                                        </FormControl>
                                        <Grid container style={{ display: "flex", alignItems: "last baseline" }}>
                                            <Grid item xs={3}>
                                                <div className="checkbox" style={webStyles.rowDisplay}>
                                                    <Checkbox
                                                        checked={this.state.runRate}
                                                        onChange={this.handleRunrate}
                                                        style={webStyles.themeColor}
                                                        name="Runrate"
                                                        data-test-id='btnRunrate'
                                                    />

                                                    <label >Run rate</label>
                                                </div>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <div className="checkbox" style={webStyles.rowDisplay}>
                                                    <Checkbox
                                                        checked={this.state.analytics}
                                                        onChange={this.handleAnalytics}
                                                        style={webStyles.themeColor}
                                                        name="Runrate"
                                                        data-test-id='btnAnalytics'
                                                    />

                                                    <label >Part of Analytics</label>
                                                </div>
                                            </Grid>
                                            {this.state.runRate && this.state.analytics &&
                                                <Grid item xs={6}>
                                                    <div className="form-block" id="signupBlock3">
                                                        <form>
                                                            <div
                                                                className="form-field"
                                                                id="signupform"
                                                                style={webStyles.topThirty}
                                                            >
                                                                <div
                                                                    className="control"
                                                                    id="signupfieldcontrol"
                                                                    style={webStyles.marginLeft0}
                                                                >
                                                                    <select
                                                                        data-test-id="txtInputRole"
                                                                        name="role"
                                                                        id="role"
                                                                        style={webStyles.inputField}

                                                                    >
                                                                        <option value="">Select</option>
                                                                        <option id="sum" value="sum">
                                                                            Sales
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>

                                                </Grid>
                                            }
                                        </Grid>

                                    </Grid>
                                </>
                            }
                        </>
                    }
                </Grid>
                <Grid style={webStyles.buttonContainer}>
                    <div className="form-field text-center" id="signupBlock4">
                        <div className="control" id="signupfieldcontrol2">
                            <button
                                type="button"
                                data-test-id="btnEmailLogIn"
                                style={webStyles.cotinueBtn}
                                className="button submit"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                    <div className="form-field text-center" id="signupBlock4">
                        <div className="control" id="signupfieldcontrol2">
                            <button
                                type="button"
                                data-test-id="btnCancel"
                                style={webStyles.cancelBtn}
                                className="button submit"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Grid>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyles = {
    maincontainer: {
        background: "#F3F4F5",
        height: "64px",
        marginTop: "32px",
        marginRight: "-20px"
    },
    leftContainer: {
        padding: "19px 0px 21px 38px"
    },
    emptyContainer: { padding: "0px 44px 0px 0px" },
    informationContainer: { padding: "0 32px", alignItems: "baseline", flexDirection: "column" },
    informationSubContainer: { marginTop: "24px", width: "800px" },
    topThirty: { marginTop: "30px" },
    flexDisplay: { display: "flex" },
    container: {
        width: "100%",
        height: "100%"
    },
    root: {
        minWidth: 275
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)"
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    labelStyle: {
        color: "black",
        fontSize: "18px",
        fontWeight: "bold"
    },
    rowDisplay: { display: "flex", alignItems: "center" },
    labelStyle1: {
        color: "black",
        fontSize: "18px",
    },
    inputField: {
        width: "90%",
        background: 'transparent',
        border: '0.5px solid #979797'
    },
    cotinueBtn: {
        margin: "10px 0 0"
    },
    marginLeft0: {
        marginLeft: 0,
        marginTop: 10
    },
    cancelBtn: {
        background: "transparent",
        margin: "10px 0 0"
    },

    buttonContainer: { display: "flex", gap: "20px", margin: "0 40px" },
    themeColor: {
        color: "#FDD100"
    },
    headerWithmandatory: {
        display: "flex",
        alignItems: "baseline",
        gap: "4px"
    },
    infIcon: { display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid #FDD100", borderRadius: "70%", width: "30px", height: "30px", marginLeft: "10px" }
};
// Customizable Area End
