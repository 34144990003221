import React from "react";
// Customizable Area Start
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Box
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Customizable Area End
import AddDkrController, { Props } from "./AddDkrController";
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  }
});
export default class ProductDkr extends AddDkrController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { typing } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.ProductDkrmaincontainer}>
          <Grid 
          justifyContent="space-between" 
          container 
          alignItems="center">
            <Grid className="first" style={webStyle.ProductDkrleftSide} item>
              <Grid 
              spacing={2} 
              container 
              alignItems="center">
                <Grid item style={webStyle.ProductDkrrowarrowDisplay}>
                  <ArrowBackIosIcon 
                  data-test-id="nextButton1" 
                  style={{ fontSize: "24px", color: "black" }} 
                  onClick={this.navigateToEditTemplate2} />
                  <span style={{...webStyle.ProductDkrHeadStyleTop1,fontWeight: 400}}>
                    Edit Template
                  </span>
                </Grid>
                <Grid item style={webStyle.ProductDkrrowarrowDisplay}>
                  <ArrowBackIosIcon 
                  style={{...webStyle.ProductDkrHeadStyleTop1,color:"#B2B2B2",fontSize:"16px"}} 
                  />
                  <span 
                  style={{...webStyle.ProductDkrlabelStyleoptionhead as React.CSSProperties,fontWeight:700,fontSize:"18px"}}
                  >
                    Add DKR Details
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid 
            style={webStyle.ProductDkrnullContainer} 
            className="second" 
            item 
            />
          </Grid>
          <Grid
            alignItems="center"
            container
            style={webStyle.ProductDkrinformContainer as React.CSSProperties}>
            <Grid
              item
              className="first"
              style={webStyle.ProductDkrinformSecondContainerHead}
              xs={12}>
              <div>
                <FormControl component="fieldset">
                  <FormLabel 
                  component="legend" 
                  style={webStyle.ProductdkrheaderMain10}>
                    <span 
                    style={webStyle.ProductDkrHeadtopStyle22 as React.CSSProperties}
                    >KPI Details</span>
                  </FormLabel>
                </FormControl>
              </div>
              <div 
              className="form-field text-center" 
              id="signupBlock4">
                <div 
                className="control" 
                id="signupfieldcontrol2">
                  <Button 
                  data-test-id="EditButton" 
                  style={webStyle.ProductDkrEditbutton as React.CSSProperties} 
                  onClick={this.navigateToEditKpi}>
                    Edit KPI Details
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              style={webStyle.ProductDkrinformationSubject}
              item
              className="first"
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel 
                component="legend" 
                style={webStyle.ProductdkrheaderMain10}>
                  <span style={{ ...webStyle.ProductDkrlabelStyleoptionhead as React.CSSProperties, fontSize: "22px" ,fontWeight: 500}}>Type</span>
                </FormLabel>
                <span 
                style={{...webStyle.ProductDkrblurvalueStyle as React.CSSProperties,fontWeight:400}}
                >Productivity</span>
              </FormControl>
            </Grid>
            <Grid
              item
              className="second"
              style={webStyle.ProductDkrinformationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" 
                style={{ ...webStyle.ProductdkrheaderMain10, marginTop: "30px" }}>
                  <span style={{...webStyle.ProductDkrlabelStyleoptionhead as React.CSSProperties,fontWeight:500,fontSize:"22px"}}>Basic Details</span>
                </FormLabel>
                <Grid container style={{ display: "flex" }} xs={12}>
                  <Grid item xs={3}>
                    <div className="form-block" id="signupBlock3">
                      <form>
                        <div
                          className="form-field"
                          id="signupform"
                          style={webStyle.ProductDkrtopdescThirty}>
                          <span 
                          style={webStyle.ProductDkrTopstyleHeader as React.CSSProperties}>Title</span>
                          <div
                            id="signupfieldcontrol"
                            className="control"
                            style={webStyle.ProductDkrmarginLeft0}>
                            <span style={webStyle.ProductDkrblurvalueStyle as React.CSSProperties}>Productivity</span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Grid>
                  <>
                    <Grid item xs={6}>
                      <div 
                      id="signupBlock3"
                      className="form-block" 
                      >
                        <form>
                          <div
                            id="signupform"
                            className="form-field"
                            style={webStyle.ProductDkrtopdescThirty}
                          >
                            <span style={webStyle.ProductDkrTopstyleHeader as React.CSSProperties}>Description</span>
                            <div
                              id="signupfieldcontrol"
                              className="control"
                              style={webStyle.ProductDkrmarginLeft0}
                            >
                              <span style={{...webStyle.ProductDkrblurvalueStyle as React.CSSProperties,fontWeight:400}}>Daily Productivity Goal (Mins) : Avg 360 <br />Per Day<br />Monthly Avg Productivity Points <br />compared Goal</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div 
                      id="signupBlock3"
                      className="form-block" 
                      >
                        <form>
                          <div
                            className="form-field"
                            style={{...webStyle.ProductDkrtopdescThirty,width:"max-content"}}
                            id="signupform"
                          >
                            <span style={webStyle.ProductDkrTopstyleHeader as React.CSSProperties}>Points (Weightage)</span>
                            <div
                              id="signupfieldcontrol"
                              className="control"
                              style={webStyle.ProductDkrmarginLeft0}
                            >
                              <span style={{...webStyle.ProductDkrblurvalueStyle as React.CSSProperties,fontWeight:400}}>10 points</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                  </>
                </Grid>
              </FormControl>
            </Grid >
            <Grid
              className="third"
              item
              style={webStyle.ProductDkrinformationSubject}
              xs={12}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.ProductdkrheaderMain10}>
                  <span style={webStyle.ProductDkrlabelStyleoptionhead as React.CSSProperties}>Calculation Type</span>
                </FormLabel>
                <div className="form-block" id="signupBlock3">
                  <form>
                    <div
                      id="signupform"
                      style={webStyle.ProductDkrtopdescThirty}
                      className="form-field"
                    >
                      <span style={webStyle.ProductDkrTopstyleHeader as React.CSSProperties}>Automatic</span>
                      <div
                        id="signupfieldcontrol"
                        style={webStyle.ProductDkrmarginLeft0}
                        className="control"
                      >
                        <span style={webStyle.ProductDkrblurvalueStyle as React.CSSProperties}>Yes</span>
                      </div>
                    </div>
                  </form>
                </div>
              </FormControl>
            </Grid>
            <Grid
              className="first"
              style={webStyle.ProductDkrinformationSubject}
              item
              xs={12}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.ProductdkrheaderMain10}>
                  <span style={webStyle.ProductDkrlabelStyleoptionhead as React.CSSProperties}>Daily Goal Details</span>
                </FormLabel>
              </FormControl>
              <Grid container style={{ display: "flex" }} xs={12}>
                <Grid item xs={2}>
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        id="signupform"
                        className="form-field"
                        style={{...webStyle.ProductDkrtopdescThirty,width:"max-content"}}
                      >
                        <span style={{...webStyle.ProductDkrTopstyleHeader as React.CSSProperties,}}>Goal Value (In Numbers)</span>
                        <div
                          id="signupfieldcontrol"
                          className="control"
                          style={webStyle.ProductDkrmarginLeft0}
                        >
                    <span style={{...webStyle.ProductDkrblurvalueStyle as React.CSSProperties,fontWeight:400}}>360</span>

                        </div>
                      </div>
                    </form>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.ProductDkrinformationSubject}
              xs={12}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.ProductdkrheaderMain10}>
                  <span style={webStyle.ProductDkrlabelStyleoptionhead as React.CSSProperties}>Analytics and Run rate</span>
                </FormLabel>
              </FormControl>
              <Grid container style={{ display: "flex", alignItems: "last baseline" }}>
                <Grid item xs={3}>
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        className="form-field"
                        id="signupform"
                        style={webStyle.ProductDkrtopdescThirty}
                      >
                        <span style={webStyle.ProductDkrTopstyleHeader as React.CSSProperties}>Run rate</span>
                        <div
                          className="control"
                          id="signupfieldcontrol"
                          style={webStyle.ProductDkrmarginLeft0}
                        >
                          <span style={webStyle.ProductDkrblurvalueStyle as React.CSSProperties}>Yes</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        className="form-field"
                        id="signupform"
                        style={webStyle.ProductDkrtopdescThirty}
                      >
                        <span style={webStyle.ProductDkrTopstyleHeader as React.CSSProperties}>Part of Analytics</span>
                        <div
                          className="control"
                          id="signupfieldcontrol"
                          style={webStyle.ProductDkrmarginLeft0}
                        >
                          <span style={webStyle.ProductDkrblurvalueStyle as React.CSSProperties}>Yes</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid >
          <Divider light />
          <Grid
            container
            alignItems="center"
            style={webStyle.ProductDkrinformContainer as React.CSSProperties}
          >
            <Grid
              className="first"
              item
              style={webStyle.ProductDkrinformSecondContainerHead}
              xs={12}
            >
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.ProductdkrheaderMain10}>
                    <span style={webStyle.ProductDkrHeadtopStyle22 as React.CSSProperties}>Daily KPI Report</span>
                  </FormLabel>

                </FormControl>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.ProductDkrinformationSubject}
              xs={12}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.ProductdkrheaderMain10}>
                  <span style={webStyle.ProductDkrlabelStyleoptionhead as React.CSSProperties}>Calculation Type</span>
                </FormLabel>
                <RadioGroup aria-label="type" data-test-id="radiogroupInput" defaultValue="Slab" value={typing} row onChange={this.handleCalculationTypeChange}>
                  <FormControlLabel value="Slab" control={<Radio style={webStyle.themeColorback} />} label="Slab" style={{fontFamily: "Open Sans",fontSize:"14px",fontWeight:400,color:"#C6C6C6"}} />
                  <FormControlLabel value="prorata" control={<Radio style={webStyle.themeColorback} />} style={{fontFamily: "Open Sans",fontSize:"14px",fontWeight:400,color:"#C6C6C6"}} label="Pro rata" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.ProductDkrinformationSubject}
              xs={12}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.ProductdkrheaderMain10}>
                  <span style={webStyle.ProductDkrlabelStyleoptionhead as React.CSSProperties}>Define Calculation Type</span>
                </FormLabel>
                {typing === "prorata" && (
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        className="form-field"
                        id="signupform"
                        style={webStyle.ProductDkrtopdescThirty}
                      >
                        <span style={webStyle.ProductDkroptionStyleTitle as React.CSSProperties}>Minimum Qualified Result (in %)</span>
                        <div
                          className="control"
                          id="signupfieldcontrol"
                          style={webStyle.ProductDkrmarginLeft0}
                        >
                          <input
                            type="text"
                            data-test-id="txtInputEmail"
                            style={{...webStyle.inputFieldDkr,textAlign:"end"}}
                            className="control-input"
                            placeholder="70%"
                            name="email"
                            id="pronounce-control"
                            
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                )}
                {typing === "Slab" && (
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <Grid container xs={12} style={webStyle.gridAlignparentdkr}>
                        <Grid item xs={2}>
                          <div
                            className="form-field"
                            id="signupform"
                            style={webStyle.ProductDkrtopdescThirty}
                          >
                            <span style={webStyle.ProductDkroptionStyleTitle as React.CSSProperties}>No. of slabs</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.ProductDkrmarginLeft0}
                            >
                              <input
                                type="text"
                                data-test-id="txtInputEmail"
                                style={{ ...webStyle.ProductDkrinputFieldTabless, marginBottom: "10px" }}
                                className="control-input"
                                name="email"
                                id="pronounce-control"
                                disabled
                              />
                              <input
                                type="text"
                                data-test-id="txtInputEmail"
                                style={webStyle.ProductDkrinputFieldTabless}
                                className="control-input"
                                name="email"
                                id="pronounce-control"
                                disabled
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div
                            className="form-field"
                            id="signupform"
                            style={webStyle.ProductDkrtopdescThirty}
                          >
                            <span style={webStyle.ProductDkroptionStyleTitle as React.CSSProperties}>Min.</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.ProductDkrmarginLeft0}
                            >
                              <input
                                type="text"
                                data-test-id="txtInputEmail"
                                style={{ ...webStyle.ProductDkrinputFieldTabless, marginBottom: "10px" }}
                                className="control-input"
                                name="email"
                                id="pronounce-control"
                                disabled
                              />
                              <input
                                type="text"
                                data-test-id="txtInputEmail"
                                style={webStyle.ProductDkrinputFieldTabless}
                                className="control-input"
                                name="email"
                                id="pronounce-control"
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div
                            className="form-field"
                            id="signupform"
                            style={webStyle.ProductDkrtopdescThirty}>
                            <span style={webStyle.ProductDkroptionStyleTitle as React.CSSProperties}>Max.</span>
                            <div
                              id="signupfieldcontrol"
                              className="control"
                              style={webStyle.ProductDkrmarginLeft0}>
                              <input
                                data-test-id="txtInputEmail"
                                type="text"
                                style={{ ...webStyle.ProductDkrinputFieldTabless, marginBottom: "10px" }}
                                name="email"
                                className="control-input"
                                disabled
                                id="pronounce-control"
                              />
                              <input
                                type="text"
                                style={webStyle.ProductDkrinputFieldTabless}
                                data-test-id="txtInputEmail"
                                name="email"
                                className="control-input"
                                id="pronounce-control"
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div
                            style={webStyle.ProductDkrtopdescThirty}
                            className="form-field"
                            id="signupform"
                          >
                            <span style={webStyle.ProductDkroptionStyleTitle as React.CSSProperties}>Weightage</span>
                            <div
                              className="control"
                              style={webStyle.ProductDkrmarginLeft0}
                              id="signupfieldcontrol"
                            >
                              <input
                                data-test-id="txtInputEmail"
                                type="text"
                                style={{ ...webStyle.ProductDkrinputFieldTabless, marginBottom: "10px" }}
                                className="control-input"
                                name="email"
                                id="pronounce-control"
                                disabled
                              />
                              <input
                                data-test-id="txtInputEmail"
                                type="text"
                                className="control-input"
                                name="email"
                                id="pronounce-control"
                                style={webStyle.ProductDkrinputFieldTabless}
                              />
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div
                            id="signupform"
                            className="form-field"
                            style={webStyle.ProductDkrtopdescThirty}
                          >
                            <div
                              style={webStyle.ProductDkrmarginLeft0}
                              className="control"
                              id="signupfieldcontrol"
                            >
                              <button
                                data-test-id="btnAdd"
                                style={webStyle.ProductDkrremoveButton as React.CSSProperties}
                                type="button"
                                className="control-input"
                              >
                                Remove
                              </button>
                              <button
                                type="button"
                                data-test-id="btnRemove"
                                className="control-input"
                                style={webStyle.ProductDkraddButton as React.CSSProperties}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                )}
              </FormControl >
            </Grid >
            <Grid
              className="first"
              item
              style={webStyle.ProductDkrinformationSubContainerTable}
              xs={12}  >
              <>
                <FormControl component="fieldset">
                  <FormLabel 
                  component="legend" style={webStyle.ProductDkrheadermandatory}>
                    <span style={{...webStyle.ProductDkrlabelStyle100 as React.CSSProperties,fontWeight:500}}>Applicable DKRs</span>
                  </FormLabel>
                </FormControl>
                <TableContainer 
                style={webStyle.ProductDkrtablemainContainer}>
                  <Table aria-label="customized table">
                  <TableHead>
                      <TableRow style={webStyle.ProductDkrmaintableHead}>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"35px"}}><span style={{fontWeight:600}}>Sr.</span><span style={{fontWeight:600}}>No.</span></TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",fontWeight:600}}>DKR Brief Title (Tasks)</TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",fontWeight:600}}>
                          DKR SAM (in minutes)
                        </TableCell>
                        <TableCell>
                           <Button onClick={this.handleAddRow}  style={{ fontFamily: 'Open Sans', backgroundColor: "FDD100", color: "black", marginRight: "20px", width: "160px", fontSize: "16px", fontWeight: 600, textTransform: "none",paddingTop: "6px",paddingBottom:"6px",borderRadius:"10px"  }}>
                               Add DKR
                           </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
             {this.state.rows.map((row:any, index:any) => (
              <TableRow key={row.id}>
                 <TableCell component="th" scope="row" style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"65px"}}>{index + 1}.</TableCell>
                <TableCell>
                   <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.ProductDkrmarginLeft0}
                                  >
                                    <input
                                      type="text"
                                      data-test-id="txtInputEmail"
                                      style={{...webStyle.ProductDkrinputmainFieldTable,backgroundColor:"White",width:"100%",color:"Black",textAlign:"start",fontFamily:"Open Sans",fontWeight:400,fontSize:"14px"}}
                                      className="control-input"
                                      placeholder={row.title}
                                      name="email"
                                      id="pronounce-control"
                                    />
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                </TableCell>
                <TableCell>
                   <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.ProductDkrmarginLeft0}
                                  >
                                    <input
                                      type="text"
                                      data-test-id="txtInputEmail"
                                      style={{...webStyle.ProductDkrinputmainFieldTable,backgroundColor:"White",width:"30%",color:"Black",textAlign:"start",fontFamily:"Open Sans",fontWeight:400,fontSize:"14px"}}
                                      className="control-input"
                                      placeholder={row.points}
                                      name="email"
                                      id="pronounce-control"
                                    />
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                </TableCell>
                <TableCell>
                  <a data-test-id="clickforRemoveProduct"
                   onClick={() => this.handleRemoveRow(index)}  style={{ color: "#ff5c5c", textDecoration: "underline" }}>Remove DKR</a>
                </TableCell>
              </TableRow>
            ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            </Grid>
          </Grid >
          <Grid style={webStyle.buttonmainContainer}>
            <Box style={{ marginTop: '50px', marginBottom: '40px' }}>
              <Button style={webStyle.ProductDkrSubmitBtn as React.CSSProperties}>
                Submit
              </Button>
              <Button variant="outlined" style={webStyle.ProductDkrCancelBtn as React.CSSProperties} >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Box >
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
    ProductDkrmaintableHead: {
        backgroundColor: "#F5F5F5",
      },
    ProductDkrlabelStyle100: {
        fontSize: "22px",
        color: "black",
        fontFamily:"Open Sans",
        fontWeight: 500,
      },
    ProductDkrheadermandatory: {
        alignItems: "baseline",
        display: "flex",
        MarginTop:"30px",
        gap: "4px",
      },
      ProductDkrtablemainContainer: { 
        marginTop: "15px" ,
        border: "1px solid #979797",
         borderRadius: "10px", 
        },
    ProductDkrinformationSubContainerTable: {
        width: "90%" ,
        marginTop: "24px",
        },
    ProductDkrinputmainFieldTable: {
        background: 'transparent',
        width: "70%",
        border: '0.5px solid #979797'
      },
   
  ProductDkrEditbutton:{
    fontFamily: 'Open Sans', 
    backgroundColor: "FDD100", 
    color: "black", 
    padding: "15px 20px 15px 20px", 
    borderRadius: "10px", 
    width: "200px", 
    fontSize: "16px", 
    fontWeight: 600, 
    textTransform: "none"
  },
   ProductDkrCancelBtn: {
    fontWeight: 600,
    fontFamily: 'Open Sans',
    width: "170px",
    fontSize: "16px",
    textTransform: "none",
    backgroundColor: "white",
    color: "black",
  },
  ProductDkrSubmitBtn: {
    fontFamily: 'Open Sans',
    backgroundColor: "FDD100",
    marginRight: "20px",
    color: "black",
    fontSize: "16px",
    width: "170px",
    fontWeight: 600,
    textTransform: "none",
    
  },
  
  TableC: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    color: "black",
    fontWeight: 600
  },

  ProductDkrmaincontainer: {
    height: "64px",
    background: "#F3F4F5",
    marginRight: "-20px"
  },
  ProductDkrinformContainer: {
    padding: "0 32px",
    alignItems: "baseline",
    flexDirection: "column"
  },
  ProductDkrinformationSubject: {
    width: "800px",
    marginTop: "24px",
  },
  ProductDkrleftSide: {
    padding: "19px 0px 21px 38px"
  },
  ProductDkrnullContainer: {
    padding: "0px 44px 0px 0px"
  },

  ProductDkrinformSecondContainerHead: {
    marginTop: "24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "initial",
    width: "90%"
  },
  ProductDkrtopdescThirty: {
    marginTop: "30px",
  },
  container: {
    height: "100%",
    width: "100%",
  },
  ProductDkroptionStyleTitle: {
    color: "black",
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "Open Sans"
  },
  ProductDkrlabelStyleoptionhead: {
    color: "black",
    fontWeight: 500,
    fontSize: "22px",
    fontFamily: "Open Sans"
  },
  ProductDkrTopstyleHeader: {
    fontSize: "18px",
    color: "#c8ccc9",
    fontWeight: 700,
    fontFamily: "Open Sans"
  },
  ProductDkrblurvalueStyle: {
    color: "black",
    marginTop: "10px",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: 400
  },
  ProductDkrrowarrowDisplay: {
    alignItems: "center",
    display: "flex",
  },
  ProductDkrHeadStyleTop1: {
    color: "black",
    fontFamily: "Open Sans",
    fontSize: "18px",
  },
  ProductDkrHeadtopStyle22: {
    color: "black",
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Open Sans"
  },
  inputFieldDkr: {
    width: "60%",
    border: '0.5px solid #979797',
    background: 'transparent',
  },
  ProductDkrinputFieldTabless: {
    width: "70%",
    border: '0.5px solid #979797',
    background: 'transparent',
  },
  ProductDkrmarginLeft0: {
    marginLeft: 0,
    marginTop: 10
  },
  ProductDkrremoveButton: {
    background: "transparent",
    borderColor: '#FF5C5C',
    margin: "10px 0 0",
    width: "70%",
    fontSize: "15px",
    fontWeight: 600,
    fontFamily: "Open Sans"
  },
  ProductDkraddButton: {
    background: "transparent",
    borderColor: '#FDD100',
    margin: "10px 0 0",
    width: "70%",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Open Sans"
  },
  buttonmainContainer: { display: "flex", gap: "20px", margin: "0 40px" },
  themeColorback: {
    color: "#FDD100",
    fontFamily: "Open sans",
    fontWeight: 400,
    fontSize: "14px"
  },
  ProductdkrheaderMain10: {
    display: "flex",
    alignItems: "baseline",
    gap: "4px",
    MarginTop: "30px"
  },

  gridAlignparentdkr: {
    alignItems: "end"
  },
};
// Customizable Area End
