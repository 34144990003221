import React from "react";

import {
    Box,
    Grid,
    // Customizable Area Start
    FormControl,
    FormLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

// Customizable Area End

import AddDkrDetailsController, {
    CalculationRow,
    Props, Row
} from "./AddDkrDetailsController";

export default class ViewDkrDetails extends AddDkrDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <Box >
                <div style={webStyles.divider as React.CSSProperties}></div>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid
                        container
                        alignItems="center"
                        style={webStyles.informationContainer as React.CSSProperties}
                    >
                        <Grid
                            className="first"
                            item
                            style={webStyles.informationSubContainerHeading}
                            xs={12}
                        >
                            <div>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                        <span style={webStyles.labelStyle2 as React.CSSProperties}>Daily KPI Report</span>
                                    </FormLabel>

                                </FormControl>
                            </div>
                            <div className="form-field text-center" id="signupBlock4">
                                <div className="control" id="signupfieldcontrol2">
                                    <button
                                        type="button"
                                        className="button submit"
                                        style={webStyles.editBtn as React.CSSProperties}
                                    >
                                        Edit DKR Details
                                    </button>
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            className="first"
                            item
                            style={webStyles.informationSubContainer}
                            xs={12}
                        >
                            <FormControl component="fieldset" >
                                <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                    <span style={webStyles.labelStyle as React.CSSProperties}>Calculation Type</span>
                                </FormLabel>
                                <span style={webStyles.valueStyle as React.CSSProperties}>{this.state.type}</span>

                            </FormControl>

                        </Grid>
                        <Grid
                            className="first"
                            item
                            style={webStyles.informationSubContainer}
                            xs={12}
                        >
                            <FormControl component="fieldset" style={webStyles.width100}>
                                <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                    <span style={webStyles.labelStyle as React.CSSProperties}>Define Calculation Type</span>
                                </FormLabel>
                                {this.state.type === "prorata" ?
                                    <div className="form-block" id="signupBlock3">
                                        <form>
                                            <div
                                                className="form-field"
                                                id="signupform"
                                                style={webStyles.topThirty}
                                            >
                                                <span style={webStyles.labelStyleHeader as React.CSSProperties}>Minimum Qualified Result (in %)</span>
                                                <div
                                                    className="control"
                                                    id="signupfieldcontrol"
                                                    style={webStyles.marginLeft0}
                                                >
                                                    <span style={webStyles.valueStyle as React.CSSProperties}>70%</span>

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    :
                                    <div className="form-block" id="signupBlock3">
                                        <form>
                                            {this.state.calculationType.map((row: CalculationRow, index: number) => (
                                                <Grid container xs={12} style={webStyles.gridAlign}>
                                                    <Grid item xs={3}>
                                                        <div
                                                            className="form-field"
                                                            id="signupform"
                                                            style={webStyles.topThirty}
                                                        >
                                                            <span style={webStyles.labelStyleHeader as React.CSSProperties}>No. of slabs</span>
                                                            <div
                                                                className="control"
                                                                id="signupfieldcontrol"
                                                                style={webStyles.marginLeft0}
                                                            >
                                                                <span style={webStyles.valueStyle as React.CSSProperties}>{row.id}</span>
                                                            </div>

                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <div
                                                            className="form-field"
                                                            id="signupform"
                                                            style={webStyles.topThirty}
                                                        >
                                                            <span style={webStyles.labelStyleHeader as React.CSSProperties}>Min.</span>
                                                            <div
                                                                className="control"
                                                                id="signupfieldcontrol"
                                                                style={webStyles.marginLeft0}
                                                            >
                                                                <span style={webStyles.valueStyle as React.CSSProperties}>{`${row.min}%`}</span>
                                                            </div>

                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <div
                                                            className="form-field"
                                                            id="signupform"
                                                            style={webStyles.topThirty}
                                                        >
                                                            <span style={webStyles.labelStyleHeader as React.CSSProperties}>Max.</span>
                                                            <div
                                                                className="control"
                                                                id="signupfieldcontrol"
                                                                style={webStyles.marginLeft0}
                                                            >
                                                                <span style={webStyles.valueStyle as React.CSSProperties}>{`${row.max}%`}</span>

                                                            </div>

                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <div
                                                            className="form-field"
                                                            id="signupform"
                                                            style={webStyles.topThirty}
                                                        >
                                                            <span style={webStyles.labelStyleHeader as React.CSSProperties}>Weightage</span>
                                                            <div
                                                                className="control"
                                                                id="signupfieldcontrol"
                                                                style={webStyles.marginLeft0}
                                                            >
                                                                <span style={webStyles.valueStyle as React.CSSProperties}>{row.weightage}</span>
                                                            </div>

                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            ))}

                                        </form>
                                    </div>
                                }
                            </FormControl >

                        </Grid >
                        <Grid
                            className="first"
                            item
                            style={webStyles.informationSubContainerTable}
                            xs={12}
                        >
                            <>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                        <span style={webStyles.labelStyle as React.CSSProperties}>Applicable DKRs</span>
                                    </FormLabel>
                                </FormControl>

                                <TableContainer style={webStyles.tableContainer}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow style={webStyles.tableHead}>
                                                <TableCell>Sr. No.</TableCell>
                                                <TableCell>DKR Type</TableCell>
                                                <TableCell>
                                                    DKR Brief Title&nbsp;(g)
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {this.state.tableRow.map((row: Row) => (
                                                <TableRow key={row.id} >
                                                    <TableCell component="th" scope="row" style={webStyles.tablePadding}>
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell>{row.title}</TableCell>
                                                    <TableCell>
                                                        {row.description}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        </Grid>
                    </Grid >

                    <Grid style={webStyles.buttonContainer}>
                        <div className="form-field text-center" id="signupBlock4">
                            <div className="control" id="signupfieldcontrol2">
                                <button
                                    type="button"
                                    data-test-id="btnEmailLogIn"
                                    style={webStyles.cotinueBtn}
                                    className="button submit"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                        <div className="form-field text-center" id="signupBlock4">
                            <div className="control" id="signupfieldcontrol2">
                                <button
                                    type="button"
                                    data-test-id="btnCancel"
                                    style={webStyles.cancelBtn}
                                    className="button submit"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Box >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyles = {
    divider: { borderTop: '1px solid rgba(0, 0, 0, 0.12)', margin: '16px 0' },
    informationContainer: { padding: "0 32px", alignItems: "baseline", flexDirection: "column" },
    informationSubContainer: { marginTop: "24px", width: "800px" },
    informationSubContainerTable: { marginTop: "24px", width: "90%" },
    informationSubContainerHeading: { marginTop: "24px", display: "flex", alignItems: "initial", justifyContent: "space-between", width: "90%" },
    topThirty: { marginTop: "30px" },
    title: {
        fontSize: 14
    },
    labelStyle: {
        color: "black",
        fontSize: "24px",
        fontWeight: "300",
        marginTop: "10px",
        marginBottom: "10px"
    },
    labelStyle2: {
        color: "black",
        fontSize: "24px",
        fontWeight: "bold"
    },
    inputField: {
        width: "40%",
        background: 'transparent',
        border: '0.5px solid #979797'
    },
    inputFieldTable: {
        width: "70%",
        background: 'transparent',
        border: '0.5px solid #979797'
    },
    cotinueBtn: {
        margin: "10px 0 0"
    },
    marginLeft0: {
        marginLeft: 0,
        marginTop: 10
    },
    cancelBtn: {
        background: "transparent",
        margin: "10px 0 0"
    },
    removeBtn: {
        background: "transparent",
        borderColor: '#FF5C5C',
        margin: "10px 0 0",
        width: "70%",
    },
    addBtn: {
        background: "transparent",
        borderColor: '#FDD100',
        margin: "10px 0 0",
        width: "70%",
    },
    buttonContainer: { display: "flex", gap: "20px", margin: "0 40px" },
    themeColor: {
        color: "#FDD100"
    },
    headerWithmandatory: {
        display: "flex",
        alignItems: "baseline",
        gap: "4px"
    },
    editBtn: {
        height: "60px",
        fontSize: "20px",
        fontWeight: "normal"
    },
    tableHead: {
        backgroundColor: "#F5F5F5",
    },
    inputFieldDisable: {
        width: "70%",
        background: "#F5F5F5",
        border: '0.5px solid #979797'
    },
    tablePadding:{padding:"30px"},
    tableContainer: { border: "1px solid #979797", borderRadius: "10px", marginTop: "10px" },
    gridAlign: {
        alignItems: "end"
    },
    valueStyle: {
        color: "black",
        fontSize: "22px",
        marginTop: "10px"
    },
    labelStyleHeader: {
        color: "#c8ccc9",
        fontSize: "22px",
        fontWeight: "bold"
    },
    width100:{width: '100%'}
};
// Customizable Area End
