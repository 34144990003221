import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { number } from "yup";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  kpiName:string,
  kpiDescription:string,
  weightage:string,
  goalCalculation:string,
  isduplicateOpen:boolean,
  anchorEl7:any,
  templateAdd:any,
  SelectedtemplateId:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
 
  // Customizable Area End
}

export default class EditTemplate2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getIdForTemplateAdded: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      kpiName:'',
      kpiDescription:'',
      weightage:'',
      goalCalculation:'',
      isduplicateOpen:false,
      anchorEl7:null,
      templateAdd:[],
      SelectedtemplateId:number

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const dId = localStorage.getItem("DepartmentNavigationId");
    this.getTemplateAddedResponse(dId)

    // Customizable Area End
  }



    // Customizable Area Start
    async receive(from: string, message: Message) {
      // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
         if(apiRequestCallId === this.getIdForTemplateAdded) {
            this.getTemplateAddedDetail(responseJson)
        }
      }
      // Customizable Area End
    }
    // Customizable Area End
  
    handleSelecttemplate = (templateId: number | null) => {
    
      // Use setState's callback to ensure state is updated
      this.setState(
        { SelectedtemplateId: templateId },
        () => {
          // Callback function ensures you access the updated state
        }
      );
    };
  
      navigateToTimingDKR = () => {
        this.props.navigation.navigate("TimingDkr")
      }
      navigateToTimingView = () => {
        this.props.navigation.navigate("TimingView")
        localStorage.setItem("TemplateNavigationId", this.state.SelectedtemplateId);

      }
     
      navigateToSignUp=()=>{
        this.props.navigation.navigate("EditKpi")
      }
      navigateToTemplateAdded = async ()=>{
        const navigateToUserProfile = new Message(getName(MessageEnum.NavigationMessage));
        navigateToUserProfile.addData(getName(MessageEnum.NavigationTargetMessage), "TemplateAdded");
        navigateToUserProfile.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateToUserProfile);
      }
     
      toggleModal = () => {
        this.setState((prevState) => ({ isduplicateOpen: !prevState.isduplicateOpen }));
      };
      handleClose2 = () => {
        this.setState({isduplicateOpen:false,});
      };
      handleClick7 = (event:any) => {
        this.setState({ anchorEl7: event.currentTarget });
      };
      handleClickClose7 = () => {
        this.setState({ anchorEl7: null });
      };
      getTemplateAddedDetail = (responseJson: any) => { 
        if (responseJson) {
          this.setState({ templateAdd: responseJson.data });
        } 
      }
      getTemplateAddedResponse = (ID:any) => {
    
        const fetchTemplateAddedHeader = {
          "Content-Type": configJSON.dashboarContentType,
          token:localStorage.getItem("authToken")
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getIdForTemplateAdded = requestMessage.messageId;
        const endpoint = `bx_block_gamification/template_filter?department_id=${ID}`;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endpoint 
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(fetchTemplateAddedHeader)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.dashboarApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

 

  // Customizable Area Start
  // Customizable Area End
}
