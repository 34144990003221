import { IBlock } from "../../../framework/src/IBlock";
import React from "react";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import toast from "react-hot-toast";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: string
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  type: string;
  description: string;
  automatic: boolean;
  runRate: boolean;
  analytics: boolean;
  goaltype: string;
  isModal: boolean;
  statisticsTab: string;
  tabNumber: number;
  scoreTabNumber: number;
  employeesData: any;
  loading: boolean;
  open: boolean;
  name: any;
  descriptionModal: any;
  id: any;
  dayType: any;
  startDate: any;
  endDate: any;
  infoBoardData: any;
  title: any;
  descriptionModalSecond: any;
  openNew: boolean;
  filteredData: any;
  infoTitle: any;
  infoDescription: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddKpiController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEventDetailsId: any
  getinfoboardsDetailsId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      type: "core",
      description: "",
      automatic: false,
      runRate: false,
      analytics: false,
      goaltype: "number",
      isModal: false,
      statisticsTab: '',
      tabNumber: 1,
      scoreTabNumber: 0,
      employeesData: [],
      infoBoardData: [],
      loading: true,
      open: false,
      name: "",
      descriptionModal: "",
      id: null,
      dayType: "",
      startDate: "",
      endDate: "",
      title: "",
      descriptionModalSecond: "",
      openNew: false,
      filteredData: [],
      infoTitle: '',
      infoDescription: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
   
    super.componentDidMount();
    this.getEventDetails();
    this.infoboardsDetails();
   
  
    // Customizable Area Start
    const activeTab=await getStorageData("tabb")
    if(activeTab === null){
      this.setState({statisticsTab:""})
    }
    else{
     this.setState({statisticsTab:activeTab})
    }

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getEventDetailsId) {
        this.getEventDetailsIdFunction(responseJson)
      }
      if (apiRequestCallId === this.getinfoboardsDetailsId) {
        this.infoBoardDataFunction(responseJson)
      }
    }
    // Customizable Area End
  }

  // web events
  handleChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: string;
  }>) => {
    this.setState({
      type: event.target.value
    });
  };
  handleDescriptionChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: string;
  }>) => {
    this.setState({ description: event.target.value })
  };
  handleAutomatic = () => {
    this.setState({ automatic: !this.state.automatic })
  }
  handleRunrate = () => {
    this.setState({ runRate: !this.state.runRate })
  }
  handleAnalytics = () => {
    this.setState({ analytics: !this.state.analytics })
  }
  handleGoal = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ goaltype: event.target.value })
  }

  // Customizable Area Start
  getEventDetailsIdFunction = (responseJson: any) => {
    if (responseJson) {
      this.setState({ employeesData: responseJson?.data, loading: false });
    }
  }
  infoBoardDataFunction = (responseJson: any) => {
    if (responseJson) {
      this.setState({ infoBoardData: responseJson?.infoboards })
    }
  }
  handlePageTabNavigate=async (pageName:any)=>{
    console.log(pageName,"Hey")
    setStorageData("tabb",pageName)
   this.setState({statisticsTab:pageName})
  }
  handlePageTabBackNavigate=()=>{
    removeStorageData("tabb")
    this.setState({statisticsTab:""})
  }

  handleOpenSecond = (modalTitle: any, eventId: any, description: any) => {
    if (!eventId) {
      this.setState({ open: true, infoDescription: description, infoTitle: modalTitle })
    }
    const filteredData = eventId
      ? this.state.employeesData.filter(
        (item: any) => item.attributes.id === eventId
      )
      : [];
    const formatDate = (date: any) => {
      const parsedDate = new Date(date);
      const day = String(parsedDate.getDate()).padStart(2, '0');
      const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
      const year = parsedDate.getFullYear();
      return `${day}-${month}-${year}`;
    };
    const filteredItem = filteredData.length > 0 ? filteredData[0].attributes : null;
    const formattedStartDate = filteredItem ? formatDate(filteredItem.start_date) : null;
    const formattedEndDate = filteredItem ? formatDate(filteredItem.end_date) : null;
    const newModalTitle = filteredItem.event_name;
    const newDescription = filteredItem.description
    const dayType = filteredItem.day_type === 'full_day' ? 'Full Day' : 'Half Day'
    this.setState({
      title: newModalTitle,
      descriptionModalSecond: newDescription,
      openNew: true,
      filteredData,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      dayType,
    });
  };

  handleClose = () => {
    this.setState({ open: false })
  }
  handleCloseNew = () => {
    this.setState({ openNew: false, open: false })
  }
  Goback = async(path: any) => {
    this.setState({statisticsTab:""})
    removeStorageData("tabb")
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
    
  }
  getEventDetails = async () => {
    let userToken = window.localStorage.getItem("authToken");
    const fetchactvateAccountsHeader = {
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEventDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_gamification/events/company_events`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchactvateAccountsHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  infoboardsDetails = async () => {
    let companyId = await getStorageData("companyId");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getinfoboardsDetailsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/infoboards?company_id=${companyId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
