import React from "react";

import {
  Box,
  Typography,
  Grid
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import "../../dashboard/src/dashboard.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
// Customizable Area End

import SingleUserUploadController, {
  Props
} from "./SingleUserUploadController.web";

export default class SingleUserUpload extends SingleUserUploadController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={webStyle.maincontainer}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid className="first" style={webStyle.leftContainer} item>
            <Typography style={webStyle.labelStyle}>
              Upload a Single User
            </Typography>
          </Grid>
          <Grid className="second" style={webStyle.emptyContainer} item />
        </Grid>
        <Grid
          container
          alignItems="center"
          style={webStyle.informationContainer}
        >
          <Grid
            className="first"
            item
            style={webStyle.informationSubContainer}
            xs={4}
          >
            <Typography style={webStyle.labelStyle}>
              Personal Information
            </Typography>
            <div className="form-block" id="signupBlock3">
              <form>
                <div
                  className="form-field"
                  id="signupform"
                  style={webStyle.topThirty}
                >
                  <span style={webStyle.labelStyle}>First Name</span>
                  <div
                    className="control"
                    id="signupfieldcontrol"
                    style={webStyle.marginLeft0}
                  >
                    <input
                      type="text"
                      data-test-id="txtInputFirstName"
                      style={webStyle.inputField}
                      className="control-input"
                      value={this.state.firstName}
                      placeholder="Enter first name"
                      name="firstName"
                      id="pronounce-control"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div
                  className="form-field"
                  id="signupform"
                  style={webStyle.topThirty}
                >
                  <span style={webStyle.labelStyle}>Last Name</span>
                  <div
                    className="control"
                    id="signupfieldcontrol"
                    style={webStyle.marginLeft0}
                  >
                    <input
                      type="text"
                      data-test-id="txtInputLastName"
                      style={webStyle.inputField}
                      className="control-input"
                      value={this.state.lastName}
                      placeholder="Enter last name"
                      name="lastName"
                      id="pronounce-control"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div
                  className="form-field"
                  id="signupform"
                  style={webStyle.topThirty}
                >
                  <span style={webStyle.labelStyle}>Email Id</span>
                  <div
                    className="control"
                    id="signupfieldcontrol"
                    style={webStyle.marginLeft0}
                  >
                    <input
                      type="text"
                      data-test-id="txtInputEmail"
                      style={webStyle.inputField}
                      className="control-input"
                      placeholder="Enter E-mail"
                      value={this.state.email}
                      name="email"
                      id="pronounce-control"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
              </form>
            </div>
          </Grid>
          <Grid
            className="first"
            item
            style={webStyle.informationSubContainer}
            xs={8}
          >
            <Typography style={webStyle.labelStyle}>
              Work Information
            </Typography>
            <Grid xs={12} style={webStyle.flexDisplay}>
              <Grid alignItems="center" item xs={4}>
                <div className="form-block" id="signupBlock3">
                  <form>
                    <div
                      className="form-field"
                      id="signupform"
                      style={webStyle.topThirty}
                    >
                      <span style={webStyle.labelStyle}>Employee ID</span>
                      <div
                        className="control"
                        id="signupfieldcontrol"
                        style={webStyle.marginLeft0}
                      >
                        <input
                          type="text"
                          data-test-id="txtInputPronounce"
                          style={webStyle.inputField1}
                          className="control-input"
                          value={this.state.employId}
                          placeholder=""
                          name="employId"
                          id="pronounce-control"
                          onChange={this.handleInputChange}
                          disabled
                        />
                      </div>
                    </div>
                    <div
                      className="form-field"
                      id="signupform"
                      style={webStyle.topThirty}
                    >
                      <span style={webStyle.labelStyle}>Work Phone Number</span>
                      <div
                        className="control"
                        id="signupfieldcontrol"
                        style={webStyle.marginLeft0}
                      >
                        <PhoneInput
                          country={"in"}
                          inputProps={{
                            name: "full_phone_number"
                          }}
                          containerStyle={webStyle.phoneInputContainer}
                          inputStyle={webStyle.customPhoneInputStyle}
                          enableSearch={true}
                          placeholder="Enter Phone Number"
                          data-test-id="phoneInput"
                          value={this.state.phone}
                          onChange={(phone: any, countryData: any) => {
                            this.handlePhoneNumberChange(phone, countryData);
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="form-field"
                      id="signupform"
                      style={webStyle.topThirty}
                    >
                      <span style={webStyle.labelStyle}>Department</span>
                      <div
                        className="control"
                        id="signupfieldcontrol"
                        style={webStyle.marginLeft0}
                      >
                        <select
                          data-test-id="txtInputDepartment"
                          name="department"
                          id="department"
                          value={this.state.selectedDepartment}
                          style={webStyle.inputField1}
                          onChange={e => {
                            this.setState({
                              selectedDepartment: e.target.value
                            });
                          }}
                        >
                          <option value="">Select Department</option>
                          {this.state.department.map(
                            (label: any, index: any) => (
                              <option id={index} value={label}>
                                {label}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                    <div
                      className="form-field"
                      id="signupform"
                      style={webStyle.topThirty}
                    >
                      <span style={webStyle.labelStyle}>Designation</span>
                      <div
                        className="control"
                        id="signupfieldcontrol"
                        style={webStyle.marginLeft0}
                      >
                        <input
                          type="text"
                          data-test-id="txtInputDesignation"
                          style={webStyle.inputField1}
                          className="control-input"
                          placeholder="Enter Designation"
                          value={this.state.designation}
                          name="designation"
                          id="pronounce-control"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div
                      className="form-field"
                      id="signupform"
                      style={webStyle.topThirty}
                    >
                      <span style={webStyle.labelStyle}>Role</span>
                      <div
                        className="control"
                        id="signupfieldcontrol"
                        style={webStyle.marginLeft0}
                      >
                        <select
                          data-test-id="txtInputRole"
                          name="role"
                          id="role"
                          value={this.state.role}
                          style={webStyle.inputField1}
                          onChange={e => {
                            this.setState({ role: e.target.value });
                          }}
                        >
                          <option value="">Select Role</option>
                          <option id="employee" value="employee">
                            Employee
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      className="form-field"
                      id="signupform"
                      style={webStyle.topThirty}
                    >
                      <span style={webStyle.labelStyle}>Reporting to</span>
                      <div
                        className="control"
                        id="signupfieldcontrol"
                        style={webStyle.marginLeft0}
                      >
                        <input
                          type="text"
                          data-test-id="txtInputReporting"
                          style={webStyle.inputField1}
                          className="control-input"
                          placeholder="Enter Reporting Manager Name"
                          value={this.state.reportingTo}
                          name="reportingTo"
                          id="pronounce-control"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div
                      className="form-field"
                      id="signupform"
                      style={webStyle.topThirty}
                    >
                      <span style={webStyle.labelStyle}>
                        Staffing location (Country)
                      </span>
                      <div
                        className="control"
                        id="signupfieldcontrol"
                        style={webStyle.marginLeft0}
                      >
                        <select
                          data-test-id="txtInputCountry"
                          name="country"
                          id="country"
                          value={this.state.selectedCountry}
                          style={webStyle.inputField1}
                          onChange={e => {
                            this.handleSelectCountry(e);
                          }}
                        >
                          <option value="">Select Country</option>
                          {this.state.country.map((label: any, index: any) => (
                            <option id={index} value={label}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
              </Grid>
              <Grid alignItems="center" item xs={4}>
                <div className="form-block" id="signupBlock3">
                  <form>
                    <div
                      className="form-field"
                      id="signupform"
                      style={webStyle.topThirty}
                    >
                      <span style={webStyle.labelStyle}>
                        Staffing location (State)
                      </span>
                      <div
                        className="control"
                        id="signupfieldcontrol"
                        style={webStyle.marginLeft0}
                      >
                        <select
                          data-test-id="txtInputState"
                          name="state"
                          id="state"
                          value={this.state.selectedState}
                          style={webStyle.inputField1}
                          onChange={e => {
                            this.handleSelectState(e);
                          }}
                        >
                          <option value="">Select State</option>
                          {this.state.state.map((label: any, index: any) => (
                            <option id={index} value={label}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div
                      className="form-field"
                      id="signupform"
                      style={webStyle.topThirty}
                    >
                      <span style={webStyle.labelStyle}>
                        Staffing location (City)
                      </span>
                      <div
                        className="control"
                        id="signupfieldcontrol"
                        style={webStyle.marginLeft0}
                      >
                        <select
                          data-test-id="txtInputCity"
                          name="city"
                          id="city"
                          value={this.state.selectedCity}
                          style={webStyle.inputField1}
                          onChange={e => {
                            this.handleSelectCity(e);
                          }}
                        >
                          <option value="">Select City</option>
                          {this.state.city.map((label: any, index: any) => (
                            <option id={index} value={label}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div
                      className="form-field"
                      id="signupform"
                      style={webStyle.topThirty}
                    >
                      <span style={webStyle.labelStyle}>Date of Joining</span>
                      <div
                        className="control"
                        id="signupfieldcontrol"
                        style={webStyle.marginLeft0}
                      >
                        <input
                          type="date"
                          name="doj"
                          id="date_of_joining"
                          data-test-Id="txtInputDoj"
                          className="control-input"
                          style={webStyle.inputField1}
                          value={this.state.doj}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div
                      className="form-field"
                      id="signupform"
                      style={webStyle.topThirty}
                    >
                      <span style={webStyle.labelStyle}>Employee Type</span>
                      <div
                        className="control"
                        id="signupfieldcontrol"
                        style={webStyle.marginLeft0}
                      >
                        <select
                          data-test-id="txtInputEmployType"
                          name="employtype"
                          id="employtype"
                          value={this.state.employtype}
                          style={webStyle.inputField1}
                          onChange={e => {
                            this.setState({ employtype: e.target.value });
                          }}
                        >
                          <option value="">Select Employe Type</option>
                          <option id="regular" value="regular">
                            Regular
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      className="form-field"
                      id="signupform"
                      style={webStyle.topThirty}
                    >
                      <span style={webStyle.labelStyle}>Company Name</span>
                      <div
                        className="control"
                        id="signupfieldcontrol"
                        style={webStyle.marginLeft0}
                      >
                        <input
                          type="text"
                          data-test-id="txtInputCompanyName"
                          style={webStyle.inputField1}
                          className="control-input"
                          placeholder="Enter Company Name"
                          value={this.state.companyName}
                          name="companyName"
                          id="pronounce-control"
                          onChange={e =>
                            this.handleChangeCompanyName(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="form-field"
                      id="signupform"
                      style={webStyle.topThirty}
                    >
                      <span style={webStyle.labelStyle}>Time Zone</span>
                      <div
                        className="control"
                        id="signupfieldcontrol"
                        style={webStyle.marginLeft0}
                      >
                        <select
                          data-test-id="txtInputTimeZone"
                          name="timeZone"
                          id="timeZone"
                          value={this.state.selectedtimeZone}
                          style={webStyle.inputField1}
                          onChange={e => {
                            this.setState({ selectedtimeZone: e.target.value });
                          }}
                        >
                          <option value="">Select Time Zone</option>
                          {this.state.timeZone.map((label: any, index: any) => (
                            <option id={index} value={label}>
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div
                      className="form-field"
                      id="signupform"
                      style={webStyle.topThirty}
                    >
                      <span style={webStyle.labelStyle}>Shifting Times</span>
                      <div
                        className="control"
                        id="signupfieldcontrol"
                        style={webStyle.marginLeft0}
                      >
                        <input
                          type="text"
                          data-test-id="txtInputShift"
                          style={webStyle.inputField1}
                          className="control-input"
                          placeholder="Enter Shift TIme"
                          name="shiftTime"
                          value={this.state.shiftTime}
                          id="pronounce-control"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid style={webStyle.buttonContainer}>
          <div className="form-field text-center" id="signupBlock4">
            <div className="control" id="signupfieldcontrol2">
              <button
                type="button"
                data-test-id="btnEmailLogIn"
                style={webStyle.cotinueBtn}
                className="button submit"
                onClick={this.handleCreateEmployee}
              >
                Save
              </button>
            </div>
          </div>
          <div className="form-field text-center" id="signupBlock4">
            <div className="control" id="signupfieldcontrol2">
              <button
                type="button"
                data-test-id="btnCancel"
                style={webStyle.cancelBtn}
                className="button submit"
                onClick={this.handleReset}
              >
                Cancel
              </button>
            </div>
          </div>
        </Grid>
      </Box>

      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  maincontainer: {
    background: "#F3F4F5",
    height: "64px",
    marginTop: "32px",
    marginRight: "-20px"
  },
  leftContainer: {
    padding: "19px 0px 21px 38px"
  },
  emptyContainer: { padding: "0px 44px 0px 0px" },
  informationContainer: { padding: "0 32px", alignItems: "baseline" },
  informationSubContainer: { marginTop: "24px" },
  topThirty: { marginTop: "30px" },
  flexDisplay: { display: "flex" },
  container: {
    width: "100%",
    height: "100%"
  },
  root: {
    minWidth: 275
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  labelStyle: {
    color: "black",
    fontSize: "18px",
    fontWeight: "bold"
  },
  inputField: {
    width: "80%"
  },
  cotinueBtn: {
    margin: "10px 0 0"
  },
  cancelBtn: {
    background: "transparent",
    margin: "10px 0 0"
  },
  inputField1: {
    width: "90%"
  },
  marginLeft0: {
    marginLeft: 0,
    marginTop: 10
  },
  customPhoneInputStyle: {
    background: "#F3F4F5 !important",
    border: "none !important"
  },
  phoneInputContainer: {
    width: "90%",
    border: "none",
    background: "#F3F4F5"
  },
  buttonContainer: { display: "flex", gap: "20px", margin: "0 40px" }
} as any;
// Customizable Area End
