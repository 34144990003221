import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link } from "react-router-dom"
// Customizable Area End

import SettingsController, {
    Props
} from "./SettingsController";
import { version_screen } from "./assets";

export default class VersionUpdate extends SettingsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box style={{ background: "#F3F4F5", height: "64px", marginTop: "32px" }} >
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid className="first" style={{
                    padding: '19px 0px 21px 38px',
                    // display: "flex",
                    width: "100%",
                    maxWidth: "80%",
                    justifyContent: "flex-start",
                }} item>
                    <p style={{ color: "black", fontSize: "18px", fontWeight: 400, paddingLeft: "14px", display: "flex", alignItems: "center" }}>
                        <Link to="/VersionUpdate">
                            <ArrowBackIosIcon style={{ marginRight: "42px" }} />
                        </Link>
                        Settings {"> Version Update"}
                    </p>

                </Grid>
                <Grid container alignItems="center" style={{ padding: '84px 0 0 0', display:"flex", justifyContent:"center" }}>
                
                    <img src={version_screen} alt="logo" height={382} width={362} />
                   
                  
                    
                </Grid>
                <Grid container alignItems="center" style={{paddingTop:"69px", display:"flex", justifyContent:"center" }}>
                <Typography style={webStyle.noBorder as React.CSSProperties}>
                        Time To Update!
                    </Typography>
                </Grid>
                </Grid>
                </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    // container: {
    //     width: "100%",
    //     height: "100%"
    // }
    noBorder: {
        '& .MuiTypography-body1': {
            fontSize: '36px', 
        },
    },
};
// Customizable Area End
