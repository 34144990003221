import React from "react";
// Customizable Area Start
import {
  Button,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Box
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Customizable Area End
import AddDkrController, { Props } from "./AddDkrController";
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  }
});
export default class TimingDkr extends AddDkrController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.Timemaincontainer}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid className="first" style={webStyle.TimeleftSide} item>
              <Grid container spacing={2} alignItems="center">
                <Grid item style={webStyle.TimerowarrowDisplay}>
                  <ArrowBackIosIcon style={{ fontSize: "24px", color: "black" }} data-test-id="nextButton1" onClick={this.navigateToEditTemplate2} />
                  <span
                    style={{ ...webStyle.TimeHeadStyleTop1, fontWeight: 400 }}>
                    Edit Template
                  </span>
                </Grid>
                <Grid 
                item style={webStyle.TimerowarrowDisplay}>
                  <ArrowBackIosIcon style={{ ...webStyle.TimeHeadStyleTop1, color: "#B2B2B2", fontSize: "16px" }} />
                  <span style={{ ...webStyle.TimelabelStyleoptionhead as React.CSSProperties, fontWeight: 700, fontSize: "18px" }}>
                    Add DKR Details
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid 
            className="second" style={webStyle.TimenullContainer} item />
          </Grid>
          <Grid
            container
            alignItems="center"
            style={webStyle.TimeinformContainer as React.CSSProperties}>
            <Grid
              className="first"
              item
              style={webStyle.TimeinformSecondContainerHead}
              xs={12}>
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.TimeheaderMain10}>
                    <span style={webStyle.TimeHeadtopStyle22 as React.CSSProperties}>KPI Details</span>
                  </FormLabel>

                </FormControl>
              </div>
              <div className="form-field text-center" id="signupBlock4">
                <div className="control" id="signupfieldcontrol2">
                  <Button style={webStyle.Editbutton as React.CSSProperties} data-test-id="EditButton" onClick={this.navigateToEditKpi}>
                    Edit KPI Details
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.TimeinformationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.TimeheaderMain10}>
                  <span style={{ ...webStyle.TimelabelStyleoptionhead as React.CSSProperties, fontSize: "22px", fontWeight: 500 }}>Type</span>
                </FormLabel>
                <span style={{ ...webStyle.TimeblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>Timing</span>
              </FormControl>
            </Grid>
            <Grid
              className="second"
              item
              style={webStyle.TimeinformationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={{ ...webStyle.TimeheaderMain10, marginTop: "30px" }}>
                  <span style={{ ...webStyle.TimelabelStyleoptionhead as React.CSSProperties, fontWeight: 500, fontSize: "22px" }}>Basic Details</span>
                </FormLabel>
                <Grid container style={{ display: "flex" }} spacing={6} xs={12}>
                  <Grid item xs={3}>
                    <div className="form-block" id="signupBlock3">
                      <form>
                        <div
                          className="form-field"
                          id="signupform"
                          style={webStyle.TimetopdescThirty}>
                          <span style={webStyle.TimeTopstyleHeader as React.CSSProperties}>Title</span>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.TimemarginLeft0}>
                            <span style={webStyle.TimeblurvalueStyle as React.CSSProperties}>Timing</span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Grid>
                  <>
                    <Grid item xs={6} spacing={6}>
                      <div className="form-block" id="signupBlock3">
                        <form>
                          <div
                            className="form-field"
                            id="signupform"
                            style={webStyle.TimetopdescThirty}
                          >
                            <span style={webStyle.TimeTopstyleHeader as React.CSSProperties}>Description</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.TimemarginLeft0}
                            >
                              <span style={{ ...webStyle.TimeblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>1 Late:8 points<br />2 Late:10 points</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="form-block" id="signupBlock3">
                        <form>
                          <div
                            className="form-field"
                            id="signupform"
                            style={{ ...webStyle.TimetopdescThirty, width: "max-content" }}
                          >
                            <span style={webStyle.TimeTopstyleHeader as React.CSSProperties}>Points (Weightage)</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.TimemarginLeft0}
                            >
                              <span style={{ ...webStyle.TimeblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>10 points</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                  </>
                </Grid>
              </FormControl>
            </Grid >



          </Grid >
          <Divider light style={{ marginTop: "20px", marginBottom: "20px" }} />
          <Grid
            container
            alignItems="center"
            style={webStyle.TimeinformContainer as React.CSSProperties}
          >
            <Grid
              className="first"
              item
              style={webStyle.TimeinformSecondContainerHead}
              xs={12}
            >
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.TimeheaderMain10}>
                    <span style={webStyle.TimeHeadtopStyle22 as React.CSSProperties}>Daily KPI Report</span>
                  </FormLabel>

                </FormControl>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.TimeinformationSubContainerTable}
              xs={12}  >
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.Timeheadermandatory}>
                    <span style={{ ...webStyle.labelStyle10 as React.CSSProperties, fontWeight: 500 }}>Applicable Occurrence</span>
                  </FormLabel>
                </FormControl>
                <TableContainer style={webStyle.TimetablemainContainer}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow 
                      style={webStyle.TimemaintableHead}
                      >
                        <TableCell 
                        style={{ fontFamily: "Open Sans", fontSize: "14px", color: "black", fontWeight: 600, paddingLeft: "70px", width: "33%" }}
                        >Occurrence Title</TableCell>
                        <TableCell style={{ 
                          fontFamily: "Open Sans", 
                          fontSize: "14px", 
                          color: "black", 
                          fontWeight: 600, 
                          width: "33%" 
                          }}>
                          Deductible Value
                        </TableCell>
                        <TableCell style={{ 
                          width: "18%" 
                          }}>
                          <Button 
                          onClick={this.handleAddRow} 
                          style={{ fontFamily: 'Open Sans', 
                          backgroundColor: "FDD100", 
                          color: "black", 
                          marginRight: "20px", 
                          width: "220px", 
                          fontSize: "16px", fontWeight: 600, textTransform: "none", paddingTop: "6px", paddingBottom: "6px", borderRadius: "10px" }}>
                            Add Occurrence
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.rows.map((row: any, index: any) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row" 
                          style={{ fontFamily: "Open Sans", fontSize: "14px", color: "black", paddingLeft: "65px" }}>{index + 1}. Late Occurrence</TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  id="signupform"
                                  className="form-field"
                                >
                                  <Box
                                    className="control"
                                    style={webStyle.TimemarginLeft0}
                                    id="signupfieldcontrol"
                                  >
                                    <input
                                      data-test-id="txtInputEmail"
                                      type="text"
                                      id="pronounce-control"
                                      placeholder={row.points}
                                      style={{ ...webStyle.inputmainTable, backgroundColor: "White", width: "30%", color: "Black", textAlign: "start", fontFamily: "Open Sans", fontWeight: 400, fontSize: "14px" }}
                                      className="control-input"
                                      name="email"
                                    />
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <a 
                            style={{ color: "#ff5c5c", textDecoration: "underline" }}
                            data-test-id="clickforRemoveTime"
                            onClick={() => this.handleRemoveRow(index)} 
                            >Remove Occurrence</a>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

              </>
            </Grid>
          </Grid >

          <Grid style={webStyle.TimebuttonmainContainer}>
            <Box style={{ marginTop: '50px', marginBottom: '40px' }}>
              <Button style={webStyle.TimeSubmitBtn as React.CSSProperties}>
                Submit
              </Button>
              <Button variant="outlined" style={webStyle.TimeCancelBtn as React.CSSProperties} >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Box >
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {




  TimemaintableHead: {
    backgroundColor: "#F5F5F5",
  },
  labelStyle10: {
    fontSize: "22px",
    fontFamily: "Open Sans",
    fontWeight: 500,
    color: "black",
  },
  Timeheadermandatory: {
    display: "flex",
    MarginTop: "30px",
    alignItems: "baseline",
    gap: "4px",
  },
  TimetablemainContainer: {
    marginTop: "15px",
    borderRadius: "10px",
    border: "1px solid #979797",
  },
  TimeinformationSubContainerTable: {
    marginTop: "24px",
    width: "98%",
  },
  inputmainTable: {
    background: 'transparent',
    border: '0.5px solid #979797',
    width: "70%",
  },
  Editbutton: {
    backgroundColor: "FDD100",
    color: "black",
    fontFamily: 'Open Sans',
    padding: "15px 20px 15px 20px",
    width: "200px",
    fontSize: "16px",
    borderRadius: "10px",
    fontWeight: 600,
    textTransform: "none"
  },

  TimeCancelBtn: {
    fontWeight: 600,
    fontFamily: 'Open Sans',
    textTransform: "none",
    backgroundColor: "white",
    fontSize: "16px",
    width: "170px",
    color: "black",
  },
  informatSubContainerTable: { marginTop: "24px", width: "98%" },
  TimeinformSecondContainerHead: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "24px",
    alignItems: "initial",
    width: "98%"
  },
  TimetopdescThirty: {
    marginTop: "30px",
  },
  container: {
    height: "100%",
    width: "100%",
  },

  TimelabelStyleoptionhead: {
    color: "black",
    fontWeight: 500,
    fontSize: "22px",
    fontFamily: "Open Sans"
  },
  TimeTopstyleHeader: {
    fontSize: "18px",
    color: "#c8ccc9",
    fontWeight: 700,
    fontFamily: "Open Sans"
  },
  TimeblurvalueStyle: {
    color: "black",
    marginTop: "10px",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: 400
  },
  TimerowarrowDisplay: {
    alignItems: "center",
    display: "flex",
  },
  TimeHeadStyleTop1: {
    color: "black",
    fontFamily: "Open Sans",
    fontSize: "18px",
  },
  TimeHeadtopStyle22: {
    color: "black",
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Open Sans"
  },

  TimemarginLeft0: {
    marginLeft: 0,
    marginTop: 10
  },

  TimebuttonmainContainer: { display: "flex", gap: "20px", margin: "0 40px" },

  TimeheaderMain10: {
    display: "flex",
    alignItems: "baseline",
    gap: "4px",
    MarginTop: "30px"
  },
  TimeSubmitBtn: {
    fontFamily: 'Open Sans',
    marginRight: "20px",
    color: "black",
    fontSize: "16px",
    width: "170px",
    backgroundColor: "FDD100",
    fontWeight: 600,
    textTransform: "none",

  },

  TableC: {
    fontSize: "14px",
    color: "black",
    fontWeight: 600,
    fontFamily: "Open Sans",
  },

  Timemaincontainer: {
    background: "#F3F4F5",
    height: "64px",
    marginRight: "-20px"
  },
  TimeinformContainer: {
    padding: "0 32px",
    flexDirection: "column",
    alignItems: "baseline",
  },
  TimeinformationSubject: {
    marginTop: "24px",
    width: "800px",
  },
  TimeleftSide: {
    padding: "19px 0px 21px 38px"
  },
  TimenullContainer: {
    padding: "0px 44px 0px 0px"
  },

};
// Customizable Area End
