
import React from "react";
import DashboardController, { Props } from "./DashboardController";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { arrowDown, success_event, active_calendar ,rightAroww, leftAroww} from "./assets";
import { theme } from "./Template.web";
import Modal from "@material-ui/core/Modal";
import Checkbox from '@material-ui/core/Checkbox';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


import { Box, styled} from '@material-ui/core';
import moment from "moment";


// @ts-ignore





export default class CalendarComponent extends DashboardController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    renderCustomHeader = ({ date, decreaseMonth, increaseMonth }: any) => (
        <div className="custom-header">
            <button
            data-testid="decbutton"
                type="button" 
                onClick={(e) => this.handleDecreaseMonthClick(decreaseMonth, e)}

                aria-label="Previous Month"
                className="icon-button"
            >
                <img src={leftAroww} alt="Previous" />
            </button>
            <span style={{
                fontFamily: "Open Sans",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: '19.07px',
                textAlign: 'left',
                color: '#000000'
            }}>{date?.toLocaleString('default', { month: 'short' })} {date?.getFullYear()}</span>
            <button
                type="button" 
                onClick={(e) => this.handleIncreaseMonthClick(increaseMonth, e)} // Pass the function as a parameter
                aria-label="Next Month"
                className="icon-button"
            >
                <img src={rightAroww} alt="Next" />
            </button>
        </div>
    );
   ;
  
renderCustomDayContents = (day: any, date: Date): any => {
    const today = new Date();
    const currentMonth = today.getMonth();
  
    const isSunday = date?.getDay() === 0;
    const isNotCurrentMonth = date?.getMonth() !== currentMonth;
    const dayNamesElement = document.querySelector('.react-datepicker__day-names');

    if (dayNamesElement) {
        const newDayNames = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
    
        dayNamesElement.innerHTML = newDayNames.map((day, index) => 
            `<span style="display: flex; justify-content: space-around;" class="${index === 6 ? 'red' : ''}">${day}</span>`    ).join('');
    }
    
  
    return (
      <span 
        className={`${isSunday ? 'highlight-sunday' : ''} ${isNotCurrentMonth ? 'not-current-month' : ''}`}
      >
        {day}
      </span>
    );
  };

groupEventsByDate(events: any) {
  const { selectedMonth, selectedYear } = this.state;
  const groupedEvents: any = {};

  events.forEach((event: any) => {
    const startDate = new Date(event.attributes.start_date);
    const endDate = event.attributes.end_date ? new Date(event.attributes.end_date) : startDate;

    const eventMonth = startDate.getMonth();
    const eventYear = startDate.getFullYear();

    if (eventMonth === selectedMonth && eventYear === selectedYear) {
      const dateKey = `${startDate.toISOString().split('T')[0]} - ${endDate.toISOString().split('T')[0]}`;
      if (!groupedEvents[dateKey]) {groupedEvents[dateKey] = []; } groupedEvents[dateKey].push(event);
    }
  });

  return groupedEvents;
}




renderEvents() {
    const groupedEvents = this.groupEventsByDate(this.state.showEventName);

    if (Object.keys(groupedEvents).length === 0) {
        return <p className="noEvent">No events for this month</p>;
    }

    return this.renderSortedEvents(groupedEvents);
}

groupEventsByDateSingle(events :any) {
    const { selectedMonth, selectedYear } = this.state;
    return events.reduce((acc :any, event:any) => {
        const eventStartDate = new Date(event.attributes.start_date);
        const eventEndDate = event.attributes.end_date ? new Date(event.attributes.end_date) : eventStartDate;

        
        for (let date = new Date(eventStartDate); date <= eventEndDate; date.setDate(date.getDate() + 1)) {
            this.addEventToAccumulator(date, event, selectedMonth, selectedYear, acc);

        }
        return acc;
    }, {});
}

addEventToAccumulator(date: Date, event: any, selectedMonth: number, selectedYear: number, acc: any) {
    const eventMonth = date.getMonth();  
    const eventYear = date.getFullYear();  
    if (eventMonth === selectedMonth && eventYear === selectedYear) {
        const dateKey = date.toLocaleDateString();  

        if (!acc[dateKey]) {
            acc[dateKey] = [];
        }

        acc[dateKey].push(event);
    }
}
formatDateSingleEvent(selectedDate :any) {
    if (selectedDate && !isNaN(new Date(selectedDate).getTime())) {
        const date = new Date(selectedDate);
        const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        const formattedDate = localDate.toISOString().split('T')[0];
        return formattedDate;
    }
}
renderEventBlock(formattedDate: any, groupedEvents: any, formattedSelectedDate: any) {
    return (
        <div className="event-block">
            <div>
                <h5>{this.formatDateEnvent(formattedDate)}</h5>
                <ul>
                    {groupedEvents[formattedSelectedDate].map((event: any) => (
                        <li key={event.id}>
                            <h5 className="event-title-name">{event.attributes.event_name}</h5>
                            <span className="event-remind">1h Early Reminder</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

renderSelectedDateEvents() {
    const { selectedDate } = this.state;
    const groupedEvents = this.groupEventsByDateSingle(this.state.showEventName);
    const formattedSelectedDate = new Date(selectedDate).toLocaleDateString();
    const formattedDate = this.formatDateSingleEvent(selectedDate)
  
    if (!selectedDate || !groupedEvents[formattedSelectedDate]) {
        return <p className="noEvent">No events for the selected date</p>;
    }
    return this.renderEventBlock(formattedDate, groupedEvents, formattedSelectedDate);
  }



    render() {
        const selectStyled: any = {
            appearance: 'none',
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            color: this.state.dayType === "Choose Day Type" ? "#959595" : "#000000",
        };
      
        

const sliceString= this.state.activateMessage.split('!'); 

         return (
            // Customizable Area Start
            <>
                
                <div
                    className={`content-wrapper ${this.state.isPopupVisible ? "right-popup-open"
                        : ""}`}
                        style={{ position: this.state.isPopupVisible ? 'fixed' : 'relative' }}

                >
                    <div className="calender-main">
                        <div className="custom-calender">
                            <div className="calendar-container">
                    
                                <Calendar
                                  onActiveStartDateChange={({ activeStartDate }) => {
                                    this.handleMonth(activeStartDate)
                                 }}
                                    data-testid="calendar"
                                    tileClassName={({ date }) => {
                                        
                                        const day = date?.getDate().toString().padStart(2, '0');
                                        const month = (date?.getMonth() + 1).toString().padStart(2, '0');
                                        const year = date?.getFullYear();
                                        const realDate = `${year}-${month}-${day}`;
                                        
                                        return  this.getTileClassName(realDate)
                                    }}    
                                   
                            
                                 formatMonthYear={(locale:any, date:any) => this.formatMonthLabel(locale, date)}
                                    prev2Label={null}
                                    next2Label={null}
                                    onChange={this.handleDateChange}
                              />
                             
                            </div>
                        </div>
                        <div className="custom-right-sidebar" style={{padding:"15px"}}>
                        {this.state.userRole !== 'employee' && (
                            <div className="right-sidebar-top">
                                <button  style={{cursor:'pointer'}}data-testid="addevent" className="button" onClick={this.openPopup}>+  Add Event</button>
                            </div>
                         )} 
                            <div className="event-blocks" id="event-blocks">
                                <h3>Events of the Month</h3>
                              {this.state.selectedDate ? this.renderSelectedDateEvents() : this.renderEvents()}
                            </div>
                        </div>
                    </div>
                    {
                        this.state.isPopupVisible && (
                            <>
                                <StyledPopup className="popup-overlay" ></StyledPopup>
                                <div className="right-popup">
                                    <div className="popup-header">
                                        <h5>Add Event</h5>
                                        <span  data-testid="close" className="close" onClick={this.openPopup}></span>
                                    </div>

                                    <div className="event-scoller">
                                        <StyledHeading >
                                            {this.state.showeEventDateFirst + " - " +this.state.showeEventDateLast}
                                            </StyledHeading>
                                    </div>

                                    <div className="form-block">
                                        <form onSubmit={e => {this.handleEventFormSubmit(e) }} >
                                            <div className="form-field">
                                                <span className="label">Event Name</span>
                                                <div className="control">
                                                    <input type="text" className="control-input" placeholder="Add Event Name" name="event_name"  maxLength={255}
                                                       onChange={(e) => {this.setState({ createEventData: { ...this.state.createEventData, event_name: e.target.value } })}}
                                                        id="event-name" />
                                                </div>
                                            </div>

                                            <div className="form-field half checkbox-field">
                                                <div className="field date-field-custom">
                                                    <span className="label">Date</span>
                                                    <div className="control">
                                                        {this.state.isMultiple ? (
                                                            <DatePickerBox>
                                                                <div  ref={this.datePickerRef}>
                                                                <DatePicker 
                                                                   onInputClick={()=>this.setState({datepickerClose:true})}
                                                                   open={this.state.datepickerClose}
                                                                    data-test-id= "handleMainModaldouble"
                                                                    selectsRange={true}
                                                                    className="control-input pickdate"
                                                                    startDate={this.state.createEventData.start_date}
                                                                    endDate={this.state.createEventData.end_date}
                                                                    onChange={(update :any) => {
                                                                        const [start, end] = update;
                                                                        if(end && start){
                                                                            this.setState({datepickerClose:false})
                                                                        }
                                                                        this.setState({
                                                                              createEventData: {
                                                                                ...this.state.createEventData,
                                                                                start_date: start,
                                                                                end_date: end,
                                                                              },
                                                                        });
                                                                    }}
                                                                    calendarStartDay={1}
                                                                    placeholderText="Pick a date"
                                                                    renderCustomHeader={this.renderCustomHeader}
                                                                    locale="en-US"
                                                                    renderDayContents={this.renderCustomDayContents} 
                                                                    value={this.formatDateRange(
                                                                        this.state.createEventData.start_date,
                                                                        this.state.createEventData.end_date
                                                                      )}

                                                                />
                                                                </div>
                                                            </DatePickerBox>

                                                        ) : (
                                                            <div  ref={this.datePickerRef} >
                                                                <DatePickerBox>
                                                                    <DatePicker 
                                                                     onInputClick={()=>this.setState({datepickerClose:true})}
                                                                     open={this.state.datepickerClose}
                                                                        className="control-input pickdate"
                                                                        selected={this.state.createEventData.start_date}
                                                                        calendarStartDay={1}
                                                                        onChange={(date :any) => {
                                                                            this.setState({
                                                                                createEventData: {
                                                                                    ...this.state.createEventData,
                                                                                    start_date: date,
                                                                                },
                                                                                datepickerClose:false
                                                                            });
                                                                        }}
                                                                        data-test-id= "handleMainModalCalender"
                                                                        placeholderText="Pick a date"
                                                                        renderCustomHeader={this.renderCustomHeader}
                                                                        locale="en-US"
                                                                        renderDayContents={this.renderCustomDayContents} 
                                                                        value={this.formatSingleDate(
                                                                            this.state.createEventData.start_date,
                                                                          )}
                                                                    />
                                                                </DatePickerBox>


                                                            </div>

                                                        )}
                                                        <StyledSpan
                                                         >{<img src={active_calendar} alt="logo" height={25} width={25} />}</StyledSpan>
                                                    </div>
                                                </div>
                                                <div className="field">
                                                    <div className="control">
                                                        <div className="checkbox">
                                                            <Checkbox
                                                                checked={this.state.checkBoxRes}
                                                                color="primary"
                                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                name="restricted"
                                                                onChange={this.handleCheckboxChangeRes}
                                                                data-testid="restricted"
                                                            />

                                                            <label >Restricted</label>
                                                        </div>
                                                        <div className="checkbox">
                                                            <Checkbox
                                                                checked={this.state.checkBoxMul}
                                                                data-testid="multipleCheckbox"
                                                                onChange={this.handleCheckboxChange}
                                                             color="primary"
                                                                name="isMultiple"
                                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            />

                                                            <label >Multiple</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-field">
                                                <span className="label">Description</span>
                                                <div className="control">
                                                    <textarea placeholder="Describe About The Event"  maxLength={5000}
                                                     onChange={(e:any) => {this.setState({ createEventData: { ...this.state.createEventData, description: e.target.value } })}}
                                                        className="control-input" name="description" id="" cols={30} rows={5}></textarea>
                                                </div>
                                            </div>
                                            <div className="form-field select-field">
                                                <div className="control">
                                                    <div>
                                                        <select style={selectStyled} className="control-input" name="day_type"
                                                            onChange={(e:any) => {  this.setState({  dayType: e.target.value, createEventData: { ...this.state.createEventData, day_type: e.target.value,   } })}}
                                                        >
                                                            <option  className="day_type" value="Choose Day Type">Choose Day Type</option>
                                                            <option  className="day_type" value="full_day">Full Day</option>
                                                            <option  className="day_type" value="half_day">Half Day</option>
                                                        </select>
                                                    </div>

                                                    <img className="select-arrow" src={arrowDown} alt="logo" width={20} height={20} />

                                                </div>
                                            </div>

                                            <div className="form-field checkbox-field">
                                                <div className="control">
                                                    <div className="checkbox">
                                                        <Checkbox
                                                        data-test-id="checkboxID"
                                                         checked={this.state.notify}
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            onChange={this.handleCheckboxChangeNotify}
                                                        />
                                                        <label id="event_check1">Notify People via Infoboard</label><br />
                                                    </div>
                                                    <div className="checkbox">
                                                        <Checkbox

                                                            data-testid="reprocess"
                                                            checked={this.state.isChecked}
                                                            onChange={this.handleCheckboxChange}
                                                            color="primary"
                                                            name="isChecked"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                        <label id="event_check2">Re-process Leave Applications Based On This Holiday</label>


                                                    </div>
                                                    {this.state.isChecked && (
                                                        <p>
                                                            (Leaves that are already applied for this holiday will be reprocessed and the balance will be adjusted accordingly.)
                                                        </p>
                                                    )}

                                                </div>
                                            </div>
                                            
                                            <div className="form-field otp-button d-flex">
                                                <div className="control">
                                                    <input type="submit" style={(this.state.createEventData.event_name && this.state.createEventData.description && this.state.createEventData.day_type && this.state.createEventData.start_date)?webStyle.saveEnable : webStyle.saveDisable} data-test-id="save" className="button" value="Save" />
                                                    <input type="reset" data-testid="cancel1" className="button reset" value="Cancel" onClick={this.openPopup} />
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div >
                <StyledModal
                    open={this.state.open  }
                    onClose={this.handleOpenModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <>
                        <div style={webStyle.paper as React.CSSProperties}>
                            <div className="center-block-header final-screen text-center">
                                <CongratulationsHeading >Congratulations!</CongratulationsHeading>
                                <StyledPara >Event Added Successfully</StyledPara>
                                <div className="header-logo">
                                    <img src={success_event} alt='logo' width={284} height={278} />
                                </div>
                            </div>
                        </div>
                    </>
                </StyledModal>
               
            </>
            // Customizable Area End
        );
    }
}
const webStyle = {
    paper: {
        position: "absolute",
        width: 700,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(8, 8, 8)
    },
    saveEnable:{

    },
    saveDisable:{
        backgroundColor: "#F7F7F7",
        color:"#878787",
        cursor: "not-allowed",
        pointerEvents: "none",
        border:"none"
    }
}
const StyledModal=styled(Modal)({
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
})
const StyledSpan = styled('span')({
    position: 'absolute',
    top: '45%',
    marginTop: '-8px',
    pointerEvents: 'none',
    right: '15px',
    cursor: 'pointer',
  });
  const StyledPopup=styled(Box)({
    height:"100vh"
  })
  const StyledHeading=styled('h6')({
     fontFamily: 'Open Sans',
     fontSize: '18px',
     fontWeight: 600,
     lineHeight: '20px',
     textAlign: 'left',
     marginLeft:'27px'
  })
  const CongratulationsHeading=styled('h3')({
    color: "black",
    fontSize:"28px"
  })
  const StyledPara=styled('p')({
     color: "#707070",
     fontSize:"16px"
  })
 
const DatePickerBox = styled(Box)({
    
    '& .react-datepicker__input-container .control-input':{
      cursor:"pointer"
    },

    '& .react-datepicker__header': {
        border: '1px solid white !important',
        backgroundColor: "#FFFFFF !important",
    },
    '& .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover': { // Additional custom class
        background: '#fdd10050'
    },
    '& .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range': {
        background: '#fdd100 !important',
        color: 'black'
    },
    '& .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover': {
        background: '#fdd10050'
    },
    '& .react-datepicker__day--range-end': {
        background: '#fdd100 !important',
        color: 'white !important'
    },
    '& .react-datepicker__month-container': {
        width: "267px",
        padding: "13px 20px 15px 20px"

    },
    '& .react-datepicker__day-names': {
        fontFamily: "Open Sans",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: '19.07px',
        textAlign: 'left',
        color: '#000000'
    },
    '& .react-datepicker__week': {
        fontFamily: "Open Sans",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: '16.34px',
        textAlign: 'left',
        marginLeft: '-8px'
    },
    '& .react-datepicker__day--today': {
        fontFamily: "Open Sans",
        fontSize: '12px',
        fontWeight: 400,
        height: '27px',
        width: '27px',
        background: '#FDD100 !important',
        borderRadius: '50px',
        color:'white'
    },
    '& .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle': {
        display: 'none !important'
    },
    '& .react-datepicker__day': {
        borderRadius: '50% !important'
    },
    '& .react-datepicker__day.react-datepicker__day--023.react-datepicker__day--today': {
        borderRadius: '50%',
        fontSize: '12px',
        color: 'white',
        width: '27.2px',
        hieght: '27.2px',
    },
});