import React from "react";

import {
  Box,
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import "../../dashboard/src/dashboard.css"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Calendar_Icon, Comment_Icon, close_Icon } from './assets';
import Modal from "@material-ui/core/Modal";


// Customizable Area End

import AddKpiController, { Props } from "./AddKpiController";

export default class AddKpiReport extends AddKpiController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <Box style={webStyles.maincontainer}>
        <Box style={webStyles.headerSection}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid style={webStyles.leftContainer} item>

              <ArrowBackIosIcon style={{ fontSize: '18px', marginRight: '20px' }} />
              <span style={webStyles.labelStyle1}>Daily KPI Report</span>

            </Grid>
            <Grid item style={webStyles.rowDisplay}>
              <Box style={webStyles.calendarWrapper}>
                <div style={webStyles.imgDate}>
                  <p style={webStyles.calendarImg}>
                    <img style={{ height: '24px' }} src={Calendar_Icon} alt="calendar_icon" />
                  </p>
                  <span style={webStyles.calendarWrapperSpan}>25-Aug-2023</span>
                </div>
                <span style={{ marginRight: '14px' }}>FRIDAY</span>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Grid container className='stepperContainer' spacing={2}>
          <Grid
            item
            alignItems="center"
            lg={12}
          >
            <Box style={webStyles.addkapiwrapper}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box style={webStyles.meetingDetail} display={'flex'}>
                  <Box style={{width:'15%',alignContent:'center'}}>KPI</Box>
                  <Box style={{width:'35%',alignContent:'center'}}>DKR Title</Box>
                  <Box style={{width:'40%',display:'flex',justifyContent:'end'}}><div style={{width:"300px",display:'flex',justifyContent:'flex-start'}}>DKR Input</div></Box>
                  <Box style={{width:'10%',textAlign:"end"}}>Remarks</Box>
                </Box>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} justifyContent={'space-between'} mb='20px' style={webStyles.meetingDetail}>
                  <Box style={webStyles.kpiTitle}>KPI - 1</Box>
                  <Box style={webStyles.kpiDescription}>Today's Sales</Box>
                  <Box style={{width:'40%',textAlign:'end'}}><input
                    type="text"
                    placeholder="Enter value*"
                    style={webStyles.TextInput}
                    name="Sales"
                  /></Box>
                  <Box style={{width:'10%',textAlign:'end',alignContent:'center'}}><img alt="openModel" onClick={()=>this.setState({isModal:true})} src={Comment_Icon}/></Box>
                </Box>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} justifyContent={'space-between'} mb='20px' style={webStyles.meetingDetail}>
                  <Box style={webStyles.kpiTitle}>KPI - 2</Box>
                  <Box style={webStyles.kpiDescription}>Today's Collection</Box>
                  <Box style={{width:'40%',textAlign:'end'}}><input
                    type="text"
                    name="Collection"
                    style={webStyles.TextInput}
                    placeholder="Enter value*"
                  /></Box>
                  <Box style={{width:'10%',textAlign:'end',alignContent:'center'}}><img src={Comment_Icon}/></Box>
                </Box>
              </Box>
              <Box style={webStyles.meetingDetail} display={'block'}>
                <Box display={'flex'} justifyContent={'space-between'} mb='20px'>
                  <Box style={webStyles.kpiTitle}>KPI - 3</Box>
                  <Box style={webStyles.kpiDescription}>Today's Walk-in</Box>
                  <Box style={{width:'40%',textAlign:'end'}}><input
                    style={webStyles.TextInput}
                    placeholder="Enter value*"
                    type="text"
                    name="Walk"
                  /></Box>
                  <Box style={{width:'10%',textAlign:'end',alignContent:'center'}}><img src={Comment_Icon}/></Box>
                </Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                <Box style={webStyles.kpiTitle}></Box>
                  <Box style={webStyles.kpiDescription}>Today's Converted</Box>
                  <Box style={{width:'40%',textAlign:'end'}}><input
                    type="text"
                    style={webStyles.TextInput}
                    name="Converted"
                    placeholder="Enter value*"
                  /></Box>
                  <Box style={{width:'10%',textAlign:'end',alignContent:'center'}}><img src={Comment_Icon}/></Box>
                </Box>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} justifyContent={'space-between'} mb='20px' style={webStyles.meetingDetail}>
                  <Box style={webStyles.kpiTitle}>KPI - 4</Box>
                  <Box style={webStyles.kpiDescription}>Today's Visits</Box>
                  <Box style={{width:'40%',textAlign:'end'}}><input
                    style={webStyles.TextInput}
                    type="text"
                    placeholder="Enter value*"
                    name="Visits"
                  /></Box>
                  <Box style={{width:'10%',textAlign:'end',alignContent:'center'}}><img src={Comment_Icon}/></Box>
                </Box>
              </Box>
              <Box style={webStyles.meetingDetail} display={'block'}>
                <Box display={'flex'} justifyContent={'space-between'} mb='20px'>
                  <Box style={webStyles.kpiTitle}>KPI - 5</Box>
                  <Box style={webStyles.kpiDescription}>Today's Stock</Box>
                  <Box style={{width:'40%',textAlign:'end'}}><input
                    style={webStyles.TextInput}
                    placeholder="Enter value*"
                    name="Stock"
                    type="text"
                  /></Box>
                  <Box style={{width:'10%',textAlign:'end',alignContent:'center'}}><img src={Comment_Icon}/></Box>
                </Box>
                <Box display={'flex'} justifyContent={'space-between'} mb='20px'>
                <Box style={webStyles.kpiTitle}></Box>
                  <Box style={webStyles.kpiDescription}>Today's Converted</Box>
                  <Box style={{width:'40%',textAlign:'end'}}><select
                    name="Converted"
                    data-testid="Converted"
                    id="Converted"
                    style={webStyles.selectBox}
                  >
                    <option id="Select Option" value="Select Option">Select Option*</option>
                    <option id="one" value="One">One</option>
                    <option id="two" value="Two">Two</option>
                  </select></Box>
                  <Box style={{width:'10%',textAlign:'end',alignContent:'center'}}><img src={Comment_Icon}/></Box>
                </Box>
              </Box>
              <Box style={webStyles.meetingDetail} display={'block'}>
                <Box display={'flex'} justifyContent={'space-between'} mb='20px'>
                  <Box style={webStyles.kpiTitle}>Productivity</Box>
                  <Box style={webStyles.kpiDescription}>How many posters did you do today?</Box>
                  <Box style={{width:'40%',textAlign:'end'}}><input
                    type="text"
                    style={webStyles.TextInput}
                    name="Productivity"
                    placeholder="Enter value*"
                  /></Box>
                  <Box style={{width:'10%',textAlign:'end',alignContent:'center'}}><img src={Comment_Icon}/></Box>
                </Box>
                <Box display={'flex'} justifyContent={'space-between'} mb='20px'>
                <Box style={webStyles.kpiTitle}></Box>
                  <Box style={webStyles.kpiDescription}>How many mini videos have you edited today?</Box>
                  <Box style={{width:'40%',textAlign:'end'}}><input
                    style={webStyles.TextInput}
                    type="text"
                    placeholder="Enter value*"
                    name="videos"
                  /></Box>
                  <Box style={{width:'10%',textAlign:'end',alignContent:'center'}}><img src={Comment_Icon}/></Box>
                </Box>
                <Box display={'flex'} justifyContent={'space-between'} mb='20px'>
                <Box style={webStyles.kpiTitle}></Box>
                  <Box style={webStyles.kpiDescription}>How many major videos have you edited today?</Box>
                  <Box style={{width:'40%',textAlign:'end'}}><input
                    type="text"
                    placeholder="Enter value*"
                    name="edited"
                    style={webStyles.TextInput}
                  /></Box>
                  <Box style={{width:'10%',textAlign:'end',alignContent:'center'}}><img src={Comment_Icon}/></Box>
                </Box>
                <Box display={'flex'} justifyContent={'space-between'} mb='20px'>
                <Box style={webStyles.kpiTitle}></Box>
                  <Box style={webStyles.kpiDescription}>How many YouTube videos have you optimized today?</Box>
                  <Box style={{width:'40%',textAlign:'end'}}><input
                    type="text"
                    placeholder="Enter value*"
                    name="many"
                    style={webStyles.TextInput}
                  /></Box>
                  <Box style={{width:'10%',textAlign:'end',alignContent:'center'}}><img src={Comment_Icon}/></Box>
                </Box>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} justifyContent={'space-between'} mb='20px' style={webStyles.meetingDetail}>
                  <Box style={webStyles.kpiTitle}>Occurrence</Box>
                  <Box style={webStyles.kpiDescription}>Today’s Collection Backup</Box>
                  <Box style={{ width: '40%', textAlign: 'end' }}><select
                    name="Collection"
                    data-testid="Collection"
                    id="Collection"
                    style={webStyles.selectBox}
                  >
                    <option id="Select Option" value="Select Option">Select Option*</option>
                    <option id="one" value="One">One</option>
                    <option id="two" value="Two">Two</option>
                  </select></Box>
                  <Box style={{width:'10%',textAlign:'end',alignContent:'center'}}><img src={Comment_Icon}/></Box>
                </Box>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'} justifyContent={'space-between'} mb='20px' style={webStyles.meetingDetail}>
                  <Box style={webStyles.kpiTitle}>Checklist</Box>
                  <Box style={webStyles.kpiDescription}>Reminder description will be dispalyed here</Box>
                  <Box style={{width:'40%',textAlign:'end'}}></Box>
                  <Box style={{width:'10%',textAlign:'end',alignContent:'center'}}></Box>
                </Box>
              </Box>
              <Box style={webStyles.buttonContainer}>
                <div className="control kpi-report-btn" id="signupfieldcontrol2">
                  <button
                    type="button"
                    data-test-id="kpiReportNextBtn"
                    style={webStyles.saveCotinueBtn}
                    className="button submit"
                  >
                    Save & Continue
                  </button>
                </div>
              </Box>
            </Box>
          </Grid>
        <Modal
            open={this.state.isModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div 
              style={webStyles.modalContainer}
              id="modal"
              className="center-block-header text-start"
              >
              <div style={{ display: "flex", justifyContent: 'space-between' }}>
                <div style={{color:'rgba(0, 0, 0, 1)',fontSize:'16px',fontWeight:400}}>Today's Sales</div>
                <img
                  src={close_Icon}
                  alt="close"
                  style={{ marginBottom: "19px" }}
                  onClick={()=>this.setState({isModal:false})}
                />
              </div>
              <div>
                <textarea rows={6} cols={42} placeholder="Write Your Remark Here…" >

                </textarea>
              </div>
              <Box>
                <div className="control kpi-report-btn" id="signupfieldcontrol2">
                  <button
                    type="button"
                    data-test-id="kpiReportSubmitbtn"
                    style={webStyles.cotinueBtn}
                    className="button submit"
                  >
                    Submit
                  </button>
                </div>
              </Box>
            </div>
            </Modal>
        </Grid>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
    calendarWrapper: {
      display: "flex",
      justifyContent: "space-between",
      width: "298px",
      height: "62px",
      background: "#fff",
      alignItems: "center",
      padding: "15px 20px",
      borderRadius: "8px"
    },
    kpiTitle:{
      width:'15%',
      alignContent:'center',
      fontWeight:600,
      fontSize:"18px"
    },
    kpiDescription:{
      width:'35%',
      alignContent:'center',
      fontWeight:400,
      fontSize:"18px"
    },
    addkapiwrapper: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "32px",
      marginRight: "32px"
    },
    meetingDetail: {
      boxShadow: "0px 4px 18px 0px rgba(0, 0, 0, 0.1)",
      padding: "20px",
      width: "100%",
      fontSize: "18px",
      fontWeight: 600,
      color: "#000000",
    },
    imgDate :{
      display: "flex",
      alignItems: "center",
      gap: "15px"
    },
    
    calendarWrapperSpan: {
      fontSize: "14px",
      fontWeight: 400,
    },
    
    calendarImg: {
      padding: "8px",
      borderRadius : "5px",
      backgroundColor: "#FFCC00"
    },
    modalContainer: {
      top: "50%",
      left: "50%",
      transform: `translate(-${"50%"}, -${"50%"})`, // Adjust the transform to center the modal
      position: "absolute",
      width: "375px",
      backgroundColor: "white",
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px",
      padding: "32px",
      borderRadius: "10px",
      overflowY: "scroll",
      height: "292px"
    },
    TextInput: {
      padding: "11px 16px",
      borderRadius: "8px",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 400,
      color: "rgba(0, 0, 0, 0.4)",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      width:'303px'
    },
    selectBox:{
      width: "303px",
      padding: "11px 16px",
      borderRadius: "8px",
      color: "rgba(0, 0, 0, 0.4)",
      backgroundColor:'white'
    },
    maincontainer: {
      background: "#F3F4F5",
    },
    headerSection: {
      backgroundColor: '#F3F4F5',
      padding: '16px',
    },
    leftImgSection: {
  
    },
    leftContainer: {
      padding: "19px 0px 21px 38px",
      alignItems: 'center',
      display: 'flex',
    },
    bullet: {
      display: "inline-block",
      margin: "0 2px",
      transform: "scale(0.8)"
    },
    title: {
      fontSize: 14
    },
    pos: {
      marginBottom: 12
    },
    labelStyle: {
      color: "black",
      fontSize: "18px",
      fontWeight: "bold"
    },
    rowDisplay: {},
    labelStyle1: {
      fontSize: "18px",
      lineHeight: '24px',
      color: '#000000'
    },
    inputField: {
      width: "90%",
      background: 'transparent',
      border: '0.5px solid #979797'
    },
    cotinueBtn: {
      margin: "20px 0 0",
      fontWeight: 600,
      height:"55px"
    },
    saveCotinueBtn:{
      width:'335px',
      margin: "20px 0 0",
      fontWeight: 600,
      height:"55px"
    },
    marginLeft0: {
      marginLeft: 0,
      marginTop: 10
    },
    cancelBtn: {
      background: "transparent",
      margin: "10px 0 0"
    },
  
    buttonContainer: { display: "flex", gap: "20px", margin: "0 40px" },
    themeColor: {
      color: "#FDD100"
    },
    headerWithmandatory: {
      display: "flex",
      alignItems: "baseline",
      gap: "4px"
    },
    infIcon: { display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid #FDD100", borderRadius: "70%", width: "30px", height: "30px", marginLeft: "10px" }
  }as any;
  // Customizable Area End