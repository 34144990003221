import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Select, MenuItem, Card, CardContent
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { filterList } from "./assets";
import Modal from "@material-ui/core/Modal";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import TaskListController, {
  Props,
  configJSON,
} from "./TaskListController";

export default class TaskList extends TaskListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box sx={webStyle.mainWrapper}>


          <Box sx={webStyle.controlView}>
            <Typography style={{ fontFamily: 'Open Sans, sans-serif', color: 'black', fontSize: '16px', fontWeight: 'bold' }}>{configJSON.chooseDepartment}</Typography>
            <Typography style={{ fontFamily: 'Open Sans, sans-serif', color: 'red', fontSize: '16px', fontWeight: 'bold' }}>{configJSON.compulsory}</Typography>

            <Select
              value={this.state.department}
              onChange={this.handleDepartmentChange}
              style={{
                borderBottom: 'none', display: 'flex',
                alignItems: 'center',
                border: `1px solid rgba(0, 0, 0, 0.3)`,
                borderRadius: '10px',
                padding: '5px',
                backgroundColor: "#F6F6F9",
                width: '300px',
                marginLeft: '10px'
              }}
              IconComponent={() => (
                <IconButton style={webStyle.iconButton}>
                  <ExpandMoreIcon />
                </IconButton>
              )}
            >
              {this.state.options.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box sx={webStyle.headerView}>
            <Typography style={{ fontFamily: 'Open Sans, sans-serif', color: 'black', fontSize: '16px', fontWeight: 'bold' }}>{configJSON.allTemplates}</Typography>
            <img src={filterList} width={16} height={16} style={{ marginLeft: '10px' }} />
          </Box>

          <Box sx={webStyle.container}>
            {/* <Box sx={webStyle.alertContainer} >
            <img src={emptyList} alt="logo" width={175} height={125} style={{alignSelf:'center'}}/>
            <h3 style={{fontSize:'18px', textAlign:'center', color:'#000', fontWeight:'bold',fontFamily: 'Open Sans, sans-serif'}}>{configJSON.noRecord}</h3>
            <h5 style={{fontSize:'16px', textAlign:'center', color:'#000', fontWeight:'normal',fontFamily: 'Open Sans, sans-serif', width:'200px'}}>{configJSON.subNoRecord}</h5>
          </Box> */}
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '20px' }}>
              {this.state.listOfCards.map((item: any, index: any) => (
                <Card style={webStyle.card}>
                  <CardContent>
                    <div style={webStyle.subCard}>
                      <div>
                        <h3 style={{ fontFamily: 'Open Sans, sans-serif', color: 'black', fontSize: '20px', fontWeight: 'bold' }}>{item.title}</h3>
                        <p style={{ fontFamily: 'Open Sans, sans-serif', color: 'black', fontSize: '14px', fontWeight: 'bold' }}>{item.description}</p>
                      </div>
                      <div>
                        <Button data-test-id="btnAddExample"
                          variant="contained" color="primary" style={{ backgroundColor: '#FFCC00', alignItems: 'center', justifyContent: 'center' }}
                          onClick={()=>this.handleOpenModalWithId(item.id)}>
                          <label style={{ fontFamily: 'Open Sans, sans-serif', color: 'black', fontSize: '16px', fontWeight: 'bold', margin: 0, textTransform: 'none' }}>{item.buttonText}</label>
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </Box>

        </Box>
        <Modal
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
          open={this.state.openConfirmationModal}
          onClose={this.handleOpenModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <>
            <Box sx={webStyle.paper as React.CSSProperties}>
              <label style={{ fontFamily: 'Open Sans, sans-serif', color: 'black', fontSize: '24px', fontWeight: 'normal', textTransform: 'none', textAlign: 'center' }}>{configJSON.confirmation}</label>
              <Box sx={{ width: '60%', alignItems: 'center', justifyContent: 'center', display: 'flex', marginTop: '20px' }}>
                <Button data-test-id="btnAddExampleDuplicate" variant="contained" color="primary" style={{ backgroundColor: '#FFCC00', alignItems: 'center', justifyContent: 'center', width: '30%' }} onClick={() => this.handleDuplicateClick()}>
                  <label style={{ fontFamily: 'Open Sans, sans-serif', color: 'black', fontSize: '16px', fontWeight: 'bold', margin: 0, textTransform: 'none' }}>{configJSON.yes}</label>
                </Button>
                <Button variant="outlined" style={{ alignItems: 'center', justifyContent: 'center', color: '#FFFFFF', marginLeft: '15px', width: '30%' }} onClick={() => this.handleOpenModal()}>
                  <label style={{ fontFamily: 'Open Sans, sans-serif', color: 'black', fontSize: '16px', fontWeight: 'bold', margin: 0, textTransform: 'none' }}>{configJSON.no}</label>
                </Button>
              </Box>
            </Box>
          </>
        </Modal>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  paper: {
    position: "absolute",
    width: '30%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: '40px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    background: "#fff",
    borderTop: "1px solid #e3e5e7"
  },
  headerView: {
    display: 'flex',
    width: '100%',
    paddingVertical: '20px',
    background: "#e3e5e7",
    padding: '20px'
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  controlView: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '20px',
  },
  dropDown: {
    flex: 1,
    borderBottom: 'none',
  },
  control: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid rgba(0, 0, 0, 0.6)`,
    borderRadius: '10px',
    padding: '10px',
    backgroundColor: "#e3e5e7",
    width: '300px'
  },
  iconButton: {
    padding: '10px',
  },
  container: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%'
  },
  alertContainer: {
    flexDirection: 'column',
    alignItem: 'center',
    justifyContent: 'center',
    height: '600px',
    display: 'flex',
  },
  card: { backgroundColor: '#F6F6F9', width: '100%', marginBottom: '10px' },
  subCard: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }
};
// Customizable Area End
