export const kpimainlogo = require("../../email-account-login/assets/logo.png");
export const homeicon = require("../assets/homeOutlined.png");
export const homeActive = require("../assets/HomeActive.png");

export const template = require("../assets/templateOutlined.png");
export const templateActive = require("../assets/ReportsActive.png");

export const users = require("../assets/usersOutlined.png");
export const usersActive = require("../assets/UserActive.png");

export const department = require("../assets/departmentOutlined.png");
export const departmentActive = require("../assets/WorkActive.png");

export const reports = require("../assets/reportOutlined.png");
export const reportsActive = require("../assets/DocumentActive.png");

export const permissions = require("../assets/permissionsOutlined.png");
export const permissionsActive = require("../assets/PermissionActive.png");
export const editable = require("../assets/Edit 2.png");
export const barchart = require("../assets/Barchart.png");
export const linechart = require("../assets/Linechart.png");
export const lastchart = require("../assets/Lastchart.png");
export const fileNotFound = require("../assets/file-not-found.png");
export const notification = require("../assets/Notification.png");
export const oval = require("../assets/Oval.png");
export const setting = require("../assets/Setting.png");
export const KpiLogo = require("../assets/KpiLogo.png");
export const employeeId = require("../assets/employeeId.png");
export const role = require("../assets/role.png");
export const headOffice = require("../assets/headOffice.svg");
export const contactInfo = require("../assets/contactInfo.png");
export const email = require("../assets/email.svg");
export const time = require("../assets/time.svg");
export const timezone = require("../assets/timezone.svg");
export const state = require("../assets/state.svg");
export const performance_emp = require("../assets/competence_1.png");
export const department_performance = require("../assets/department_performance.png");
export const data_comparision = require("../assets/data_comaprision.png");
export const trends = require("../assets/trends.png");
export const calendar = require("../assets/calendar.png");
export const disableEdit = require("../assets/disable_edit.png");
export const arrowDown = require("../assets/arrow_down.png");
export const inactive_calendar = require("../assets/calendar.png");
export const active_calendar = require("../assets/active_calendar.png");
export const success_event = require("../assets/successevent.png");
export const profile = require("../assets/profile.png");
export const logout = require("../assets/logout.png");
export const like = require("../assets/like.png");
export const dislike = require("../assets/dislike.png");
export const badge = require("../assets/badge.png");
export const editable_profile = require("../assets/editable_profile.png");
export const country = require("../assets/country.png");
export const active_filter = require("../assets/active_filter.png");
export const inActive_filter = require("../assets/inactive_filter.png");
export const noun_clock = require("../assets/noun_clock.png");
export const profile_sidebar = require("../assets/profile_sidebar.png");
export const Kpi_gamecard= require("../assets/kpi_gamecard.png");
export const Kpi_report= require("../assets/kpi_report.png");
export const Kpi_review= require("../assets/kpi_review.png");
export const Kpi_statistics= require("../assets/kpi_statistics.png");
export const carrer_ladder= require("../assets/carrer_ladder.png");
export const employee_profile= require("../assets/employee_profile.png");
export const employee_notification= require("../assets/employee_notification.png");
export const search_icon =require("../assets/search_icon.png");
export const Empty =require("../assets/Empty.png");
export const MessageBox =require("../assets/messageBox.png")
export const rightAroww =require("../assets/rightArrow.png")
export const leftAroww =require("../assets/leftArrow.png")
export const InfoSquareBold =require("../assets/InfoSquareBold.png")
export const InfoSquareLight =require("../assets/InfoSquareLight.png")
export const StrokeRightInfo =require("../assets/StrokeRightInfo.png")
export const CompletedPana =require("../assets/CompletedPana.png")
export const BulkUpload =require("../assets/bulk_upload.svg")
export const singleupload=require("../assets/singleupload.png")
export const bulkupload=require("../assets/bulkupload.png")
export const user_profile_icon = require("../assets/user_profile_icon.svg");
































