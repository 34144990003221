import React from "react";
import DashboardController,{Props} from "./TemplateController";
import {Modal} from "@material-ui/core"; 
import { success_event } from "./assets";
import { createTheme } from "@material-ui/core/styles";


const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

export default class SuccesModalScreen extends DashboardController{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
      const { open, onClose, message } = this.props;
  
      return (
        <Modal
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
          open={open}
          onClose={onClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={webStyle.paperModel as React.CSSProperties}>
            <div className="center-block-header final-screen text-center">
              <h3 style={{ color: "black" }}>Congratulations!!</h3>
              <p style={{ color: "black" }}>{message}</p>
              <div className="header-logo">
                <img src={success_event} alt='logo' width={284} height={278} />
              </div>
            </div>
          </div>
        </Modal>
      );
    }
  }

  const webStyle = {
    paperModel: {
        position: "absolute",
        width: 600,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(10,20,10),
        paddingHorizontal:theme.spacing(20)
    },
  }