import React from "react";

import {
    
    Box,
    
    Button,
    
    Typography,
  
    // Customizable Area Start
    Grid
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, withStyles } from "@material-ui/core/styles";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { left_arrow } from "./assets";
import { Link } from "react-router-dom"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import SettingsController, {
    Props    
} from "./SettingsController";


export class General extends SettingsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const accordionItems = [
            {
                name: "Language",
                title: "Section 1",
                items: ["English", "Nederlands", "Deutsch"]
            },
            {
                name: "Theme",
                title: "Section 2",
                items: ["Light", "Dark"]
            },
            {
                heading: "Notification",
                name: "Notification Tone",
                title: "Section 3",
                items: ["on", "off"]
            },
            {
                name: "Lock Screen Notification",
                title: "Section 3",
                items: ["on", "off"]
            }
        ];
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box style={{ background: "#F3F4F5", height: "64px", marginTop: "32px" }} >
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid className="first" style={{
                        padding: '19px 0px 21px 38px',
                        // display: "flex",
                        width: "100%",
                        maxWidth: "80%",
                        justifyContent: "flex-start",
                    }} item>
                        <p style={{ color: "black", fontSize: "18px", fontWeight: 400, paddingLeft: "14px", display: "flex", alignItems: "center" }}>
                            <Link to="/EmployeeDashboard">
                                <ArrowBackIosIcon style={{ marginRight: "42px" }} />
                            </Link>
                            Settings <img src={left_arrow} alt="logo" width={18} height={18}/> General
                        </p>

                    </Grid>


                    {accordionItems.map((section, sectionIndex) => (
                        < >
                             {section.heading && (
                                 <Grid container alignItems="center" style={{ padding: '0 32px' }}>
                                <Grid className="first" item style={{
                                    marginTop: "24px", width: "100%",
                                    maxWidth: "627px"
                                }}>
                                    <Typography style={{ color: "black", fontSize: "18px", fontWeight: 400 }}>{section.heading}</Typography>
                                </Grid>
                                </Grid>
                            )}

                            <Grid key={sectionIndex} container alignItems="center" style={{ padding: '0 32px', display: "block" }}>
                                <Grid className="first" item style={{
                                    marginTop: "24px", width: "100%",
                                    maxWidth: "627px"
                                }}>
                                    <Typography style={{ color: "black", fontSize: "18px", fontWeight: 400 }}>{section.name}</Typography>
                                </Grid>
                            </Grid>

                            <Grid container alignItems="center" style={{ padding: '0 32px' }}>
                                <Grid className="first" item style={{
                                    marginTop: "24px", width: "100%",
                                    maxWidth: "627px"
                                }}>
                                    <Accordion style={{ background: "#F3F4F5" }}>
                                        <AccordionSummary
                                        data-testId="summary"
                                            className={this.props.classes.accordionSummary}
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            onClick={() => this.toggleSection(sectionIndex)}
                                        >
                                            <Typography

                                            >
                                                {this.state.selectedItems[sectionIndex] !== undefined
                                                    ? section.items[this.state.selectedItems[sectionIndex]]
                                                    : section.title}
                                            </Typography>
                                        </AccordionSummary>

                                        <AccordionDetails
                                            className={this.props.classes.accordionDetails}>
                                            <Typography>
                                                {this.state.openSections.includes(sectionIndex) && (
                                                    <div >
                                                        {section.items.map((item, itemIndex) => (
                                                            <div
                                                                className={this.props.classes.tabSubcontet}
                                                                key={itemIndex}
                                                                onClick={() => this.selectItem(sectionIndex, itemIndex)}
                                                           
                                                            >
                                                                {item}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>

                              
                            </Grid>
                        </>
                    ))}

                   
                   
                   <Button
                className={this.props.classes.buttonsubmit}

                    >Verify</Button>
                </Grid>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const webStyle: any = (theme: any) => ({
   
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
    noBorder: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none', // Remove the border
        },
    },
    accordionSummary: {
        borderRadius: "12px",
        background: "#F3F4F5",
    },
    accordionDetails: {
        borderRadius: "8px",
        border: "0.25px solid rgba(0, 0, 0, 0.28)",
        background: "#F7F7F7",
        padding: "20px 20px 0px 20px",
    },
    tabSubcontet: {
        marginBottom: "25px",
    },
    buttonsubmit: {
        
        "&:hover" : {
            "backgroundColor": "#f7da08",
        },
        maxWidth: "327px",
        width:"100%",
    height: "48px",
    borderRadius: "7px",
    backgroundColor:" #f7da08",
    marginTop: "128px",
    display: "flex",
    justifyItems: "center",
    marginLeft: "34px",
    marginBottom: "98px"
    }

});
export default withStyles(webStyle)(General);
export {General as GeneralWeb}
// Customizable Area End
