import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import toast from 'react-hot-toast';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from "yup";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openSections: any;
  selectedItems: any;
  emailNotification: boolean;
  open: boolean;
  modalTitle: string;
  passworderrorMessage: string;
  showPassword: any;
  passwords: any;
  errors: any;
  modalMessage: string;
  showStrictPwd: any;
  privacyPolicyContent: string;
  termsOfUseContent: string;
  oldPasswordErrorMessage: string;
  loading: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  privacyPolicyGetApiData: any
  termsOfUseGetApiData: any
  ChangePwdAPICallId: any
  changeEmailNotification: any
  getAdminProfileDetails: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      // getName(MessageEnum.NavigationPayLoadMessage),
      // getName(MessageEnum.CountryCodeMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openSections: [],
      selectedItems: { 0: 0, 1: 0, 2: 0, 3: 0 },
      emailNotification: true,
      open: false,
      modalTitle: '',
      passworderrorMessage: "",
      showPassword: {
        oldPassword: false,
        newPassword: false,
        confirmPassword: false,
      },
      passwords: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      errors: {
        oldPassword: false,
        newPassword: false,
        confirmPassword: false,
        passwordMismatch: false,
        originOldPassworderror: false,
      },
      modalMessage: '',
      showStrictPwd: "",
      privacyPolicyContent: '',
      termsOfUseContent: '',
      oldPasswordErrorMessage: '',
      loading: true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = await message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = await message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.privacyPolicyGetApiData) {
        this.handleresponse(responseJson)
      }
      else if (apiRequestCallId === this.termsOfUseGetApiData) {
        this.termsOfUse(responseJson)
      }
      else if (apiRequestCallId === this.ChangePwdAPICallId) {
        this.modalMessage(responseJson)
      }
      else if (apiRequestCallId === this.changeEmailNotification) {
        this.emailToggleCall(responseJson)
      }
      else if (apiRequestCallId === this.getAdminProfileDetails) {
        this.allAdminProfileData(responseJson)
      }
    }
    // Customizable Area End
  }
  handleresponse = (responseJson: any) => {
    if (responseJson.privacy_policies && responseJson.privacy_policies.length > 0) {
      const privacyPolicyContent = responseJson.privacy_policies[0].privacy_policy;
      this.setState({ privacyPolicyContent: privacyPolicyContent });
    }
  }
  termsOfUse = (responseJson: any) => {
    if (responseJson.terms_and_conditions && responseJson.terms_and_conditions.length > 0) {
      const termsOfUseContent = responseJson.terms_and_conditions[0].terms_of_use;
      this.setState({ termsOfUseContent: termsOfUseContent });
    }
  }
  modalMessage = (responseJson: any) => {
    if (responseJson.message) {
      this.setState({
        modalMessage: responseJson.message,
        open: true,
      })
    }
    else if (responseJson.error) {
      this.setState({
        errors: { ...this.state.errors, originOldPassworderror: true },
        oldPasswordErrorMessage: responseJson.error,
      })
    }
  }
  emailToggleCall = (responseJson: any) => {
    if (responseJson.message) {
      toast.success(responseJson.message)
      this.getAdminProfileData()
    }
  }
  allAdminProfileData = (responseJson: any) => {
    if (responseJson?.data?.attributes) {
      this.setState({ emailNotification: responseJson?.data?.attributes?.email_notifications_enabled,loading: false })
    }
  }
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };
  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };
  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;
  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  async componentDidMount() {
    this.getAdminProfileData()
  }
  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  toggleSection = (index: number) => {
    const isOpen = this.state.openSections.includes(index);
    if (isOpen) {
      this.setState({ openSections: this.state.openSections.filter((item: any) => item !== index) })
    } else {
      this.setState({ openSections: [...this.state.openSections, index] })
    }
  };

  selectItem = (sectionIndex: number, itemIndex: number) => {
    this.setState({ selectedItems: { ...this.state.selectedItems, [sectionIndex]: itemIndex } })
  };

  handleOpen = (title: string) => {
    this.setState({ open: true, modalTitle: title });
    if (title === "Privacy Policy") {
      this.privacyPolicyGetApi()
    }
    else if (title === "Terms Of Use") {
      this.termsOfUseGetApi()
    }
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  handlePasswordChange = async (field: any, value: any) => {
    this.setState((prevState) => ({
      passwords: {
        ...prevState.passwords,
        [field]: value,
      },
      errors: {
        ...prevState.errors,
        [field]: false,
        passwordMismatch: false,
      },
    }));
  };

  toggleShowPassword = (field: any) => {
    this.setState(prevState => ({
      showPassword: {
        ...prevState.showPassword,
        [field]: !prevState.showPassword[field],
      }
    }));
  };
  checkNewPassword = async (passwordaa: any) => {
    const passwordSchema = Yup.string()
      .min(8, 'Password should be a minimum of 8 characters long')
      .matches(/[a-z]/, 'Password should contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password should contain at least one uppercase letter')
      .matches(/\d/, 'Password should contain at least one digit')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password should contain at least one special character')
      .required('Password is required.');

    const validatePassword = async (password: string) => {
      try {
        await passwordSchema.validate(password, { abortEarly: false });
        return { isValid: true, errors: [] };
      } catch (error: any) {
        return { isValid: false, errors: error.errors[0] };
      }
    };
    const result = await validatePassword(passwordaa);
    if (!result.isValid) {
      this.setState({ showStrictPwd: result.errors })

    }
  }

  validateFields = () => {
    const { passwords } = this.state;
    const errors = {
      oldPassword: !passwords.oldPassword,
      newPassword: !passwords.newPassword,
      confirmPassword: !passwords.confirmPassword,
      passwordMismatch: passwords.confirmPassword.length > 0 && passwords.newPassword !== passwords.confirmPassword,
    };
    this.setState({ errors, showStrictPwd: "" });
    return !Object.values(errors).some((error) => error);
  };

  handleOpenModal = () => {
    if (this.validateFields() && this.state.showStrictPwd.length === 0) {
      this.changePasswordCallApi()
    }
    else if (this.state.passwords.newPassword.length > 0) {
      this.checkNewPassword(this.state.passwords.newPassword)
    }
  };

  handleBack = (pathChange: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pathChange);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  }
  backNavigate = (pathChange: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pathChange);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };
  navigateToPassword = (pathChange: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pathChange);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };


  handleClickAccordion = (value: any) => {
    if (value === 'Change Password') {
      this.navigateToPassword('ChangePassword');
    } else if (value === 'Privacy Policy' || value === 'Terms Of Use') {
      this.handleOpen(value);
    }
  };


  privacyPolicyGetApi = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.privacyPolicyGetApiData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.privacyPolicyEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  termsOfUseGetApi = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.termsOfUseGetApiData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsOfUseEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  changePasswordCallApi = async () => {
    const token = await getStorageData("authToken")
    const header = {
      token: token,
      "Content-Type": "application/json"
    };
    const data = {
      old_password: this.state.passwords.oldPassword,
      new_password: this.state.passwords.newPassword,
      confirm_password: this.state.passwords.confirmPassword
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ChangePwdAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  emailNotificationFunction = () => {
    this.setState({ emailNotification: !this.state.emailNotification })
    this.toggleEmailCallApi()
  }
  toggleEmailCallApi = async () => {
    const token = await getStorageData("authToken")
    const header = {
      token: token,
      "Content-Type": "application/json"
    };
    const data = {
      email_notifications_enabled: this.state.emailNotification
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changeEmailNotification = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailToggleEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAdminProfileData = async () => {
    let userToken = await getStorageData("authToken");
    const httpHeaderData = {
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAdminProfileDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAdminProfileEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(httpHeaderData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
