import React from "react";
// Customizable Area Start
import {
  Button,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Box
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Customizable Area End
import AddDkrController, { Props } from "./AddDkrController";
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  }
});
export default class OccurrenceView extends AddDkrController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.OccurViewmaincontainer}>
          <Grid container justifyContent="space-between" 
          alignItems="center">
            <Grid className="first" 
            style={webStyle.OccurViewleftSide} item>
              <Grid container 
              spacing={2} alignItems="center">
                <Grid item 
                style={webStyle.OccurViewrowarrowDisplay}>
                  <ArrowBackIosIcon 
                  style={{ fontSize: "24px", color: "black" }} 
                  data-test-id="nextButton1" onClick={this.navigateToEditTemplate2} />
                  <span
                    style={{...webStyle.OccurViewHeadStyleTop1,
                    fontWeight: 400}}>
                    Edit Template
                  </span>
                </Grid>
                <Grid item style={webStyle.OccurViewrowarrowDisplay}>
                  <ArrowBackIosIcon style={{...webStyle.OccurViewHeadStyleTop1,
                    color:"#B2B2B2",fontSize:"16px"}} />
                  <span style={{...webStyle.OccurViewabelStyleoptionhead as React.CSSProperties,
                    fontWeight:700,fontSize:"18px"}}>
                    View Kpi Details
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="second" 
            
            style={webStyle.OccurViewnullContainer} item />
          </Grid>
          <Grid
            container
            alignItems="center"
            style={webStyle.OccurViewinformContainer as React.CSSProperties}>
            <Grid
              className="first"
              item
              style={webStyle.OccurViewinformSecondContainerHead}
              xs={12}>
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" 
                  style={webStyle.OccurViewheaderMain10}>
                    <span style={webStyle.OccurViewHeadtopStyle22 as React.CSSProperties}>KPI Details</span>
                  </FormLabel>

                </FormControl>
              </div>
              <div className="form-field text-center" id="signupBlock4">
                <div className="control" id="signupfieldcontrol2">
                  <Button style={webStyle.Editbutton as React.CSSProperties} 
                  data-test-id="EditButton" onClick={this.navigateToEditKpi}>
                    Edit KPI Details
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.OccurViewinformationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.OccurViewheaderMain10}>
                  <span style={{ ...webStyle.OccurViewabelStyleoptionhead as React.CSSProperties, fontSize: "22px" ,fontWeight: 500}}>Type</span>
                </FormLabel>
                <span style={{...webStyle.OccurViewblurvalueStyle as React.CSSProperties,fontWeight:400}}>Occurrence</span>
              </FormControl>
            </Grid>
            <Grid
              className="second"
              item
              style={webStyle.OccurViewinformationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" 
                style={{ ...webStyle.OccurViewheaderMain10, marginTop: "30px" }}>
                  <span style={{...webStyle.OccurViewabelStyleoptionhead as React.CSSProperties,
                    fontWeight:500,fontSize:"22px",}}>Basic Details</span>
                </FormLabel>
                {Array.isArray(this.state.showTemplateData) && (
          this.state.showTemplateData.map((template:any, index:any) => (
                <Grid container style={{ display: "flex" }} xs={12}>
                  <Grid item xs={4}>
                    <div className="form-block" id="signupBlock3">
                      <form>
                        <div
                          className="form-field"
                          id="signupform"
                          style={webStyle.OccurViewtopdescThirty}>
                          <span style={webStyle.OccurViewTopstyleHeader as React.CSSProperties}>Title</span>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.OccurViewmarginLeft0}>
                            <span style={webStyle.OccurViewblurvalueStyle as React.CSSProperties}>{template.attributes.name}</span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Grid>
                  <>
                    <Grid item xs={6}>
                      <div className="form-block" id="signupBlock3">
                        <form>
                          <div
                            className="form-field"
                            id="signupform"
                            style={webStyle.OccurViewtopdescThirty}
                          >
                            <span style={webStyle.OccurViewTopstyleHeader as React.CSSProperties}>Description</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.OccurViewmarginLeft0}
                            >
                              <span style={{...webStyle.OccurViewblurvalueStyle as React.CSSProperties,fontWeight:400}}>{template.attributes.description}</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className="form-block" id="signupBlock3">
                        <form>
                          <div
                            className="form-field"
                            id="signupform"
                            style={{...webStyle.OccurViewtopdescThirty,width:"max-content"}}
                          >
                            <span style={webStyle.OccurViewTopstyleHeader as React.CSSProperties}>Points (Weightage)</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.OccurViewmarginLeft0}
                            >
                              <span style={{...webStyle.OccurViewblurvalueStyle as React.CSSProperties,fontWeight:400}}>{template.attributes.weightage} points</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                  </>
                </Grid>
          )))}
              </FormControl>
            </Grid >

        

          </Grid >
          <Divider light style={{ marginTop: "20px",
             marginBottom: "20px" }} />
          <Grid
            container
            alignItems="center"
            style={webStyle.OccurViewinformContainer as React.CSSProperties}
          >
            <Grid
              className="first"
              item
              style={webStyle.OccurViewinformSecondContainerHead}
              xs={12}
            >
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.OccurViewheaderMain10}>
                    <span style={webStyle.OccurViewHeadtopStyle22 as React.CSSProperties}>Daily KPI Report</span>
                  </FormLabel>

                </FormControl>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.OccurViewinformationSubContainerTable}
              xs={12}  >
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.OccurViewheadermandatory}>
                    <span style={{...webStyle.OccurViewlabelStyle100 as React.CSSProperties,
                      fontWeight:500,marginTop:"20px"}}>Applicable DKRs</span>
                  </FormLabel>
                </FormControl>
                <TableContainer style={webStyle.OccurViewtableOccurViewmaincontainer}>
                  <Table aria-label="customized table">
                  <TableHead>
                      <TableRow style={webStyle.OccurViewmaintableHead}>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"50px",fontWeight:600}}>Sr. No.</TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"15px",fontWeight:600}}>DKR Type</TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",fontWeight:600}}>DKR Brief Title</TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",fontWeight:600}}>
                        </TableCell>
                        <TableCell>  </TableCell>
                      </TableRow>
                    </TableHead>
                    {Array.isArray(this.state.showTemplateData) &&
  this.state.showTemplateData.map((template: any, index: any) => (
                    <TableBody key={index}>
                       {template.attributes.dkr_information.data.attributes.applicable_dkrs.data.map(
        (occurrence: any, idx: any) => (
                        <TableRow 
                        >
                          <TableCell component="th" scope="row" 
                          style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"89px"}}>
                           {idx+1}.
                          </TableCell>
                          <TableCell component="th" scope="row" 
                          style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"15px"}}>
                             {occurrence.attributes.dkr_type}
                          </TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={{marginLeft: 0, marginTop: 10}}
                                  >              
                                    <span style={{...webStyle.OccurViewblurvalueStyle as React.CSSProperties,fontSize:"14px"}}> {occurrence.attributes.title}</span>
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >  {occurrence.attributes.response_type} 
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell style={{width:"24%"}}>
                          </TableCell>
                        </TableRow>
                        
                        
        ))}
                    </TableBody>
  ))}
                  </Table>
                </TableContainer>
              </>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.OccurViewinformationSubContainerTable}
              xs={12}  >
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.OccurViewheadermandatory}>
                    <span style={{...webStyle.OccurViewlabelStyle100 as React.CSSProperties,fontWeight:500,marginTop:"20px"}}>Applicable Occurrence</span>
                  </FormLabel>
                </FormControl>
                <TableContainer style={webStyle.OccurViewtableOccurViewmaincontainer}>
                  <Table aria-label="customized table">
                  <TableHead>
                      <TableRow style={webStyle.OccurViewmaintableHead}>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",fontWeight:600,paddingLeft:"62px"}}>Occurrence Title</TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",fontWeight:600}}>
                        Deductible Value
                        </TableCell>
                        <TableCell style={{width:"30%"}}> 
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    
                  </Table>
                </TableContainer>
              </>
            </Grid>
          </Grid >

          <Grid style={webStyle.OccurViewbuttonOccurmaincontainer}>
            <Box style={{ marginTop: '50px', marginBottom: '40px' }}>
              <Button style={webStyle.OccurViewSubmitBtn as React.CSSProperties}>
                Submit
              </Button>
              <Button variant="outlined" style={webStyle.OccurViewCancelBtn as React.CSSProperties} >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Box >
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  
    OccurViewmaintableHead: {
        backgroundColor: "#F5F5F5",
      },
    OccurViewlabelStyle100: {
        fontSize: "22px",
        color: "black",
        fontFamily:"Open Sans",
        fontWeight: 500,
      },
    OccurViewheadermandatory: {
        alignItems: "baseline",
        display: "flex",
        MarginTop:"30px",
        gap: "4px",
      },
      OccurViewtableOccurViewmaincontainer: { 
        marginTop: "15px" ,
        border: "1px solid #979797",
         borderRadius: "10px", 
        },
    OccurViewinformationSubContainerTable: {
        width: "90%" ,
        marginTop: "24px",
        },
    OccurViewinputmainFieldTable: {
        background: 'transparent',
        width: "70%",
        border: '0.5px solid #979797'
      },
 
      OccurViewRemovebutton:{
        fontFamily: 'Open Sans',
        border:"1px solid #ff5c5c", 
        backgroundColor: "White", 
        color: "black", 
        width: "145px", 
        fontSize: "16px",
        fontWeight: 600, 
        textTransform: "none",
        paddingTop: "12px",
        paddingBottom:"12px",
        borderRadius:"10px"
      },
    
      OccurViewmySelect:{
        border:"none",
        background:"none",
        padding:"15px",
         width:"135",
      },
      OccurViewmyBox:{
        border:"1px solid #979797",
        width: "145px",
        bottom:"6px",
        borderRadius: "12px",
        marginTop: 10, 
        marginLeft: 0,
      },
      OccurViewinputtext:{
        border: '0.5px solid #979797',
        background: 'transparent', 
     width:"100%"
      },
  Editbutton:{
    fontFamily: 'Open Sans', 
    backgroundColor: "FDD100", 
    color: "black", 
    padding: "15px 20px 15px 20px", 
    borderRadius: "10px", 
    width: "200px", 
    fontSize: "16px", 
    fontWeight: 600, 
    textTransform: "none"
  },
  
  OccurViewCancelBtn: {
    fontWeight: 600,
    fontFamily: 'Open Sans',
    width: "170px",
    fontSize: "16px",
    textTransform: "none",
    backgroundColor: "white",
    color: "black",
  },
  OccurViewSubmitBtn: {
    fontFamily: 'Open Sans',
    backgroundColor: "FDD100",
    marginRight: "20px",
    color: "black",
    fontSize: "16px",
    width: "170px",
    fontWeight: 600,
    textTransform: "none",
    
  },
  TCell: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    color: "black",
    fontWeight: 600
  },
  TableC: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    color: "black",
    fontWeight: 600
  },

  OccurViewmaincontainer: {
    height: "64px",
    background: "#F3F4F5",
    marginRight: "-20px"
  },
  OccurViewinformContainer: {
    padding: "0 32px",
    alignItems: "baseline",
    flexDirection: "column"
  },
  OccurViewinformationSubject: {
    width: "800px",
    marginTop: "24px",
    marginBottom : "30px"
  },
  OccurViewleftSide: {
    padding: "19px 0px 21px 38px"
  },
  OccurViewnullContainer: {
    padding: "0px 44px 0px 0px"
  },

  OccurViewinformSecondContainerHead: {
    marginTop: "24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "initial",
    width: "90%"
  },
  OccurViewtopdescThirty: {
    marginTop: "20px",
  },
  container: {
    height: "100%",
    width: "100%",
  },
 
  OccurViewabelStyleoptionhead: {
    color: "black",
    fontWeight: 500,
    fontSize: "22px",
    fontFamily: "Open Sans"
  },
  OccurViewTopstyleHeader: {
    fontSize: "18px",
    color: "#c8ccc9",
    fontWeight: 700,
    fontFamily: "Open Sans"
  },
  OccurViewblurvalueStyle: {
    color: "black",
    marginTop: "10px",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: 400
  },
  OccurViewrowarrowDisplay: {
    alignItems: "center",
    display: "flex",
  },
  OccurViewHeadStyleTop1: {
    color: "black",
    fontFamily: "Open Sans",
    fontSize: "18px",
  },
  OccurViewHeadtopStyle22: {
    color: "black",
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Open Sans"
  },
  inputField: {
    width: "60%",
    border: '0.5px solid #979797',
    background: 'transparent',
  },
  inputFieldTabless: {
    width: "70%",
    border: '0.5px solid #979797',
    background: 'transparent',
  },
  OccurViewmarginLeft0: {
    marginLeft: 0,
    marginTop: 10
  },
 
  OccurViewbuttonOccurmaincontainer: { display: "flex", gap: "20px", margin: "0 40px" },

  OccurViewheaderMain10: {
    display: "flex",
    alignItems: "baseline",
    gap: "4px",
    MarginTop: "30px"
  },

};
// Customizable Area End
