Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.formDataContentType="text/csv"
exports.dashboarApiMethodType = "GET";
exports.activateAccountApiMethodType="PUT";
exports.postMethodType="POST";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.viewAllEndPoint = "account_block/accounts";
exports.activateAccountEndPoint="account_block/activated_account";
exports.showTemplatesEndPoint="bx_block_gamification/kpis"
exports.updateTemplateEndPoint="bx_block_gamification/templates"
exports.searchemplateEndPoint="bx_block_gamification/templates/search_template"
exports.uploadBulkUserEndpoint="account_block/upload_user_data"
exports.totalTemplateEndpoint="bx_block_gamification/templates/total_template"
exports.rolesEndPoint="bx_block_gamification/role_in_department"
exports.activeUsersEndPoint="account_block/show_active_user" 
exports.createEventEndPoint='bx_block_gamification/events'
exports.filterTemplateEndPoint="bx_block_gamification/templates/filter_template"
exports.copyTemplateEndPoint="bx_block_gamification/templates/copy_template"
exports.disableKpiEndPoint="bx_block_gamification/templates/disable_kpi"
exports.countryEndpoint='account_block/accounts/countries'
exports.stateEndpoint='account_block/accounts/states'
exports.cityEndpoint='account_block/accounts/cities'
exports.timeZoneEndpoint='bx_block_dashboard/dashboards/get_time_details'
exports.departmentEndpoint='bx_block_customisableuserprofiles/admin_profile/department_list'
exports.createEmployEndpoint='bx_block_customisableuserprofiles/admin_profile/create_employee'
exports.departmentlistEndPoint="bx_block_gamification/department_list"
exports.designationListEndpoint='bx_block_gamification/designations/designation_list?department_id=22'
exports.employeeDetailsEndpoint='account_block/designation_wise_employees?designation_id='

exports.executiveProduct="Executive-Product";
exports.goodPoints="Good Points";
exports.negativePoints="Negative Points";
exports.badgesEarned="Badges Earned";
exports.employeeID="Employee ID";
exports.designation="Designation";
exports.location="Location";
exports.mobileNumber="Mobile Number";
exports.emailID="Email Id";
exports.country="Country";
exports.state="State";
exports.timeZone="Time Zone";
exports.timings="Timings";
exports.general="General";
exports.afternoonShift="Afternoon Shift";
exports.nightShift="Night Shift";
exports.basicInfo="Basic Info";
exports.firstname="First Name";
exports.lastname="Last Name";
exports.preferredPronoun="Preferred Pronounce";
exports.hometown="Hometown";
exports.address="Address";
exports.bloodgroup="Blood Group";
exports.ap="A+";
exports.an="A-";
exports.bp="B+";
exports.bn="B-";
exports.abp="AB+";
exports.abn="AB-";
exports.op="O+";
exports.on="O-";
exports.dateOfBirth="Date Of Birth";
exports.highestEducation="Highest Education";
exports.briefAboutEmployee="Brief About Employee";
exports.askEmployee="Ask Employee About/Expertise";
exports.work="Work";
exports.department="Department";
exports.reportingTo="Reporting To";
exports.seatingLocation="Seating Location";
exports.employeeStatus="Employee Status";
exports.dateOfJoining="Date Of Joining";
exports.workPhone="Work Phone";
exports.companyName="Company Name";
exports.employeeType="Employee Type";
exports.role="Role";
exports.save="Save";
exports.cancel="Cancel";
exports.getAdminProfileEndPoint="bx_block_customisableuserprofiles/admin_profile/admin_profile_detail";
exports.getAdminProfileAPiMethod="GET";
exports.template="Test Template";
exports.testingDesc="This is testing desc";
exports.personalPhoneNumber="Personal Phone number"
// Customizable Area End