import React from "react";

// Customizable Area Start
import { footerImage, forgetpw } from "./assets";
import ForgotPasswordController, { Props } from "./ForgetPasswordController.web";
// Customizable Area End

export default class ForgetPassword extends ForgotPasswordController {
   constructor(props: Props) {
      super(props);
      //Customizable Area Start
      //Customizable Area End
   }
   render() {
      return (
         //Customizable Area Start
         <div style={webStyleforget.parentDiv} id="forgetpasswordblock">
            <div id="forgetpasswordblocksubdiv" className="frogot-pwd-wrapper" style={webStyleforget.subDiv} >
               <div className="center-block-header text-center" style={webStyleforget.headerBlock}>
                  <div className="header-logo">
                     <img src={forgetpw} alt='logo' style={{ width: "110px" }} />
                  </div>
                  <div>
                     <h3 style={{marginBottom:'20px'}}>Forgot Password</h3>
                     <span >Enter your Email Id to get 6 digit code</span>
                  </div>
               </div>
               <div className="form-block">
                  <form onSubmit={this.handleSubmit}>
                     <div className="form-field">
                        <span className="label">E-mail ID</span>
                        <div className="control">
                           <input data-testid="inputField" type="text" onChange={this.handleInputChange}
                              className={`control-input ${this.state.invalidPasswordMessage ? 'error' : ''}`}
                              placeholder="Enter your Email ID" name="email" id="email-control"
                           />
                           <span style={webStyleforget.spanText}>{this.state.invalidPasswordMessage}</span>
                        </div>
                     </div>
                     <div className="form-field text-center otp-button">
                        <div className="control">
                           <input type="submit"
                              data-testid="sendOtp"
                              className="button submit"
                              disabled={this.state.isButtonDisabled}
                              value="Send Link" />
                        </div>
                     </div>
                  </form>
               </div>
            </div>
            <img src={footerImage} alt='logo' style={{ width: "100%", height: "7rem", position: "absolute", left: 0, right: 0, bottom:0 }} />
         </div>
         //Customizable Area End
      )
   }

}
const webStyleforget = {
   parentDiv: {
      position: "relative",
      height: "100vh",
   },
   subDiv: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: 'center',
      height: '100%',
      gap: '30px',
   },
   headerBlock: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: 'center',
      gap: '20px',
   },
   spanText:{
      display:"grid",
      color: "red"
   }
} as any;