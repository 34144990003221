export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const forgetpw = require("../assets/forgetpw.png");
export const newpw = require("../assets/newpw.png");
export const success = require("../assets/success.png");
export const footerImage = require("../assets/Vector.png");
export const modalImage = require("../assets/ModalIcon.png");
export const visibility_eye = require("../assets/visibility_eye.svg");
export const visibility_eye_off = require("../assets/visibility_eye_off.svg");
export const visibility_eye_off_dark = require("../assets/visibility_eye_off_dark.svg");
export const visibility_eye_dark = require("../assets/visibility_eye_dark.svg");
