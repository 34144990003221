import React from "react";
// Customizable Area Start
import {
  Button,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Box
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Customizable Area End
import AddDkrController, { Props } from "./AddDkrController";
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  }
});
export default class ProductView extends AddDkrController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.Productviewmaincontainer}>
          <Grid 
          container 
          justifyContent="space-between" 
          alignItems="center"
          >
            <Grid className="first" style={webStyle.ProductviewleftSide} item>
              <Grid 
              spacing={2} 
              alignItems="center"
              container 
              >
                <Grid 
                style={webStyle.ProductviewrowarrowDisplay}
                item 
                >
                  <ArrowBackIosIcon 
                  data-test-id="nextButton1" 
                  style={{ fontSize: "24px", color: "black" }} 
                  onClick={this.navigateToEditTemplate2}
                   />
                  <span 
                  style={{...webStyle.ProductviewHeadStyleTop1,fontWeight: 400}}
                  >
                    Edit Template
                  </span>
                </Grid>
                <Grid 
                style={webStyle.ProductviewrowarrowDisplay}
                item 
                >
                  <ArrowBackIosIcon 
                  style={{...webStyle.ProductviewHeadStyleTop1,color:"#B2B2B2",fontSize:"16px"}} 
                  />
                  <span 
                  style={{...webStyle.ProductviewlabelStyleoptionhead as React.CSSProperties,fontWeight:700,fontSize:"18px"}}
                  >
                   View Kpi Details
                  </span>
              </Grid>
                </Grid>
            </Grid>
            <Grid 
            className="second" 
            item 
            style={webStyle.ProductviewnullContainer} 
            />
          </Grid>
          {Array.isArray(this.state.showTemplateData) && (
          this.state.showTemplateData.map((template:any, index:any) => (
          <Grid
            container
            alignItems="center"
            style={webStyle.ProductviewinformContainer as React.CSSProperties}>
            <Grid
              item
              style={webStyle.ProductviewinformSecondContainerHead}
              className="first"
              xs={12}
              >
              <div>
                <FormControl component="fieldset">
                  <FormLabel 
                  style={webStyle.ProductviewheaderMain10}
                  component="legend" 
                  >
                    <span 
                    style={webStyle.ProductviewHeadtopStyle22 as React.CSSProperties}
                    >KPI Details</span>
                  </FormLabel>
                </FormControl>
              </div>
              <div 
              id="signupBlock4"
              className="form-field text-center" 
              >
                <div 
                id="signupfieldcontrol2"
                className="control" 
                >
                  <Button 
                  style={webStyle.ProductviewEditbutton as React.CSSProperties} 
                  data-test-id="EditButton" 
                  onClick={this.navigateToEditKpi}>
                    Edit KPI Details
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              style={webStyle.ProductviewinformationSubject}
              className="first"
              item
              xs={12} >
              <FormControl component="fieldset">
                <FormLabel 
                style={webStyle.ProductviewheaderMain10} 
                component="legend" 
                >
                  <span style={{ ...webStyle.ProductviewlabelStyleoptionhead as React.CSSProperties, fontSize: "22px" ,fontWeight: 500}}>Type</span>
                </FormLabel>
                <span 
                style={{...webStyle.ProductviewblurvalueStyle as React.CSSProperties,fontWeight:400}}
                >Productivity</span>
              </FormControl>
            </Grid>
            <Grid
              item
              style={webStyle.ProductviewinformationSubject}
              className="second"
              xs={12}
              >
              <FormControl component="fieldset">
                <FormLabel
                style={{ ...webStyle.ProductviewheaderMain10, }}
                component="legend" 
                >
                  <span style={{...webStyle.ProductviewlabelStyleoptionhead as React.CSSProperties,fontWeight:500,fontSize:"22px"}}>Basic Details</span>
                </FormLabel>
                <Grid container style={{ display: "flex" }} xs={12} spacing={6}>
                  <Grid item xs={3}>
                    <div className="form-block" id="signupBlock3">
                      <form>
                        <div
                          id="signupform"
                          className="form-field"
                          style={webStyle.ProductviewtopdescThirty}>
                          <span 
                          style={webStyle.ProductviewTopstyleHeader as React.CSSProperties}>Title</span>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.ProductviewmarginLeft0}>
                            <span style={webStyle.ProductviewblurvalueStyle as React.CSSProperties}>{template.attributes.name}</span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Grid>
                  <>
                    <Grid item xs={7}>
                      <div 
                      className="form-block" 
                      id="signupBlock3"
                      >
                        <form>
                          <div
                            className="form-field"
                            style={webStyle.ProductviewtopdescThirty}
                            id="signupform"
                          >
                            <span style={webStyle.ProductviewTopstyleHeader as React.CSSProperties}>Description</span>
                            <div
                              id="signupfieldcontrol"
                              style={webStyle.ProductviewmarginLeft0}
                              className="control"
                            >
                              <span 
                              style={{...webStyle.ProductviewblurvalueStyle as React.CSSProperties,fontWeight:400}}>
                                {template.attributes.description}</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <div 
                      className="form-block" 
                      id="signupBlock3"
                      >
                        <form>
                          <div
                            style={{...webStyle.ProductviewtopdescThirty,width:"max-content"}}
                            className="form-field"
                            id="signupform"
                          >
                            <span style={webStyle.ProductviewTopstyleHeader as React.CSSProperties}>Points (Weightage)</span>
                            <div
                              style={webStyle.ProductviewmarginLeft0}
                              className="control"
                              id="signupfieldcontrol"
                            >
                              <span style={{...webStyle.ProductviewblurvalueStyle as React.CSSProperties,fontWeight:400}}>{template.attributes.weightage} points</span>
                          </div>
                            </div>
                        </form>
                      </div>
                    </Grid>
                  </>
                </Grid>
              </FormControl>
            </Grid >

            <Grid
              item
              className="third"
              xs={12}
              style={webStyle.ProductviewinformationSubject}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.ProductviewheaderMain10}>
                  <span style={webStyle.ProductviewlabelStyleoptionhead as React.CSSProperties}>Calculation Type</span>
                </FormLabel>
                <div className="form-block" id="signupBlock3">
                  <form>
                    <div
                      style={webStyle.ProductviewtopdescThirty}
                      id="signupform"
                      className="form-field"
                    >
                      <span style={webStyle.ProductviewTopstyleHeader as React.CSSProperties}>Automatic</span>
                      <div
                        id="signupfieldcontrol"
                        className="control"
                        style={webStyle.ProductviewmarginLeft0}
                      >
                        <span 
                        style={webStyle.ProductviewblurvalueStyle as React.CSSProperties}
                        >{template.attributes.automatic_calculation ? "Yes" : "No"}</span>
                    </div>
                      </div>
                  </form>
                </div>
              </FormControl>
            </Grid>
            <Grid
              style={webStyle.ProductviewinformationSubject}
              className="first"
              xs={12}
              item
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.ProductviewheaderMain10}>
                  <span 
                  style={webStyle.ProductviewlabelStyleoptionhead as React.CSSProperties}
                  >Daily Goal Details</span>
                </FormLabel>
              </FormControl>
              <Grid 
              style={{ display: "flex" }} 
              container 
              xs={12}
              >
                <Grid item xs={2}>
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        style={{...webStyle.ProductviewtopdescThirty,width:"max-content"}}
                        className="form-field"
                        id="signupform"
                      >
                        <span style={{...webStyle.ProductviewTopstyleHeader as React.CSSProperties,}}>Goal Value (In Numbers)</span>
                        <div
                          style={webStyle.ProductviewmarginLeft0}
                          className="control"
                          id="signupfieldcontrol"
                        >
                    <span style={{...webStyle.ProductviewblurvalueStyle as React.CSSProperties,fontWeight:400}}>{template.attributes.goal_value}</span>

                      </div>
                        </div>
                    </form>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.ProductviewinformationSubject}
              xs={12}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.ProductviewheaderMain10}>
                  <span style={webStyle.ProductviewlabelStyleoptionhead as React.CSSProperties}>Analytics and Run rate</span>
                </FormLabel>
              </FormControl>
              <Grid container style={{ display: "flex", alignItems: "last baseline" }}>
                <Grid item xs={3}>
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        id="signupform"
                        className="form-field"
                        style={webStyle.ProductviewtopdescThirty}
                      >
                        <span style={webStyle.ProductviewTopstyleHeader as React.CSSProperties}>Run rate</span>
                        <div
                          className="control"
                          style={webStyle.ProductviewmarginLeft0}
                          id="signupfieldcontrol"
                        >
                          <span 
                          style={webStyle.ProductviewblurvalueStyle as React.CSSProperties}
                          >{template.attributes.run_rate ? "Yes" : "No"}</span>
                      </div>
                        </div>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className="form-block" id="signupBlock3">
                    <form>
                      <div
                        className="form-field"
                        style={webStyle.ProductviewtopdescThirty}
                        id="signupform"
                      >
                        <span 
                        style={webStyle.ProductviewTopstyleHeader as React.CSSProperties}>Part of Analytics</span>
                        <div
                          style={webStyle.ProductviewmarginLeft0}
                          className="control"
                          id="signupfieldcontrol"
                        >
                          <span 
                          style={webStyle.ProductviewblurvalueStyle as React.CSSProperties}>{template.attributes.part_of_analytics ? "Yes" : "No"}</span>
                        </div>
                      </div>
                    </form>
                  </div>
                </Grid>
            </Grid>
              </Grid>

          </Grid >
          )))}
          <Divider light 
          style={{ marginTop: "20px" , marginBottom:"20px"}} />
          <Grid
            alignItems="center"
            container
            style={webStyle.ProductviewinformContainer as React.CSSProperties}
          >
            <Grid
              item
              className="first"
              style={webStyle.ProductviewinformSecondContainerHead}
              xs={12}
            >
              <div>
                <FormControl component="fieldset">
                  <FormLabel 
                  component="legend" style={webStyle.ProductviewheaderMain10}>
                    <span 
                    style={webStyle.ProductviewHeadtopStyle22 as React.CSSProperties}
                    >Daily KPI Report</span>
                  </FormLabel>

                </FormControl>
              </div>
              <div className="form-field text-center" id="signupBlock4">
                <div className="control" id="signupfieldcontrol2">
                <Button style={webStyle.ProductviewEditbutton as React.CSSProperties} data-test-id="nextButton">
                Edit DKR Details
            </Button>
                </div>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              xs={12}
              style={webStyle.ProductviewinformationSubject}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.ProductviewheaderMain10}>
                  <span 
                  style={webStyle.ProductviewlabelStyleoptionhead as React.CSSProperties}>Calculation Type</span>
                </FormLabel>
                <span style={{...webStyle.ProductviewblurvalueStyle as React.CSSProperties,marginTop:'20px'}}>Pro rata</span>
              </FormControl>
            </Grid>
            <Grid
              item
              className="first"
              style={webStyle.ProductviewinformationSubject}
              xs={12}
            >
              <FormControl component="fieldset">
                <FormLabel component="legend" 
                style={webStyle.ProductviewheaderMain10}>
                  <span style={{...webStyle.ProductviewlabelStyleoptionhead as React.CSSProperties,marginTop:"30px"}}>Define Calculation Type</span>
                </FormLabel>
                <div
                        id="signupform"
                        className="form-field"
                        style={{...webStyle.ProductviewtopdescThirty,marginTop:'20px'}}
                      >
                        <span style={{...webStyle.ProductviewoptionStyleTitle as React.CSSProperties,color: "#c8ccc9"}}>Minimum Qualified Result (in %)</span>
                        <div
                          style={webStyle.ProductviewmarginLeft0}
                          className="control"
                          id="signupfieldcontrol"
                        >
                      <span style={webStyle.ProductviewblurvalueStyle as React.CSSProperties}>75%</span>    
                      </div>
                        </div>
              </FormControl >
            </Grid >
            <Grid
              className="first"
              item
              style={webStyle.ProductviewinformationSubContainerTable}
              xs={12}  >
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.Productviewheadermandatory}>
                    <span 
                    style={{...webStyle.ProductviewlabelStyle100 as React.CSSProperties,fontWeight:500,marginTop:"20px",marginBottom:"10px"}}
                    >Applicable DKRs</span>
                  </FormLabel>
                </FormControl>
                <TableContainer 
                style={webStyle.ProductviewtablemainContainer}>
                  <Table aria-label="customized table">
                  <TableHead>
                      <TableRow style={webStyle.ProductviewmaintableHead}>
                        <TableCell 
                        style={{
                          fontFamily:"Open Sans",
                          fontSize:"14px",
                          color:"black",
                          paddingLeft:"35px"
                          }}><span style={{fontWeight:600}}>Sr.</span><span style={{fontWeight:600}}>No.</span></TableCell>
                        <TableCell style={{fontFamily:"Open Sans",
                          fontSize:"14px",color:"black",fontWeight:600
                          }}>DKR Brief Title (Tasks)</TableCell>
                        <TableCell style={{fontWeight:600,fontFamily:"Open Sans",fontSize:"14px",color:"black",}}>
                          DKR SAM (in minutes)
                        </TableCell>
                        <TableCell>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {Array.isArray(this.state.showTemplateData) &&
  this.state.showTemplateData.map((template: any, index: any) => (
                    <TableBody>
                       {template.attributes.dkr_information.data.attributes.applicable_dkrs.data.map(
        (occurrence: any, idx: any) => (
              <TableRow >
                 <TableCell component="th" scope="row" style={{
                  fontFamily:"Open Sans",
                  fontSize:"14px",
                  color:"black",
                  paddingLeft:"65px"}}>{idx + 1}</TableCell>
                <TableCell>
                   <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  id="signupform"
                                  className="form-field"
                                >
                                  <Box
                                    id="signupfieldcontrol"
                                    className="control"
                                    style={webStyle.ProductviewmarginLeft0}
                                  >
                                 <span style={webStyle.ProductviewblurvalueStyle as React.CSSProperties}>{occurrence.attribute.name}</span>    

                                  </Box>
                                </Box>
                              </form>
                            </Box>
                </TableCell>
                <TableCell>
                   <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  id="signupform"
                                  className="form-field"
                                >
                                  <Box
                                    className="control"
                                    style={webStyle.ProductviewmarginLeft0}
                                    id="signupfieldcontrol"
                                  >
                                <span style={webStyle.ProductviewblurvalueStyle as React.CSSProperties}>{occurrence.attributes.title}</span>    

                                  </Box>
                                </Box>
                              </form>
                            </Box>
                </TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
        ))}
        
                    </TableBody>
  ))}
                  </Table>
                </TableContainer>
              
              </>
            </Grid>
          </Grid >

          <Grid style={webStyle.ProductviewbuttonmainContainer}>
            <Box style={{ marginTop: '50px', 
              marginBottom: '40px' }}>
              <Button style={webStyle.ProductviewSubmitBtn as React.CSSProperties}>
                Submit
              </Button>
              <Button variant="outlined" 
              style={webStyle.ProductviewCancelBtn as React.CSSProperties} >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Box >
      </>   
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
    ProductviewmaintableHead: {
        backgroundColor: "#F5F5F5",
      },
    ProductviewlabelStyle100: {
        fontSize: "22px",
        color: "black",
        fontFamily:"Open Sans",
        fontWeight: 500,
      },
    Productviewheadermandatory: {
        alignItems: "baseline",
        display: "flex",
        MarginTop:"30px",
        gap: "4px",
      },
      ProductviewtablemainContainer: { 
        marginTop: "15px" ,
        border: "1px solid #979797",
         borderRadius: "10px", 
        },
    ProductviewinformationSubContainerTable: {
        width: "98%" ,
        marginTop: "24px",
        },
    ProductviewTcell:{
        fontFamily:"Open Sans",
        fontSize:"14px",
        color:"black"
      },
       ProductviewEditbutton:{
    fontFamily: 'Open Sans', 
    backgroundColor: "FDD100", 
    color: "black", 
    padding: "15px 20px 15px 20px", 
    borderRadius: "10px", 
    width: "200px", 
    fontSize: "16px", 
    fontWeight: 600, 
    textTransform: "none"
  },
  ProductviewCancelBtn: {
    fontWeight: 600,
    fontFamily: 'Open Sans',
    width: "170px",
    fontSize: "16px",
    textTransform: "none",
    backgroundColor: "white",
    color: "black",
  },
  ProductviewSubmitBtn: {
    fontFamily: 'Open Sans',
    backgroundColor: "FDD100",
    marginRight: "20px",
    color: "black",
    fontSize: "16px",
    width: "170px",
    fontWeight: 600,
    textTransform: "none",
  },
  ProductviewTCell: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    color: "black",
    fontWeight: 600
  },
  TableC: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    color: "black",
    fontWeight: 600
  },

  Productviewmaincontainer: {
    height: "64px",
    background: "#F3F4F5",
    marginRight: "-20px"
  },
  ProductviewinformContainer: {
    padding: "0 32px",
    alignItems: "baseline",
    flexDirection: "column"
  },
  ProductviewinformationSubject: {
    width: "800px",
    marginTop: "24px",
    marginBottom: "30px"
  },
  ProductviewleftSide: {
    padding: "19px 0px 21px 38px"
  },
  ProductviewnullContainer: {
    padding: "0px 44px 0px 0px"
  },

  ProductviewinformSecondContainerHead: {
    marginTop: "24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "initial",
    width: "98%"
  },
  ProductviewtopdescThirty: {
    marginTop: "15px",
  },
  container: {
    height: "100%",
    width: "100%",
  },
  ProductviewoptionStyleTitle: {
    marginTop: '0px',
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "Open Sans"
  },
  ProductviewlabelStyleoptionhead: {
    color: "black",
    fontWeight: 500,
    fontSize: "22px",
    fontFamily: "Open Sans"
  },
  ProductviewTopstyleHeader: {
    fontSize: "18px",
    color: "#c8ccc9",
    fontWeight: 700,
    fontFamily: "Open Sans"
  },
  ProductviewblurvalueStyle: {
    color: "black",
    marginTop: "10px",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: 400
  },
  ProductviewrowarrowDisplay: {
    alignItems: "center",
    display: "flex",
  },
  ProductviewHeadStyleTop1: {
    color: "black",
    fontFamily: "Open Sans",
    fontSize: "18px",
  },
  ProductviewHeadtopStyle22: {
    color: "black",
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Open Sans"
  },
  ProductviewmarginLeft0: {
    marginLeft: 0,
    marginTop: 10
  },
  ProductviewbuttonmainContainer: { display: "flex", gap: "20px", margin: "0 40px" },
 
    ProductviewheaderMain10: {
    display: "flex",
    alignItems: "baseline",
    gap: "4px",
    MarginTop: "30px"
  },
};
// Customizable Area End
