Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "RolesPermissions";
exports.labelBodyText = "RolesPermissions Body";
exports.employeesListEndPoint="account_block/organization_employee_details";
exports.permissionsChangeEndPoint="bx_block_customisableuserprofiles/admin_profile/set_employee_permissions";
exports.httpPutMethod =  "PUT";
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End