import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  styled,
  Checkbox,
  Grid,
  CircularProgress,
  Modal

  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
// Customizable Area End

import RolesPermissionsController, {
  Props,
  configJSON,
} from "./RolesPermissionsController";

export default class RolesPermissions extends RolesPermissionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { employeesData, loading } = this.state
    return (
      // Customizable Area Start
      <>
        <MainHeaderSection>
          <KPIdetailsTypography variant="h5">Permissions</KPIdetailsTypography>
        </MainHeaderSection>
        {loading ? (<CircularProgressBox >
          <StyledCircularProgress  />
        </CircularProgressBox>) : (<StyledContainer maxWidth="xl" >
          <Grid container>
            <Grid item xs={12}>
              <CategoryTitle variant="h6">Employees</CategoryTitle>
              <PermissionList>
                {employeesData.map((employee: any) => (
                  <PermissionRow key={employee.id}>
                    <Box>
                      <Typography>{employee.name}</Typography>
                    </Box>
                    <CheckBoxParent>
                      <CheckBoxContainerCustom>
                        <CustomCheckbox
                        data-test-id="changeViewPermissionId"
                          disableRipple
                          disableFocusRipple
                          color="primary"
                          checked={employee.can_view}
                          onChange={() => this.changeViewPermission(employee.id)} />
                        <ViewTypography>View</ViewTypography>
                      </CheckBoxContainerCustom>
                      <CheckBoxContainerCustom>
                        <CustomCheckbox
                         data-test-id="changeEditPermissionId"
                          disableRipple
                          disableFocusRipple
                          color="primary"
                          checked={employee.can_edit}
                          onChange={() => this.changeEditPermission(employee.id)} />
                        <EditTypography>Edit</EditTypography>
                      </CheckBoxContainerCustom>
                    </CheckBoxParent>
                  </PermissionRow>
                ))}
              </PermissionList>
            </Grid>
          </Grid>
        </StyledContainer>)}
        <Modal open={false}>
          <Box style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'white',
            padding: '26px 38px',
            borderRadius: '8px',
          }}>
            <ModalHeaderBox >
              <Typography style={{
                fontFamily: 'Open Sans',
                fontSize: '18px',
                fontWeight: 700,
                color: '#000000'
              }}>{'Name'}</Typography>
              <CrossButton
                data-test-id="handleClose"
               ></CrossButton>
            </ModalHeaderBox>
            <Box style={{ margin: '24px 0 20px 0' }}>
              <Typography style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '6px' }}><span style={{
                fontFamily: 'Open Sans',
                fontSize: '25px',
                fontWeight: 200,
                color: 'gray',
              }}>Date</span> <span style={{
                 display:'block',
                fontSize: "16px",
                fontWeight: 600,
                color: '#000000',
              }}
              >03-03-2024</span></Typography>
              <Typography style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '6px' }}><span style={{
                fontSize: '26px',
                background:'red',
                fontWeight: 500,
                color: '#000000',
              }} >Time of Arrival</span><span style={{
                fontFamily: 'Open Sans',
                fontSize: "18px",
                fontWeight: 600,
                color: 'red',
                background:'black'
              }}>10:15 AM</span></Typography>
              <StyledBox>Statics</StyledBox>
              <StyledNewBox>Average</StyledNewBox>
              <Typography style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '8px' }}><span style={{
                fontFamily: 'Open Sans',
                fontSize: '22px',
                fontWeight: 700,
                color: 'black',
              }}>Status</span>

              Hii
              </Typography>
            </Box>
            <Box>
              <DummyModalContentBox ><span style={{ fontFamily: 'Open Sans', fontSize: '16px', fontWeight: 400, color: '#000000' }}>Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur.</span></DummyModalContentBox>
            </Box>
          </Box>
        </Modal>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomCheckbox = styled(Checkbox)({
  "&:hover": {
    backgroundColor: "transparent",
  },
  cursor: "pointer",
  marginRight: '-10px'
});
const MainHeaderSection = styled(Box)({
  padding: "20px 32px",
  fontFamily: "Open Sans",
  marginBottom: "34px",
  background: "#F3F4F5",
  display: "flex",
  alignItems: "center",
  gap: "21px",
});

const KPIdetailsTypography = styled(Typography)({
  fontFamily: "Open Sans",
  fontSize: "18px",
  fontWeight: 700,
  color: "#000000",
  lineHeight: "24px",
});

const CategoryTitle = styled(Typography)({
  fontFamily: 'Open Sans',
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '-0.2571428716182709px',
  color: '#000000',
  marginBottom: '19px',
});


const PermissionList = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
});
const CheckBoxContainerCustom = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  gap: '8px',
});
const PermissionRow = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: '0px 4px 18px 0px #00000017',
  borderRadius: '12px',
  minHeight: '58px',
  padding: '0px 25px',
  marginBottom: '16px',
});
const ViewTypography = styled(Typography)({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '21.79px',
  color: '#000000'
})
const EditTypography = styled(Typography)({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '21.79px',
  color: '#000000'
})
const CircularProgressBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100px'
})
const CheckBoxParent = styled(Box)({
  display: 'flex',
  justifyContent: "center",
  alignItems: 'center'
})
const StyledContainer=styled(Container)({
   padding: "0px 32px" 
})
const StyledCircularProgress=styled(CircularProgress)({
   color: '#FDD001'
})
const CrossButton = styled(Button)({
  background: '#EBEBEB',
  padding: '11px',
  borderRadius: '6px',
  minWidth: '40px'

})
const DummyModalContentBox = styled(Box)({
  background: '#F7F7F7',
  padding: '14px',
  borderRadius: '8px',
  border: '0.25px solid #00000047'
})
const ModalHeaderBox = styled(Box)({
  display: "flex",
  justifyContent: 'space-between',
  alignItems: 'center',
})
const StyledBox = styled(Box)({
  display: "flex",
  alignItems: "baseline",
  justifyContent: "center",
  boxShadow: '0px 4px 18px 0px #00000017',
  borderRadius: '16px',
  minHeight: '56px',
  padding: '0px 24px',
  marginBottom: '11px',
})
const StyledNewBox = styled(Box)({
  display: "none",
  alignItems: "center",
  justifyContent: "flex-wrap",
  boxShadow: '0px 4px 19px 0px #00000017',
  borderRadius: '20px',
  minHeight: '57px',
  padding: '0px 22px',
  marginBottom: '10px',
})
// Customizable Area End
