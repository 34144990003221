import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Kpis {
  name:string;
  description:string;
}
export interface TempKpis {
  name:string;
  description:string;
  weightage:string;
  calculation:string;
  status:string
}
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open: boolean;
  onClose: () => void;
  message: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  togglemodel: boolean;
  kpis: Kpis[];
  tempKpis:TempKpis[];
  templateData: { name: string, description: string },
  open: boolean,
  disableKpiModel: boolean
  showTemplateData:any;
  selectedKPIName: string,
  sideBarFlag:boolean,
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTemplateId: string = "";
  getEditTemplateId: string = "";
  totalTemplateId: string = "";
  getDisableKpiId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];


    this.state = {
      sideBarFlag:false,
      selectedKPIName: '',
      showTemplateData:{},
      disableKpiModel: false,
      open: false,
       templateData: {
        name: "",
        description: "",

      },
      kpis: [],
      tempKpis:[
        {
          name:"KPI-1",
          description:"Sales Goal<br />Sales Goal : 1000000<br />Pro Rata Basis<br />MQR : 70%",
          weightage:"20 points",
          calculation:"",
          status:"Preparing"
        },
        {
          name:"KPI-2",
          description:"Sales Goal<br />Sales Goal : 1000000<br />Pro Rata Basis<br />MQR : 70%",
          weightage:"20 points",
          calculation:"Slabs:2 Slabs",
          status:"Ready"
        },
        {
          name:"KPI-3",
          description:"Sales Goal<br />Sales Goal : 1000000<br />Pro Rata Basis<br />MQR : 70%",
          weightage:"20 points",
          calculation:"Slabs:2 Slabs",
          status:"Ready"
        },
        {
          name:"KPI-4",
          description:"Sales Goal<br />Sales Goal : 1000000<br />Pro Rata Basis<br />MQR : 70%",
          weightage:"20 points",
          calculation:"Slabs:2 Slabs",
          status:"Ready"
        },
        {
          name:"KPI-5",
          description:"Sales Goal<br />Sales Goal : 1000000<br />Pro Rata Basis<br />MQR : 70%",
          weightage:"20 points",
          calculation:"Manual",
          status:"Ready"
        }

      ],
      togglemodel: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start

  handleOpenModal = () => {
    this.setState({ open: !this.state.open});
  };
  handleOpenKpiModal = () => {
    this.setState({ disableKpiModel: !this.state.disableKpiModel });
  };

  handleUpdateKpiForm = (values: { [key: string]: string }) => {
    alert(values)
  };

  handleAddFeature = () => {
    this.setState((prevState) => ({
      kpis: [...prevState.kpis, { name: '', description: '' }],
      // templateData: { name: '', description: '' }, 
    }));
  };

  handleFeatureChange = (index: number, value: string) => {

    this.setState((prevState) => {
      const updatedKPIs = [...prevState.tempKpis];
      updatedKPIs[index].name = value;
      return { kpis: updatedKPIs };
    });

  };

  handleDescriptionChange = (index: number, value: string) => {
    this.setState((prevState) => {
      const updatedKPIs = [...prevState.tempKpis];
      updatedKPIs[index].description = value;
      return { kpis: updatedKPIs };
    });
  };

  handleOpenAddKpiModel = () => {
    this.setState({
      togglemodel: true,
    });
  };

  handleClose = () => {
    this.setState({ togglemodel: false});
  };
  

  
 
  // Customizable Area End

}
