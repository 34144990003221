export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const privacy = require("../assets/privacy.png");
export const terms_condition = require("../assets/terms_condition.png");
export const version_update = require("../assets/version_update.png");
export const version_screen = require("../assets/version_screen.png");
export const left_arrow = require("../assets/left_arrow.png");
export const  RightArrow  = require('../assets/right_arrow.png');
export const  email_notification  = require('../assets/email_notification.svg');
export const  BigCross  = require('../assets/big_cross.png');
export const  Arrow_left_light  = require('../assets/arrow_left_light.png');
export const  Success  = require('../assets/success.png');


