import React from "react";

import {
    Box,
    Typography,
    Grid,
    // Customizable Area Start
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,

    Divider
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

// Customizable Area End

import AddDkrDetailsController, {
    CalculationRow,
    Props, Row
} from "./AddDkrDetailsController";

export default class AddDkrDetails extends AddDkrDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <Box style={webStyles.maincontainer}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid className="first" style={webStyles.leftContainer} item>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item style={webStyles.rowDisplay}>
                                <ArrowBackIosIcon style={{ fontSize: "26px" }} />
                                <span
                                    style={webStyles.labelStyle1}
                                >
                                    Edit Template
                                </span>
                            </Grid>
                            <Grid item style={webStyles.rowDisplay}>
                                <ArrowBackIosIcon style={webStyles.labelStyle1} />
                                <span style={webStyles.labelStyle as React.CSSProperties}>
                                    Add DKR Details
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="second" style={webStyles.emptyContainer} item />
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    style={webStyles.informationContainer as React.CSSProperties}
                >
                    <Grid
                        className="first"
                        item
                        style={webStyles.informationSubContainerHeading}
                        xs={12}
                    >
                        <div>
                            <FormControl component="fieldset">
                                <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                    <span style={webStyles.labelStyle2 as React.CSSProperties}>KPI Details</span>
                                </FormLabel>

                            </FormControl>
                        </div>
                        <div className="form-field text-center" id="signupBlock4">
                            <div className="control" id="signupfieldcontrol2">
                                <button
                                    type="button"
                                    className="button submit"
                                    style={webStyles.editBtn as React.CSSProperties}
                                >
                                    Edit KPI Details
                                </button>
                            </div>
                        </div>

                    </Grid>
                    <Grid
                        className="first"
                        item
                        style={webStyles.informationSubContainer}
                        xs={12}
                    >
                        <FormControl component="fieldset">
                            <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                <span style={webStyles.labelStyle as React.CSSProperties}>Type</span>
                            </FormLabel>

                            <span style={webStyles.valueStyle as React.CSSProperties}>Core</span>

                        </FormControl>

                    </Grid>
                    <Grid
                        className="second"
                        item
                        style={webStyles.informationSubContainer}
                        xs={12}
                    >
                        <FormControl component="fieldset">
                            <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                <span style={webStyles.labelStyle as React.CSSProperties}>Basic Details</span>
                                <Typography variant="body1" style={{ color: "red" }}>*</Typography>
                            </FormLabel>
                            <Grid container style={{ display: "flex" }} xs={12}>
                                <Grid item xs={3}>
                                    <div className="form-block" id="signupBlock3">
                                        <form>
                                            <div
                                                className="form-field"
                                                id="signupform"
                                                style={webStyles.topThirty}
                                            >
                                                <span style={webStyles.labelStyleHeader as React.CSSProperties}>Title</span>
                                                <div
                                                    className="control"
                                                    id="signupfieldcontrol"
                                                    style={webStyles.marginLeft0}
                                                >
                                                    <span style={webStyles.valueStyle as React.CSSProperties}>KPI-1</span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Grid>
                                {this.state.type === "core" &&
                                    <Grid item xs={6}>
                                        <div className="form-block" id="signupBlock3">
                                            <form>
                                                <div
                                                    className="control"
                                                    id="signupfieldcontrol"
                                                    style={webStyles.marginLeft0}
                                                >
                                                    <span style={webStyles.valueStyle as React.CSSProperties}>Sales Goal<br />Sales Goal : 1000000<br />Pro Rata Basis<br />MQR : 70%</span>

                                                </div>
                                            </form>
                                        </div>

                                    </Grid>
                                }
                                <Grid item xs={3}>
                                    <div className="form-block" id="signupBlock3">
                                        <form>
                                            <div
                                                className="form-field"
                                                id="signupform"
                                                style={webStyles.topThirty}
                                            >
                                                <span style={webStyles.labelStyleHeader as React.CSSProperties}>Points(Weightage)</span>
                                                <div
                                                    className="control"
                                                    id="signupfieldcontrol"
                                                    style={webStyles.marginLeft0}
                                                >
                                                    <span style={webStyles.valueStyle as React.CSSProperties}>20 points</span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </Grid>
                            </Grid>
                        </FormControl>

                    </Grid >

                    <Grid
                        className="third"
                        item
                        style={webStyles.informationSubContainer}
                        xs={12}
                    >
                        <FormControl component="fieldset">
                            <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                <span style={webStyles.labelStyle as React.CSSProperties}>Basic Details</span>
                                <Typography variant="body1" style={{ color: "red" }}>*</Typography>
                            </FormLabel>
                            <div className="form-block" id="signupBlock3">
                                <form>
                                    <div
                                        className="form-field"
                                        id="signupform"
                                        style={webStyles.topThirty}
                                    >
                                        <span style={webStyles.labelStyleHeader as React.CSSProperties}>Automatic</span>
                                        <div
                                            className="control"
                                            id="signupfieldcontrol"
                                            style={webStyles.marginLeft0}
                                        >
                                            <span style={webStyles.valueStyle as React.CSSProperties}>Yes</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </FormControl>

                    </Grid>
                    <Grid
                        className="first"
                        item
                        style={webStyles.informationSubContainer}
                        xs={12}
                    >
                        <FormControl component="fieldset">
                            <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                <span style={webStyles.labelStyle as React.CSSProperties}>Monthly Goal Details</span>
                            </FormLabel>
                        </FormControl>
                        <Grid container style={{ display: "flex" }} xs={12}>
                            <Grid item xs={2}>
                                <div className="form-block" id="signupBlock3">
                                    <form>
                                        <div
                                            className="form-field"
                                            id="signupform"
                                            style={webStyles.topThirty}
                                        >
                                            <span style={webStyles.labelStyleHeader as React.CSSProperties}>Goal Type</span>
                                            <div
                                                className="control"
                                                id="signupfieldcontrol"
                                                style={webStyles.marginLeft0}
                                            >
                                                <span style={webStyles.valueStyle as React.CSSProperties}>Number</span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Grid>

                            <Grid item xs={5}>
                                <div className="form-block" id="signupBlock3">
                                    <form>
                                        <div
                                            className="form-field"
                                            id="signupform"
                                            style={webStyles.topThirty}
                                        >
                                            <span style={webStyles.labelStyleHeader as React.CSSProperties}>DKR Calculation Formulas</span>
                                            <div
                                                className="control"
                                                id="signupfieldcontrol"
                                                style={webStyles.marginLeft0}
                                            >
                                                <span style={webStyles.valueStyle as React.CSSProperties}>Sum</span>


                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </Grid>
                            <Grid item xs={5}>
                                <div className="form-block" id="signupBlock3">
                                    <form>
                                        <div
                                            className="form-field"
                                            id="signupform"
                                            style={webStyles.topThirty}
                                        >
                                            <span style={webStyles.labelStyleHeader as React.CSSProperties}>Goal Value (In numbers)</span>
                                            <div
                                                className="control"
                                                id="signupfieldcontrol"
                                                style={webStyles.marginLeft0}
                                            >
                                                <span style={webStyles.valueStyle as React.CSSProperties}>1,000,000</span>


                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        className="first"
                        item
                        style={webStyles.informationSubContainer}
                        xs={12}
                    >
                        <FormControl component="fieldset">
                            <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                <span style={webStyles.labelStyle as React.CSSProperties}>Analytics and Run rate</span>
                            </FormLabel>
                        </FormControl>
                        <Grid container style={{ display: "flex", alignItems: "last baseline" }}>
                            <Grid item xs={3}>
                                <div className="form-block" id="signupBlock3">
                                    <form>
                                        <div
                                            className="form-field"
                                            id="signupform"
                                            style={webStyles.topThirty}
                                        >
                                            <span style={webStyles.labelStyleHeader as React.CSSProperties}>Run rate</span>
                                            <div
                                                className="control"
                                                id="signupfieldcontrol"
                                                style={webStyles.marginLeft0}
                                            >
                                                <span style={webStyles.valueStyle as React.CSSProperties}>Yes</span>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </Grid>
                            <Grid item xs={3}>
                                <div className="form-block" id="signupBlock3">
                                    <form>
                                        <div
                                            className="form-field"
                                            id="signupform"
                                            style={webStyles.topThirty}
                                        >
                                            <span style={webStyles.labelStyleHeader as React.CSSProperties}>Part of Analytics</span>
                                            <div
                                                className="control"
                                                id="signupfieldcontrol"
                                                style={webStyles.marginLeft0}
                                            >
                                                <span style={webStyles.valueStyle as React.CSSProperties}>Yes</span>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </Grid>
                            <Grid item xs={6}>

                                <div className="form-block" id="signupBlock3">
                                    <form>
                                        <div
                                            className="form-field"
                                            id="signupform"
                                            style={webStyles.topThirty}
                                        >
                                            <span style={webStyles.labelStyleHeader as React.CSSProperties}>Analytics Parameter</span>
                                            <div
                                                className="control"
                                                id="signupfieldcontrol"
                                                style={webStyles.marginLeft0}
                                            >
                                                <span style={webStyles.valueStyle as React.CSSProperties}>Sales</span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Grid>
                        </Grid>

                    </Grid>


                </Grid >
                <Divider light />
                <Grid
                    container
                    alignItems="center"
                    style={webStyles.informationContainer as React.CSSProperties}
                >
                    <Grid
                        className="first"
                        item
                        style={webStyles.informationSubContainerHeading}
                        xs={12}
                    >
                        <div>
                            <FormControl component="fieldset">
                                <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                    <span style={webStyles.labelStyle2 as React.CSSProperties}>Daily KPI Report</span>
                                </FormLabel>

                            </FormControl>
                        </div>
                    </Grid>
                    <Grid
                        className="first"
                        item
                        style={webStyles.informationSubContainer}
                        xs={12}
                    >
                        <FormControl component="fieldset">
                            <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                <span style={webStyles.labelStyle as React.CSSProperties}>Calculation Type</span>
                            </FormLabel>
                            <RadioGroup aria-label="type" data-test-id="radiogroupInput" name="type" value={"prorata"} onChange={this.handleChange} row>
                                <FormControlLabel value="Slab" control={<Radio style={webStyles.themeColor} />} label="Slab" />
                                <FormControlLabel value="prorata" control={<Radio style={webStyles.themeColor} />} label="Pro rata" />
                            </RadioGroup>
                        </FormControl>

                    </Grid>
                    <Grid
                        className="first"
                        item
                        style={webStyles.informationSubContainer}
                        xs={12}
                    >
                        <FormControl component="fieldset">
                            <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                <span style={webStyles.labelStyle as React.CSSProperties}>Define Calculation Type</span>
                            </FormLabel>
                            {this.state.type === "prorata" ?
                                <div className="form-block" id="signupBlock3">
                                    <form>
                                        <div
                                            className="form-field"
                                            id="signupform"
                                            style={webStyles.topThirty}
                                        >
                                            <span style={webStyles.labelStyleTitle as React.CSSProperties}>Minimum Qualified Result (in %)</span>
                                            <div
                                                className="control"
                                                id="signupfieldcontrol"
                                                style={webStyles.marginLeft0}
                                            >
                                                <input
                                                    type="text"
                                                    data-test-id="txtInputEmail"
                                                    style={webStyles.inputField}
                                                    className="control-input"
                                                    placeholder="70%"
                                                    name="email"
                                                    id="pronounce-control"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                :
                                <div className="form-block" id="signupBlock3">
                                    <form>
                                        {this.state.calculationType.map((row: CalculationRow, index: number) => (
                                            <Grid container xs={12} style={webStyles.gridAlign}>
                                                <Grid item xs={2}>
                                                    <div
                                                        className="form-field"
                                                        id="signupform"
                                                        style={webStyles.topThirty}
                                                    >
                                                        <span style={webStyles.labelStyleTitle as React.CSSProperties}>No. of slabs</span>
                                                        <div
                                                            className="control"
                                                            id="signupfieldcontrol"
                                                            style={webStyles.marginLeft0}
                                                        >
                                                            <input
                                                                type="text"
                                                                data-test-id="txtInputEmail"
                                                                style={webStyles.inputFieldDisable}
                                                                className="control-input"
                                                                value={row.id}
                                                                name="email"
                                                                id="pronounce-control"
                                                                disabled
                                                            />
                                                        </div>

                                                    </div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div
                                                        className="form-field"
                                                        id="signupform"
                                                        style={webStyles.topThirty}
                                                    >
                                                        <span style={webStyles.labelStyleTitle as React.CSSProperties}>Min.</span>
                                                        <div
                                                            className="control"
                                                            id="signupfieldcontrol"
                                                            style={webStyles.marginLeft0}
                                                        >
                                                            <input
                                                                type="text"
                                                                data-test-id="txtInputEmail"
                                                                style={webStyles.inputFieldTable}
                                                                className="control-input"
                                                                value={`${row.min}%`}
                                                                name="email"
                                                                id="pronounce-control"
                                                            />
                                                        </div>

                                                    </div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div
                                                        className="form-field"
                                                        id="signupform"
                                                        style={webStyles.topThirty}
                                                    >
                                                        <span style={webStyles.labelStyleTitle as React.CSSProperties}>Max.</span>
                                                        <div
                                                            className="control"
                                                            id="signupfieldcontrol"
                                                            style={webStyles.marginLeft0}
                                                        >
                                                            <input
                                                                type="text"
                                                                data-test-id="txtInputEmail"
                                                                style={webStyles.inputFieldTable}
                                                                className="control-input"
                                                                value={`${row.max}%`}
                                                                name="email"
                                                                id="pronounce-control"
                                                            />
                                                        </div>

                                                    </div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div
                                                        className="form-field"
                                                        id="signupform"
                                                        style={webStyles.topThirty}
                                                    >
                                                        <span style={webStyles.labelStyleTitle as React.CSSProperties}>Weightage</span>
                                                        <div
                                                            className="control"
                                                            id="signupfieldcontrol"
                                                            style={webStyles.marginLeft0}
                                                        >
                                                            <input
                                                                type="text"
                                                                data-test-id="txtInputEmail"
                                                                style={webStyles.inputFieldTable}
                                                                className="control-input"
                                                                value={row.weightage}
                                                                name="email"
                                                                id="pronounce-control"
                                                            />
                                                        </div>

                                                    </div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div
                                                        className="form-field"
                                                        id="signupform"
                                                        style={webStyles.topThirty}
                                                    >
                                                        <div
                                                            className="control"
                                                            id="signupfieldcontrol"
                                                            style={webStyles.marginLeft0}
                                                        >
                                                            {index === this.state.calculationType.length - 1 ? (
                                                                <button
                                                                    type="button"
                                                                    data-test-id="btnAdd"
                                                                    style={webStyles.addBtn}
                                                                    className="control-input"
                                                                    onClick={this.handleAddItem}
                                                                >
                                                                    Add
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    type="button"
                                                                    data-test-id="btnRemove"
                                                                    style={webStyles.removeBtn}
                                                                    className="control-input"
                                                                    onClick={() => this.handleRemoveItem(index)}
                                                                >
                                                                    Remove
                                                                </button>
                                                            )}
                                                        </div>

                                                    </div>
                                                </Grid>

                                            </Grid>
                                        ))}

                                    </form>
                                </div>
                            }
                        </FormControl >

                    </Grid >
                    <Grid
                        className="first"
                        item
                        style={webStyles.informationSubContainerTable}
                        xs={12}
                    >
                        <>
                            <FormControl component="fieldset">
                                <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                    <span style={webStyles.labelStyle as React.CSSProperties}>Applicable DKRs</span>
                                </FormLabel>
                            </FormControl>

                            <TableContainer style={webStyles.tableContainer}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow style={webStyles.tableHead}>
                                            <TableCell>Sr. No.</TableCell>
                                            <TableCell>DKR Type</TableCell>
                                            <TableCell>
                                                DKR Brief Title&nbsp;(g)
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.tableRow.map((row: Row) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row">
                                                    {row.id}
                                                </TableCell>
                                                <TableCell>{row.title}</TableCell>
                                                <TableCell>
                                                    <div className="form-block" id="signupBlock3">
                                                        <form>
                                                            <div
                                                                className="form-field"
                                                                id="signupform"
                                                                style={webStyles.topThirty}
                                                            >
                                                                <div
                                                                    className="control"
                                                                    id="signupfieldcontrol"
                                                                    style={webStyles.marginLeft0}
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        data-test-id="txtInputEmail"
                                                                        style={webStyles.inputFieldTable}
                                                                        className="control-input"
                                                                        placeholder={row.description}
                                                                        name="email"
                                                                        id="pronounce-control"
                                                                    />
                                                                </div>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    </Grid>
                </Grid >

                <Grid style={webStyles.buttonContainer}>
                    <div className="form-field text-center" id="signupBlock4">
                        <div className="control" id="signupfieldcontrol2">
                            <button
                                type="button"
                                data-test-id="btnEmailLogIn"
                                style={webStyles.cotinueBtn}
                                className="button submit"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                    <div className="form-field text-center" id="signupBlock4">
                        <div className="control" id="signupfieldcontrol2">
                            <button
                                type="button"
                                data-test-id="btnCancel"
                                style={webStyles.cancelBtn}
                                className="button submit"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Grid>

            </Box >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyles = {
    maincontainer: {
        background: "#F3F4F5",
        height: "64px",
        marginTop: "32px",
        marginRight: "-20px"
    },
    leftContainer: {
        padding: "19px 0px 21px 38px"
    },
    emptyContainer: { padding: "0px 44px 0px 0px" },
    informationContainer: { padding: "0 32px", alignItems: "baseline", flexDirection: "column" },
    informationSubContainer: { marginTop: "24px", width: "800px" },
    informationSubContainerTable: { marginTop: "24px", width: "90%" },
    informationSubContainerHeading: { marginTop: "24px", display: "flex", alignItems: "initial", justifyContent: "space-between", width: "90%" },
    topThirty: { marginTop: "30px" },
    flexDisplay: { display: "flex" },
    container: {
        width: "100%",
        height: "100%"
    },
    root: {
        minWidth: 275
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)"
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    labelStyleTitle: {
        color: "black",
        fontSize: "18px",
        fontWeight: "bold"
    },
    labelStyle: {
        color: "black",
        fontSize: "24px",
        fontWeight: "300"
    },
    labelStyleHeader: {
        color: "#c8ccc9",
        fontSize: "22px",
        fontWeight: "bold"
    },
    valueStyle: {
        color: "black",
        fontSize: "22px",
        marginTop: "10px"
    },
    rowDisplay: { display: "flex", alignItems: "center" },
    labelStyle1: {
        color: "black",
        fontSize: "18px",
    },
    labelStyle2: {
        color: "black",
        fontSize: "24px",
        fontWeight: "bold"
    },
    inputField: {
        width: "40%",
        background: 'transparent',
        border: '0.5px solid #979797'
    },
    inputFieldTable: {
        width: "70%",
        background: 'transparent',
        border: '0.5px solid #979797'
    },
    cotinueBtn: {
        margin: "10px 0 0"
    },
    editBtn: {
        height: "60px",
        fontSize: "20px",
        fontWeight: "normal"
    },
    marginLeft0: {
        marginLeft: 0,
        marginTop: 10
    },
    cancelBtn: {
        background: "transparent",
        margin: "10px 0 0"
    },
    removeBtn: {
        background: "transparent",
        borderColor: '#FF5C5C',
        margin: "10px 0 0",
        width: "70%",
    },
    addBtn: {
        background: "transparent",
        borderColor: '#FDD100',
        margin: "10px 0 0",
        width: "70%",
    },
    buttonContainer: { display: "flex", gap: "20px", margin: "0 40px" },
    themeColor: {
        color: "#FDD100"
    },
    headerWithmandatory: {
        display: "flex",
        alignItems: "baseline",
        gap: "4px"
    },
    tableHead: {
        backgroundColor: "#F5F5F5",
    },
    inputFieldDisable: {
        width: "70%",
        background: "#F5F5F5",
        border: '0.5px solid #979797'
    },
    tableContainer: { border: "1px solid #979797", borderRadius: "10px", marginTop: "10px" },
    gridAlign: {
        alignItems: "end"
    },
    infIcon: { display: "flex", alignItems: "center", justifyContent: "center", border: "1px solid #FDD100", borderRadius: "70%", width: "30px", height: "30px", marginLeft: "10px" }
};
// Customizable Area End
