export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const editable_icon = require("../assets/editable_icon.png");
export const plus_icon = require("../assets/Plus_icon.png");
export const close_square = require("../assets/close-square.png");
export const profile_edit_pencil_icon = require("../assets/profile_edit_pencil_icon.png");
export const profile_add_icon = require("../assets/profile_add_icon.png");
export const user_profile_icon = require("../assets/user_profile_icon.svg");
export const user_profile = require("../assets/Oval.png");
export const like_icon = require("../assets/Group.png");
export const poster = require("../assets/Poster.png");
export const close = require("../assets/close.png");