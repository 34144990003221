import React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography, Modal, Button, styled, MenuItem, Checkbox } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { editable, disableEdit, InfoSquareBold, InfoSquareLight, StrokeRightInfo, CompletedPana } from "./assets";
import { webStyle } from "./EditTemplate.web";
import './dashboard.css'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Customizable Area End
import DashboardController, { Props } from "./DashboardController";


export default class Users extends DashboardController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  tagModal() {
    return (
      <Modal open={this.state.tagTemplateModal} >
        <ModalBox>
          <ModalTitleBox>
            <HeadingTypography>Tag Template</HeadingTypography>
          </ModalTitleBox>
          <ContentBox data-test-id="ContextBoxId" >
            <Box><ContentTypography>
              Template Name
            </ContentTypography>
              <Box>
                <StyledModalContainer style={{ width: "100%" }}>
                  <div ref={this.datePickerRef}>
                    <Box
                      data-test-id="dropdown-trigger"
                      style={{
                        width: '100%',
                        height: "56px",
                        backgroundColor: "#F6F6F9",
                        borderRadius: "12px",
                        padding: "15px 8px 15px 15px",
                        cursor: "pointer",
                        position: "relative",
                        background: "#F3F4F5",
                        border: '0.25px solid #979797'
                      }}
                      onClick={this.toggleDropdown}
                    >
                      <NewStyledSpan >
                        {this.state.selectedTemplateName}
                      </NewStyledSpan>
                      <StyledExpandMoreIcon  />

                      {this.state.isOpen && (
                        <Box
                          data-test-id="dropdown-menu"
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: "0",
                            width: "100%",
                            overflowY: "auto",
                            backgroundColor: "white",
                            borderRadius: "12px",
                            zIndex: 1,
                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                            paddingBottom: "10px",
                            marginTop: "5px",
                            maxHeight: "323px",
                          }}
                        >
                          {
                            this.state.getTemplateName.length > 0 ? (
                              this.state.getTemplateName.map((val: any, index: any) => (
                                <MenuItem
                                  key={index}
                                  value={val.id}
                                  data-testid="handleSelectionTemplateanti"
                                  onClick={() => this.handleSelectionTemplate(val)}
                                  style={{
                                    fontSize: "16px",
                                    fontFamily: "Open Sans",
                                    fontWeight: 400,
                                    color: "black",
                                    marginRight: "20px",
                                    maxWidth: "100%",
                                    borderRadius: "8px",
                                    whiteSpace: "normal",
                                    overflowWrap: "break-word",
                                    marginLeft: '-10px',
                                    display: 'flex',
                                    gap: '16px',
                                    marginBottom: '-8px',

                                  }}
                                  className="showTempateName"
                                >
                                  <Checkbox
                                    style={{
                                      fontSize: 'large',
                                      paddingRight: "0px",
                                      color: this.state.selectedTemplateCheckBox === val.id ? "#FFD11A" : "#DADADA"
                                    }}
                                    checked={this.state.selectedTemplateCheckBox === val.id}
                                  />
                                  <NamesTypography >
                                    {val.attributes.name}
                                  </NamesTypography>
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem
                                disabled
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Open Sans",
                                  fontWeight: 400,
                                  color: "#D32F2F",
                                  marginRight: "20px",
                                  maxWidth: "290px",
                                  borderRadius: "8px",
                                  whiteSpace: "normal",
                                  overflowWrap: "break-word",
                                  marginLeft: '-10px',
                                  display: 'flex',
                                  gap: '16px',
                                  marginBottom: '-8px',
                                }}
                              >
                                <NoTemplatesTypography >
                                  No templates assigned
                                </NoTemplatesTypography>
                              </MenuItem>
                            )
                          }

                        </Box>
                      )}
                    </Box>

                  </div>
                </StyledModalContainer>
              </Box>
            </Box>

            <Box>
              <ContentTypography>
                Department
              </ContentTypography>
              <Box>
                <input style={{
                  width: '627px',
                  height: '56px',
                  borderRadius: "12px",
                  border: "0.25px solid #979797",
                  background: "#F3F4F5",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#959595",
                  padding: '16px'
                }}
                  disabled
                  value={this.state.selectedTemplateData.department}
                ></input>
              </Box>
            </Box>
            <Box>
              <ContentTypography>
                Employee
              </ContentTypography>
              <Box style={{ background: '#F3F4F5' }}>
                <input
                  value={this.state.selectedTemplateData.name}
                  disabled
                  style={{
                    padding: '16px',
                    width: '627px',
                    height: '56px',
                    borderRadius: "12px",
                    border: "0.25px solid #979797",
                    background: "#F3F4F5", fontSize: "16px",
                    fontWeight: 500,
                    color: "#959595"
                  }}></input>
              </Box>
            </Box>
          </ContentBox>
          <SubmitBoxContainer >
            <SubmitBox
              data-test-id="handleCloseSubmitId"
              onClick={this.handleTagTemplateModal}
              style={{ cursor: 'pointer', fontSize: '16px', fontWeight: 600, color: "#000000", textTransform: 'capitalize' }}>
              Save
            </SubmitBox>
            <SubmitBox
              data-test-id="handleTemplateCancel"
              onClick={this.handleTagTemplateCancel}
              style={{ cursor: 'pointer', fontSize: '16px', textTransform: 'capitalize', fontWeight: 600, color: "#000000", background: 'white', border: '1px solid #979797' }}>
              Cancel
            </SubmitBox>
          </SubmitBoxContainer>
        </ModalBox>
      </Modal>
    )
  }

  tootltip() {

    return (
      <div>
        <div>
          <AssignedTemplatesTypography
           >
            Assigned Templates
          </AssignedTemplatesTypography>
          <div
          >
            <Typography style={{
              fontFamily: 'Open Sans, sans-serif',
              fontSize: '12px',
              fontWeight: 500,
              color: '#406587',
              lineHeight: '16.34px',
            }}>1. Developer Gamecard (1)</Typography>
            <Typography style={{
              fontFamily: 'Open Sans, sans-serif',
              fontSize: '12px',
              fontWeight: 500,
              color: '#406587',
              lineHeight: '16.34px',
            }}>2. Developer DKR</Typography>
          </div>
        </div>
      </div>
    )
  }
  succesModal() {
    return (
      <Modal open={this.state.succesModalisOpen} >
        <ModalBoxSucces>
          <ModalContentBox >
            <HeadingTypographySucces>  Congratulations!</HeadingTypographySucces>
            <BoxTemplate >
              Template Has Been Successfully Assigned
            </BoxTemplate>
            <ImageStyled src={CompletedPana} />
          </ModalContentBox>

        </ModalBoxSucces>
      </Modal>
    )
  }
  kpiProgress(item: any) {
    return (
      <CircularProgressBox position="relative" display="inline-flex">
        <CircularProgress data-test-id="progress" variant="determinate" value={Number(item.kpi_progress)} style={this.getCircularProgressStyle(item)}
        />
        <ContainerBox top={0} left={0} bottom={0} right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <KpiProgressTypography
            data-test-id="typography"
            variant="caption"
            color="textSecondary"
          >
            {item.kpi_progress + "%"}
          </KpiProgressTypography>
        </ContainerBox>
      </CircularProgressBox>
    )
  }

  render() {
    const modal = () => {
      return <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
        data-testId="confirmationModal"
        open={this.state.disableKpiModel}
        onClose={this.handleOpenKpiModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <>
          <div className="kpi-model">

            <AreYouSureTypography data-testid="accountnameofmodal" >Are you sure you want to {this.state.activateDeactivate ? "disable " : "enable "} the account of {this.state.accountsName} ? </AreYouSureTypography>
            <div id="modal-footer" style={webStyle.KpiModelfooterStyle}>
              <SubmitBtn data-test-id="save" onClick={() => { this.confirmDisableAccount() }}
              >Yes</SubmitBtn>
              <div style={webStyle.buttoncancel} data-test-id="cancleBtnDivForModal">
                <CancelBtn

                  onClick={this.handleOpenKpiModal}
                  className="modalCancelBtn"
                  variant="outlined"

                >
                  Cancel
                </CancelBtn>
              </div>
            </div>

          </div>
        </>
      </Modal>
    }

    return (
      // Customizable Area Start
      <>
        <div className="content-wrapper">
          <div className="user-table">
            <ResponsiveTableBox className="responsive-table" >
              <table className="table" cellSpacing="0" cellPadding="0"
                style={{ position: 'relative', width: 'calc(100% - 83px)', margin: 'auto' }}>
                <DummyBox ></DummyBox>
                <thead>
                  <tr>
                    <th >Employee ID</th>
                    <th>Name</th>
                    <th>Department</th>
                    <th>Templates</th>
                    <th>KPI Progress</th>
                    <th>Enable</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.viewAllUserData.map((item: any, key: number) => {

                    return (
                      <tr data-test-id="rows" key={key} style={{ pointerEvents: item.signed_up === false ? 'none' : 'auto', }}>
                        <td className={this.getClassName(item.activate_deactivate)}>
                          {item.employee_id
                            ? item.employee_id
                            : "-"}
                        </td>
                        <td className={this.getClassName(item.activate_deactivate)}>
                          <span className="attr-name"> {item.name
                            ? item.name
                            : "-"}</span>
                        </td>
                        <td className={this.getClassName(item.activate_deactivate)}>
                          {item.department
                            ? item.department
                            : "-"}
                        </td>
                        <td className={this.getClassName(item.activate_deactivate)}>
                          {item.templates === "Not Assigned" ? (
                            <Typography
                              style={{
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '21.79px',
                                color: '#406587',
                                cursor: 'pointer'
                              }}
                              data-test-id="notAssigned"
                              onClick={() => this.handleTagTemplateModal(item)}
                            >
                              Not Assigned
                              <NewStyledImg  src={StrokeRightInfo} data-testid="handleTagTemplateModalNotAssigned" onClick={() => this.handleTagTemplateModal(item)} alt="Stroke Info" />
                            </Typography>
                          ) : (
                            <Typography
                              style={{
                                fontSize: '16px',
                                fontWeight: 500,
                                lineHeight: '21.79px',
                                color: '#406587',
                                display: 'flex',
                                gap: '5px',
                                alignItems: 'center',
                                cursor: 'pointer'
                              }}
                              onClick={() => this.handleTagTemplateModal(item)}
                              data-test-id="Assigned"
                            >
                              Assigned
                              <div className="tooltip-container">
                                <img
                                  src={this.state.hoveredIndex === key ? InfoSquareBold : InfoSquareLight}
                                  onMouseEnter={() => this.setState({ hoveredIndex: key })}
                                  onMouseLeave={() => this.setState({ hoveredIndex: null })}
                                  alt="Info" className="tooltip-image" />
                                <span className="tooltip-text" data-testid="tooltip">{this.tootltip()}</span>
                              </div>
                              <img style={{ marginLeft: '8px', cursor: 'pointer' }} src={StrokeRightInfo} data-testid="handleTagTemplateModal" onClick={() => this.handleTagTemplateModal(item)} alt="Stroke Info" />
                            </Typography>
                          )}
                        </td>
                        <td className={this.getClassName(item.activate_deactivate)}>
                          {item.kpi_progress === "NA" ? "N/A" :
                            this.kpiProgress(item)
                          }
                        </td>
                        <td className={this.getClassName(item.activate_deactivate)}>
                          <AntSwitch
                            data-test-id="emp_switch"
                            checked={item.activate_deactivate}
                            onClick={() => {
                              this.handleSwitchChange(item.id, item.name, item.activate_deactivate); this.handleOpenKpiModal()
                            }
                            }
                            name="checked"
                            id={`switch-${item.id}`}
                            style={{ cursor: 'pointer', pointerEvents: "auto" }}
                          />
                        </td>
                        <td className="action">
                          {item.activate_deactivate ? (
                            <img src={editable} width={30} height={30} style={{ cursor: 'pointer' }} />
                          ) : (
                            <img src={disableEdit} width={30} height={30} style={{ cursor: 'pointer' }} />
                          )}

                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </ResponsiveTableBox>
          </div>
          {modal()}
          {this.tagModal()}
          {this.succesModal()}
        </div>

      </>
      // Customizable Area End
    );
  }

}
// Customizable Area Start
export const AntSwitch = withStyles(theme => ({
  root: {
    width: 50,
    height: 26,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    "&$checked": {
      transform: "translateX(22px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#6192c8",
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 22,
    height: 22,
  },
  track: {
    borderRadius: 13,
    backgroundColor: "#bcbeca",
    opacity: 1,
  },
  checked: {},
}))(Switch);

const ModalBox = styled(Box)({
  position: 'fixed',
  padding: '55px 48px 55px 48px',
  maxWidth: '725px',
  width: '100%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '8px',
  overflowY: 'auto',

});
const ModalBoxSucces = styled(Box)({
  position: 'fixed',
  padding: '60px 48px 40px 48px',
  maxWidth: '668px',
  width: '100%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '8px',
  overflowY: 'auto',
  display: 'flex',
  alignItems: "center",
  justifyContent: "center"
});

const ModalTitleBox = styled(Box)({
  display: "flex",
  justifyContent:
    'space-between',
  position: 'relative'
})
const BoxTemplate = styled(Box)({
  fontSize: '16px',
  fontWeight: 500,
  color: "#000000",
  marginBottom: '80px',
  opacity: '58%',

})
const ContentBox = styled(Box)({
})
const ContentTypography = styled(Typography)({
  color: '#000000',
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '24px',
  textAlign: 'left',
  marginTop: "35px",
  marginBottom: "16px",

})
const SubmitBoxContainer = styled(Box)({
  display: 'flex',
  width: '100%', paddingRight: '51px',
  gap: 14
})
const HeadingTypography = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: '24px',
  textAlign: 'left',
  color: '#000000'


})
const HeadingTypographySucces = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: '24px',
  textAlign: 'center',
  color: '#000000',
  marginBottom: '13px',



})
const SubmitBox = styled(Button)({
  maxWidth: '100px',
  width: '100%',
  background: '#FFCC00',
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  borderRadius: '8px',
  marginTop: '40px',
  '&:hover': {
    backgroundColor: '#FFCC00',
    color: '#000000',
    cursor: 'pointer',
  },
})
const StyledModalContainer = styled(Box)({
  '@media (max-width: 1370px)': {
    width: "170px",

  },
})
const ModalContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
const CircularProgressBox = styled(Box)({

})
const ContainerBox = styled(Box)({

})
const KpiProgressTypography = styled(Typography)({

})
const SubmitBtn = styled(Button)({
  backgroundColor: '#FDD100',
  color: 'black',
  width: '80px',
  fontSize: '12px',
  fontWeight: 600,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#FDD100',
  },
})
const CancelBtn = styled(Button)({
  backgroundColor: 'white',
  color: 'black',
  width: '80px',
  fontSize: '12px',
  fontWeight: 600,
  textTransform: 'none',
})
const DummyBox = styled(Box)({
  position: 'absolute',
  top: 10,
  left: -40,
  right: -30,
  background: '#f4f4f5',
  height: '55px',
  zIndex: -1
})
const NoTemplatesTypography = styled(Typography)({
  color: '#000000',
  fontWeight: 500,
  fontSize: '16px',
  padding: "10px"
})
const NamesTypography = styled(Typography)({
  color: '#000000',
  fontWeight: 400,
  fontSize: '14px'
})
const AreYouSureTypography = styled(Typography)({
  color: "#000",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  padding: "5px 0px 30px 25px"
})
const AssignedTemplatesTypography=styled(Typography)({
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16.34px',
    color: '#406587',
    marginBottom: '4px',
})
const NewStyledSpan=styled('span')({
   fontSize: "16px",
    fontWeight: 500, 
    color: "black" 
})
const NewStyledImg=styled('img')({
  marginLeft: '8px',
   cursor: 'pointer',
    width: '7px',
     height: '14px'
})
const ResponsiveTableBox=styled(Box)({
  padding: 0 
})
const StyledExpandMoreIcon=styled(ExpandMoreIcon)({
   float: "right",
    color: "black", 
    fontSize: "25px" 
})
const ImageStyled=styled('img')({
  width: "223px",
   height: "204px" 
})
// Customizable Area End
