export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const kpimainlogo = require("../assets/logo.png");
export const kpipasswordlogo = require("../assets/logo_2.png");
export const kpiverifylogo = require("../assets/logo_3.png");
export const footerImage = require("../assets/Vector.png");
export const SiteStats = require("../assets/SiteStats-cuate.png");
export const Dashboard = require("../assets/Dashboard.png");
export const DataReport = require("../assets/DataReport.png");
export const modalImage = require("../assets/ModalIcon.png");
