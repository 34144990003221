import React from "react";
// Customizable Area Start
import {
  Button,
  Typography,
  Box,
  IconButton,
  Dialog,
  TextField,
  MenuItem, Link, Popover ,Grid
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { createTheme, styled } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';



// Customizable Area End
import EditTemplate2Controller, { Props } from "./EditTemplate2Controller";
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent', 
        }
      }
    }
  }
});
export default class EditTemplate2 extends EditTemplate2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() { 
    // Customizable Area Start
    
    const UpdateKpiDetailCard = () => {

      return (
        <Dialog
          open={this.state.isduplicateOpen}
          onClose={this.handleClose2}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.3)', boxShadow: 'none' },
          }}
          PaperProps={{
            style: { boxShadow: 'none' },
            elevation: 0,
          }}
        >
          <StyledModelInnerContainer>
            <Box
              style={{
                width: 490,
                boxShadow: 'none',
                backgroundColor: 'white',
                borderRadius: '8px',
                padding: '30px',
              }}
            >
              <Box style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography
                    id="dialog-title"
                    style={{
                      fontSize: 22,
                      color: 'black',
                      display: 'flex',
                      fontWeight: 500,
                      justifyContent: 'center',
                    }}
                  >
                    Do you really want to update KPI Details?
                    </Typography>
                  <Typography
                    id="dialog-description"
                    style={{
                      fontSize: 18,
                      color: 'black',
                      lineHeight: 2.5,
                      justifyContent: 'center',
                      display: 'flex',
                      fontWeight: 400
                    }}
                  >
                    Updating KPI Details will remove the DKRs
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    paddingRight: '40px',
                    paddingLeft: '40px',
                    marginTop: '22px',
                  }}
                >
                  <Button
                    data-test-id="addKpi"
                    style={{
                      color: 'black',
                      marginRight: '12px',
                      width: '80px',
                      fontSize: '12px',
                      textTransform: 'none',
                      backgroundColor: '#FDD100',
                      fontWeight: 600,
                      fontFamily:"Open Sans"
                    }}
                    onClick={this.navigateToSignUp.bind(this)}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="outlined"
                    style={{
                      backgroundColor: 'white',
                      fontWeight: 600,
                      color: 'black',
                      width: '80px',
                      fontFamily:"Open Sans",
                      fontSize: '12px',
                      textTransform: 'none',
                    }}
                    data-testid="hhh"
                    onClick={this.handleClose2}
                  >
                    No
                  </Button>
                </Box>
              </Box>
            </Box>
            </StyledModelInnerContainer>
        </Dialog>


      )
    };
   
    const breadcrumbs = [
      <Link
        key="1"
        href="/EditTemplate2"
      >
        <Typography style={{ color: 'black', fontSize: "18px", fontWeight: 701, fontFamily: 'Open Sans', }}>Edit Template</Typography>
      </Link>,
    ];
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      <form style={webStyle.template as React.CSSProperties}>
        <div style={webStyle.filterHeader}>
          <Breadcrumbs separator="" aria-label="breadcrumb" data-test-id="addKpi">
              <div>
              <IconButton style={{ color: "black", fontSize: "small", padding: "0px", background: "none" }} data-test-id="nextButton" onClick={this.navigateToTemplateAdded}>
                  <ArrowBackIosIcon />
                </IconButton>
              </div>
              {breadcrumbs}
            </Breadcrumbs>
          </div>
        </form>

        <Box>
          <Box sx={webStyle.templateNameContainer}>
            <Box style={{ marginLeft: '20px' }}>
              <Typography style={webStyle.templateNameText}>
                Template Name
              </Typography>
              <TextField
                hiddenLabel
                data-test-id={`description`}
                value=""
                style={webStyle.templateNameInput}
                InputProps={{ disableUnderline: true }}
              />
            </Box>
            <Box style={{ marginLeft: '15px' }}>
              <Typography style={webStyle.templateNameText}>
                Template Description
              </Typography>
              <TextField
                data-test-id={`description`}
                value=""
                rows={6}
                multiline
                InputProps={{ disableUnderline: true }}
                fullWidth
                style={webStyle.descriptionInput}
              />
            </Box>
          </Box>
          <Box sx={webStyle.kpiHeader}>
            <Typography style={webStyle.kpiListText}>
              KPI List
            </Typography>
            <Button
              data-test-id="addKpi"
              style={webStyle.addKpiButton as React.CSSProperties}>
              Add KPI
            </Button>
          </Box>

          <StyledModalContainer>
            <Box sx={webStyle.totalWeightageContainer}>
              <Box>
                <Box style={webStyle.weightageCircle}>
                  <Typography style={webStyle.weightageText}>
                    100%
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography style={webStyle.totalWeightageText}>
                  Total Weightage
                </Typography>
                <StyledModalPara>
                  <p style={webStyle.weightageDescription}>
                    This Weightage Is The Total Sum Of All The KPIS Available In A Template, And Is Affected On Addition And Reduction Of KPI. This Weightage Is The Total Sum Of All The KPIS Available In A Template
                  </p>
                  <p style={webStyle.weightageNote}>
                    Total Always Needs To Be 100.
                  </p>
                </StyledModalPara>
              </Box>
            </Box>
            </StyledModalContainer>
                       {this.state.templateAdd.map((template: any, index: any) => (
        template.attributes.kpis.map((kpi: any, kpiIndex: any) => (
            <Box key={`${index}-${kpiIndex}`} >
            <Box>
              <Typography style={webStyle.coreText}>
                {kpi.kpi_type}
              </Typography>
            </Box>
            <Box
          style={{
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
            backgroundColor: 'white',
            borderRadius: "12px",
            display: 'flex',
            flexDirection: 'row',
            padding: "30px 15px",
            marginLeft: '50px',
            width: 'auto',
            marginTop: '50px',
            marginRight: '65px'

          }}

        >
          <Grid container>
          <Grid item md={2}>
          <Box style={{ marginLeft: '25px' }}>  
            <Typography
              style={{
                fontSize: "21px",
                color: 'black',
                fontFamily: 'Open Sans',
                fontWeight: 700,
              }}
            >
              KPI Name
            </Typography>
            <TextField
              data-test-id={`feature`}
              value={kpi.name}
              hiddenLabel
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                backgroundColor: '#F3F4F5',
                border: 'none',
                width: '98%',
                marginTop: '12px',
                padding: '10px',
                borderRadius: '10px',
              }}
            />
          </Box>
          </Grid>
          <Grid item md={3}>
          <Box style={{ marginLeft: '25px' }}>
            <Typography
              style={{
                color: 'black',
                fontFamily: 'Open Sans',
                fontWeight: 700,
                fontSize: "21px",
              }}
            >
              KPI Description
            </Typography>
            <TextField
              value={kpi.description}
              data-test-id={`description`}
              fullWidth
              rows={8}
              multiline
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                width: '100%',
                marginTop: '12px',
                borderRadius: '10px',
                border: 'none',
                padding: '12px',
                backgroundColor: '#F3F4F5',
              }}
            />
          </Box>
          </Grid>
          <Grid item md={2}>
          <Box style={{ marginLeft: '30px' }}>
            <WeightageTypography>
              Weightage
            </WeightageTypography>
            <TextField
              data-test-id={`description`}
              value={kpi.weightage}
              hiddenLabel
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                backgroundColor: '#F3F4F5',
                border: 'none',
                padding: '10px',
                width: '90%',
                borderRadius: '10px',
                marginTop: '12px',
              }}
            />
          </Box>
          </Grid>
          <Grid item md={2}>
          <Box style={{ marginRight: '-8px' }}>
            <Typography
              style={{
                fontFamily: 'Open Sans',
                fontWeight: 700,
                color: 'black',
                fontSize: "21px",
              }}
            >
              Goal Calculation
            </Typography>
            <TextField
              data-test-id={`description`}
              value=""
              hiddenLabel
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                marginTop: '12px',
                backgroundColor: '#F3F4F5',
                borderRadius: '10px',
                padding: '10px',
                width: '105%',
                border: 'none',
              }}
            />
          </Box>
          </Grid>
          <Grid 
          item 
          md={3} 
          style={{
            display: "flex", 
            flexDirection: "row", 
            justifyContent: "space-between"}}>
          <Box 
          style={{ marginLeft: '42px', 
          marginTop: '30px', 
            position: "relative" }}>
           
                <Button   data-test-id="addKpi"
                style={{
                  backgroundColor: "#dcdfff",
                  padding: "9px",
                  color: "#7d7dde",
                  borderRadius: "13px",
                  fontWeight: 400,
                  width: "130%",
                  textTransform: "none",
                  border: "1px solid #a5a8ff",
                  marginTop: "12px",
                  fontSize: "18px",
                }}
                >
                Preparing
              </Button>
          </Box>
          
          <Box data-test-id="onclickuser" onClick={() => this.handleSelecttemplate(kpi.id)}  >
          
  <IconButton data-test-id="onclickuser" onClick={(event) => {
    this.handleClick7(event); // Pass the event to handleClick7
   // Pass the kpi.id to handleSelecttemplate
  }}>
    <MoreVert style={{ color: 'black', fontSize: '40px', marginLeft: '20px' }} />
  </IconButton>
  <Popover
    open={Boolean(this.state.anchorEl7)}
    anchorEl={this.state.anchorEl7}
    onClose={this.handleClickClose7}
    anchorOrigin={{
      horizontal: 'right',
      vertical: 'bottom',
    }}
    transformOrigin={{
      horizontal: 'right',
      vertical: 'top',
    }}
    PaperProps={{
      style: {
        padding: '10px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
        borderRadius: '10px',
      },
    }}
  >
    
            <Box >
              <MenuItem
                style={{ backgroundColor: '#FDD001', color: 'black' }}
                data-test-id="nextButton"
                onClick={this.navigateToTimingView}
              >
                View Detailed KPI
              </MenuItem>
              <MenuItem onClick={this.toggleModal}>Edit KPI Details</MenuItem>
              <MenuItem
                style={{ color: 'black' }}
                data-test-id="Gonext"
                onClick={this.navigateToTimingDKR}
              >
                Add DKR Details
              </MenuItem>
              <MenuItem style={{ color: 'black' }}>Remove</MenuItem>
            </Box>
  </Popover>
  {UpdateKpiDetailCard()}
</Box>

          </Grid>
          </Grid>


        </Box>
        </Box>
        ))))}

          <Box style={{ marginTop: '50px',  marginLeft: '50px' }}>
            <Button data-test-id="addKpi" style={webStyle.submitButton as React.CSSProperties}>
              Submit
            </Button>
            <Button variant="outlined" style={webStyle.cancelButton as React.CSSProperties}>
              Cancel
            </Button>
          </Box>
      </Box>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  templateNameContainer: {
    flexDirection: 'row',
    display: 'flex',
    marginTop: '20px',
  },
  templateNameText: {
    fontWeight: 700,
    fontFamily: 'Open Sans',
    color: 'black',
    fontSize: "18px",
  },
  templateNameInput: {
    backgroundColor: '#F3F4F5',
    border: 'none',
    width: '450px',
    borderRadius: '10px',
    padding: '8px',
    marginTop: '15px',
  },
  descriptionInput: {
    width: '550px',
    marginTop: '15px',
    border: 'none',
    backgroundColor: '#F3F4F5',
    borderRadius: '10px',
    padding: '8px',
  },
  kpiHeader: {
    display: "flex",
    height: "70px",
    flexDirection: "row",
    backgroundColor: '#F3F4F5',
    justifyContent: "space-between",
    marginTop: '20px',
  },
  kpiListText: {
    color: 'black',
    fontWeight: 700,
    fontSize: "18px",
    marginLeft: '20px',
    fontFamily: 'Open Sans',
    marginTop: '20px',
  },
  addKpiButton: {
    marginBottom: "10px",
    marginTop: "10px",
    backgroundColor: "#FDD100",
    marginRight: "20px",
    color: "black",
    width: "130px",
    fontWeight: 700,
    fontSize: "16px",
    textTransform: 'none',
    fontFamily: 'Open Sans',
  },
  totalWeightageContainer: {
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    backgroundColor: 'white',
    padding: "25px 15px 25px 15px",
    display: 'flex',
    borderRadius: "12px",
    flexDirection: 'row',
    marginLeft: '40px',
    marginTop: '50px',
    marginRight: '50px',
    width: 'auto',
  },
  weightageCircle: {
    height: "70px",
    width: "70px",
    borderRadius: "50%",
    border: "5px solid #fdd100",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "25px",
  },
  weightageText: {
    fontWeight: 700,
    color: 'black',
    fontFamily: 'Open Sans',
    fontSize: "large",
  },
  totalWeightageText: {
    fontWeight: 700,
    fontSize: "28px  ",
    color: 'black',
    fontFamily: 'Open Sans',
    marginLeft: '20px',
  },
  weightageDescription: {
    fontWeight: 250,
    color: 'black',
    fontFamily: 'Open Sans',
    fontSize: "16px",
    marginLeft: '20px',
    marginTop: '20px',
  },
  weightageNote: {
    fontWeight: 250,
    color: 'black',
    fontFamily: 'Open Sans',
    fontSize: "16px",
    lineHeight: '0.5',
    marginLeft: '20px',
  },
  coreText: {
    color: 'black',
    fontWeight: 700,
    fontFamily: 'Open Sans',
    fontSize: "24px",
    marginLeft: '55px',
    marginTop: '35px',
    marginBottom: "-30px"
  },
  submitButton: {
    fontFamily: 'Open Sans',
    backgroundColor: "#FDD100",
    marginRight: "20px",
    color: "black",
    width: "170px",
    fontWeight: 550,
    fontSize: "medium",
    textTransform: 'none'
  },
  cancelButton: {
    fontFamily: 'Open Sans',
    color: "black",
    backgroundColor: "white",
    fontSize: "medium",
    width: "170px",
    fontWeight: 550,
    textTransform: 'none'
  },

  template: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px 10px",
    backgroundColor: '#F3F4F5',
    position: "relative",
    height: "72px",
    alignItems: "center"
  },
  filterHeader: {
    padding: "0px 0px 0px 22px",
    display: "flex",
    alignItems: "flex-start", 
    gap: "20px"
   },
};

const StyledModalContainer = styled(Box)({
  '@media (max-width: 1370px)': {
    width: 'fit-content',
    marginRight: '20px' 
},
})

const StyledModalPara = styled(Box)({
  '@media (max-width: 1370px)': {
    fontSize: "small",
},
})
const StyledModelInnerContainer = styled(Box)({
  '&.MuiPaper-elevation24': {
    boxShadow: "none",
  }
});
const ButtonHoverStop = styled(Box)({
  '& .MuiIconButton-root:hover': {
    backgroundColor: 'transparent', 
  },
});
const WeightageTypography = styled(Typography)({
    color: 'black',
    fontWeight: 700,
    fontFamily: 'Open Sans',
    fontSize: "21px",
})
const ReadyButton = styled(Button)({
    borderRadius: "13px",
    padding: "11px",
    backgroundColor: "#d8eed6",
    width: "130px",
    color: "#1fa625",
    fontSize: "16px",
    textTransform: "none",
    fontWeight: 250,
    border: "1px solid #88cb85",
})
// Customizable Area End
