import React from "react";

// Customizable Area Start
import { footerImage, otpUnlock } from "./assets";
import OtpInput from 'react-otp-input';
// Customizable Area End

import OTPInputAuthController, { Props } from "./OTPInputAuthController";

export default class OTPInputAuthScreen extends OTPInputAuthController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            // Customizable Area Start
            < div style={webStyleOtp.parentDiv} id="otpinputblock" >
                <div className="otp-block otp-wrapper" style={webStyleOtp.subDiv} id="otpinputblocksubdiv">
                    <div className="center-block-header text-center" style={webStyleOtp.headerBlock}>
                        <div className="header-logo">
                            <img src={otpUnlock} alt='logo' style={{ width: "132px" }} />
                        </div>
                        <div>
                            <h3>Enter OTP</h3>
                            <span>Enter 6 Digit Code Sent To Your Email</span>
                        </div>
                    </div>

                    <div className="form-block opt-wrapper" style={{ marginTop: "76px" }}>
                        <form onSubmit={this.verifyOtp}>
                            <div className={`form-field otp-block`}>
                                <div className="control">
                                    <OtpInput
                                        value={this.state.otp}
                                        onChange={this.handleOtpChange}
                                        numInputs={6}
                                        inputType="number"
                                        renderSeparator={<span> </span>}
                                        containerStyle={{ display: 'flex', justifyContent: 'space-between' }}

                                        inputStyle={{
                                            margin: '0 5px', padding: '10px', width: '40px'
                                        }}
                                        renderInput={(props, index) =>
                                            <input
                                                data-testid="otpinput"
                                                id={this.state.filledInputs >= (index + 1) ? 'otp-field' : ''}
                                                autoFocus={index === 0}
                                                {...props}
                                            />
                                        }
                                    />
                                    <span style={{ color: "black", display: "flex", justifyContent: "flex-end", margin: "4px 0 0 0" }}>
                                        <span role="button" style={{ color: this.state.countdown === 0 ? "blue" : "black", cursor: "pointer", whiteSpace: "break-spaces" }}
                                            onClick={() => {
                                                if (this.state.countdown === 0) {
                                                    this.setState({ countdown: 5 * 60 });
                                                    this.startCountdown();
                                                    this.ResendOtpRequest();
                                                }
                                            }} >Resend </span>
                                        code in {Math.floor(this.state.countdown / 60)}:{this.state.countdown % 60 < 10 ? '0' : ''}{this.state.countdown % 60}
                                    </span>
                                </div>
                            </div>

                            <div className="form-field text-center otp-button">
                                <div className="control">

                                    <input type="submit"
                                        disabled={this.state.filledInputs !== 6}
                                        className="button submit"
                                        value="Continue"

                                    />

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <img src={footerImage} alt='logo' style={{ width: "100%", height: "7rem", position: "absolute", bottom: 0, left: 0, right: 0 }} />
            </div >
            // Customizable Area End
        )
    }
}
const webStyleOtp = {
    parentDiv: {
        position: "relative",
        height: "100vh",
        overflowY: "hidden"
    },
    subDiv: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        height: '100%',
        justifyContent: 'center',
    },
    headerBlock: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: 'center',
        gap: '20px',
    },
} as any;