import React from "react";
// Customizable Area Start
import {
  Button,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Box
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Customizable Area End
import AddDkrController, { Props } from "./AddDkrController";
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  }
});
export default class OccurrenceDkr extends AddDkrController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.Occurmaincontainer}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid className="first" style={webStyle.OccurleftSide} item>
              <Grid container spacing={2} alignItems="center">
                <Grid item style={webStyle.OccurrowarrowDisplay}>
                  <ArrowBackIosIcon style={{ fontSize: "24px", color: "black" }} 
                  data-test-id="nextButton1" onClick={this.navigateToEditTemplate2} />
                  <span
                    style={{...webStyle.OccurHeadStyleTop1,fontWeight: 400}}>
                    Edit Template
                  </span>
                </Grid>
                <Grid item style={webStyle.OccurrowarrowDisplay}>
                  <ArrowBackIosIcon style={{...webStyle.OccurHeadStyleTop1,color:"#B2B2B2",fontSize:"16px"}} />
                  <span style={{...webStyle.OccurabelStyleoptionhead as React.CSSProperties,fontWeight:700,fontSize:"18px"}}>
                    Add DKR Details
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="second" 
            style={webStyle.OccurnullContainer} item />
          </Grid>
          <Grid
            container
            alignItems="center"
            style={webStyle.OccurinformContainer as React.CSSProperties}>
            <Grid
              className="first"
              item
              style={webStyle.OccurinformSecondContainerHead}
              xs={12}>
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.OccurheaderMain10}>
                    <span style={webStyle.OccurHeadtopStyle22 as React.CSSProperties}>KPI Details</span>
                  </FormLabel>

                </FormControl>
              </div>
              <div className="form-field text-center" id="signupBlock4">
                <div className="control" id="signupfieldcontrol2">
                  <Button style={webStyle.Editbutton as React.CSSProperties} 
                  data-test-id="EditButton" onClick={this.navigateToEditKpi}>
                    Edit KPI Details
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.OccurinformationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.OccurheaderMain10}>
                  <span style={{ ...webStyle.OccurabelStyleoptionhead as React.CSSProperties, 
                    fontSize: "22px" ,fontWeight: 500}}>Type</span>
                </FormLabel>
                <span style={{...webStyle.OccurblurvalueStyle as React.CSSProperties,fontWeight:400}}>Occurrence</span>
              </FormControl>
            </Grid>
            <Grid
              className="second"
              item
              style={webStyle.OccurinformationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" 
                style={{ ...webStyle.OccurheaderMain10, marginTop: "30px" }}>
                  <span style={{...webStyle.OccurabelStyleoptionhead as React.CSSProperties,fontWeight:500,fontSize:"22px"}}>Basic Details</span>
                </FormLabel>
                <Grid container style={{ display: "flex" }} xs={12}>
                  <Grid item xs={3}>
                    <div className="form-block" id="signupBlock3">
                      <form>
                        <div
                          className="form-field"
                          id="signupform"
                          style={webStyle.OccurtopdescThirty}>
                          <span style={webStyle.OccurTopstyleHeader as React.CSSProperties}>Title</span>
                          <div
                            className="control"
                            id="signupfieldcontrol"
                            style={webStyle.OccurmarginLeft0}>
                            <span style={webStyle.OccurblurvalueStyle as React.CSSProperties}>Occurrence</span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Grid>
                  <>
                    <Grid item xs={6}>
                      <div className="form-block" id="signupBlock3">
                        <form>
                          <div
                            className="form-field"
                            id="signupform"
                            style={webStyle.OccurtopdescThirty}
                          >
                            <span style={webStyle.OccurTopstyleHeader as React.CSSProperties}>Description</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.OccurmarginLeft0}
                            >
                              <span style={{...webStyle.OccurblurvalueStyle as React.CSSProperties,fontWeight:400}}>1 Missed Submission :8 points<br /></span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="form-block" id="signupBlock3">
                        <form>
                          <div
                            className="form-field"
                            id="signupform"
                            style={{...webStyle.OccurtopdescThirty,width:"max-content"}}
                          >
                            <span style={webStyle.OccurTopstyleHeader as React.CSSProperties}>Points (Weightage)</span>
                            <div
                              className="control"
                              id="signupfieldcontrol"
                              style={webStyle.OccurmarginLeft0}
                            >
                              <span style={{...webStyle.OccurblurvalueStyle as React.CSSProperties,fontWeight:400}}>10 points</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                  </>
                </Grid>
              </FormControl>
            </Grid >
          </Grid >
          <Divider light />
          <Grid
            container
            alignItems="center"
            style={webStyle.OccurinformContainer as React.CSSProperties}
          >
            <Grid
              className="first"
              item
              style={webStyle.OccurinformSecondContainerHead}
              xs={12}
            >
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.OccurheaderMain10}>
                    <span style={webStyle.OccurHeadtopStyle22 as React.CSSProperties}>Daily KPI Report</span>
                  </FormLabel>

                </FormControl>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.OccurinformationSubContainerTable}
              xs={12}  >
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.Occurheadermandatory}>
                    <span style={{...webStyle.OccurlabelStyle100 as React.CSSProperties,fontWeight:500}}>Applicable DKRs</span>
                  </FormLabel>
                </FormControl>
                <TableContainer style={webStyle.OccurtableOccurmaincontainer}>
                  <Table aria-label="customized table">
                  <TableHead>
                      <TableRow style={webStyle.OccurmaintableHead}>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"35px",fontWeight:600}}>Sr. No.</TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"35px",fontWeight:600}}>DKR Type</TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",fontWeight:600}}>DKR Brief Title</TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",fontWeight:600}}>
                          DKR Response
                        </TableCell>
                        <TableCell>  </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow 
                        >
                          <TableCell component="th" scope="row" style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"65px"}}>
                           1.
                          </TableCell>
                          <TableCell component="th" scope="row" style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"65px"}}>
                           Captured DKR
                          </TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={{marginLeft: 0, marginTop: 10}}
                                  >
                                    <input
                                      type="text"
                                      data-test-id="txtInputEmail"
                                      style={webStyle.Occurinputtext}
                                      className="control-input"
                                      placeholder="Todays Collection"
                                      name="email"
                                      id="pronounce-control"
                                    />
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.OccurmyBox}
                                  >
                                    <select style={webStyle.OccurmySelect}>
                                       <option value="Manual">Dropdown</option>
                                       <option value="Dropdown">Manual</option>
                                    </select>
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell style={{width:"24%"}}>
                          <a href="#" className="view-link" 
                          style={{color:"#1c33ad",fontSize:"16px",fontWeight:400,fontFamily:"Inter"}}>Add Option</a>
                          </TableCell>
                        </TableRow>
                        <TableRow 
                        style={{ backgroundColor: "#F6F6F9" }}>
                          <TableCell component="th" scope="row" style={{fontFamily:"Open Sans",fontSize:"14px",color:"black"}}>
                           Option 1.
                          </TableCell>
                          <TableCell>
                            <Box 
                            className="form-block" 
                            id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    style={webStyle.OccurmarginLeft0}
                                    id="signupfieldcontrol"
                                  >
                                    <input
                                      type="text"
                                      style={{...webStyle.OccurinputmainFieldTable,backgroundColor:"White",width:"100%"}}
                                      data-test-id="txtInputEmail"
                                      placeholder="Recorded stock"
                                      className="control-input"
                                      id="pronounce-control"
                                      name="email"
                                      />
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box 
                            className="form-block" 
                            id="signupBlock3">
                              <form>
                                <Box
                                  id="signupform"
                                  className="form-field"
                                >
                                  <Box
                                    className="control"
                                    style={webStyle.OccurmyBox}
                                    id="signupfieldcontrol"
                                  >
                                    <select 
                                    style={webStyle.OccurmySelect}>
                                       <option value="Manual">positive
                                       </option>
                                       <option value="Dropdown">Negative
                                       </option>
                                    </select>
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Box 
                             id="signupBlock3"
                            className="form-block">
                              <form>
                                <Box
                                  id="signupform"
                                  className="form-field"
                                >
                                  <Box
                                    style={webStyle.OccurmarginLeft0} 
                                    className="control"
                                    id="signupfieldcontrol" >
                                     <Button 
                                     style={webStyle.OccurRemovebutton as React.CSSProperties}>
                                         Remove Options
                                     </Button>
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow 
                        style={{ backgroundColor: "#F6F6F9" }}>
                          <TableCell component="th" scope="row" style={{fontFamily:"Open Sans",fontSize:"14px",color:"black"}}>
                           Option 2.
                          </TableCell>
                          <TableCell>
                            <Box 
                            className="form-block" 
                            id="signupBlock3">
                              <form>
                                <Box className="form-field"  id="signupform"
                                >
                                  <Box
                                    id="signupfieldcontrol"
                                    style={webStyle.OccurmarginLeft0}
                                    className="control"
                                  >
                                    <input
                                      data-test-id="txtInputEmail"
                                      type="text"
                                      style={{...webStyle.OccurinputmainFieldTable,
                                        backgroundColor:"White",
                                        width:"100%"}}
                                      placeholder="Recorded stock"
                                      className="control-input"
                                      name="email"
                                      id="pronounce-control"/>
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box className="form-block" 
                            id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    id="signupfieldcontrol"
                                    style={webStyle.OccurmyBox}
                                    className="control"
                                  >
                                    <select 
                                    style={webStyle.OccurmySelect}>
                                       <option value="Manual">positive</option>
                                       <option value="Dropdown">Negative</option>
                                    </select>
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Box 
                            className="form-block"
                             id="signupBlock3"
                            >
                              <form>
                                <Box
                                  id="signupform"
                                  className="form-field"
                                >
                                  <Box
                                    style={webStyle.OccurmarginLeft0} 
                                    id="signupfieldcontrol" 
                                    className="control"
                                    >
                                     <Button 
                                     style={webStyle.OccurRemovebutton as React.CSSProperties}>
                                         Remove Options
                                     </Button>
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow 
                        style={{ 
                          backgroundColor: "#F6F6F9" 
                          }}>
                          <TableCell 
                          component="th" 
                          scope="row" 
                          style={{fontFamily:"Open Sans",
                          fontSize:"14px",
                          color:"black"}}>
                           Option 3.
                          </TableCell>
                          <TableCell>
                            <Box className="form-block" 
                            id="signupBlock3">
                              <form>
                                <Box
                                  id="signupform"
                                  className="form-field"
                                >
                                  <Box
                                    className="control"
                                    style={webStyle.OccurmarginLeft0}
                                    id="signupfieldcontrol"
                                  >
                                    <input
                                      data-test-id="txtInputEmail"
                                      type="text"
                                      style={{...webStyle.OccurinputmainFieldTable,
                                        backgroundColor:"White",
                                        width:"100%"}}
                                        placeholder="Recorded stock"
                                        className="control-input"
                                      name="email"
                                      id="pronounce-control"/>
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box className="form-block" 
                            id="signupBlock3">
                              <form>
                                <Box className="form-field" id="signupform"
                                >
                                  <Box
                                    className="control"
                                    style={webStyle.OccurmyBox}
                                    id="signupfieldcontrol"
                                  >
                                    <select style={webStyle.OccurmySelect}>
                                       <option value="Manual">positive</option>
                                       <option value="Dropdown">Negative</option>
                                    </select>
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Box 
                             id="signupBlock3"
                            className="form-block">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    style={webStyle.OccurmarginLeft0} 
                                    className="control"
                                    id="signupfieldcontrol" >
                                     <Button style={webStyle.OccurRemovebutton as React.CSSProperties}>
                                         Remove Options
                                     </Button>
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                        </TableRow>                     
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.OccurinformationSubContainerTable}
              xs={12}  >
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.Occurheadermandatory}>
                    <span style={{...webStyle.OccurlabelStyle100 as React.CSSProperties,
                      fontWeight:500}}>Applicable Occurrence</span>
                  </FormLabel>
                </FormControl>
                <TableContainer style={webStyle.OccurtableOccurmaincontainer}>
                  <Table aria-label="customized table">
                  <TableHead>
                      <TableRow style={webStyle.OccurmaintableHead}>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",fontWeight:600}}>Occurrence Title</TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",fontWeight:600}}>
                        Deductible Value
                        </TableCell>
                        <TableCell>
                           <Button onClick={this.handleAddRow} 
                            style={{ fontFamily: 'Open Sans', backgroundColor: "FDD100", color: "black", marginRight: "20px", width: "160px", fontSize: "16px", fontWeight: 600, textTransform: "none",paddingTop: "6px",paddingBottom:"6px",borderRadius:"10px"  }}>
                               Add Occurrence
                           </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
             {this.state.rows.map((row:any, index:any) => (
              <TableRow key={row.id}>
                 <TableCell component="th" scope="row" 
                 style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"65px"}}>{index + 1}. Negative Responce</TableCell>
                <TableCell>
                   <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    id="signupfieldcontrol"
                                    style={webStyle.OccurmarginLeft0}
                                  >
                                    <input
                                      type="text"
                                      data-test-id="txtInputEmail"
                                      style={{...webStyle.OccurinputmainFieldTable,backgroundColor:"White",width:"30%",color:"Black",textAlign:"start",fontFamily:"Open Sans",fontWeight:400,fontSize:"14px"}}
                                      className="control-input"
                                      placeholder={row.points}
                                      name="email"
                                      id="pronounce-control"
                                    />
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                </TableCell>
                <TableCell>
                  <a data-test-id="clickforRemoveOccurence"
                   onClick={() => this.handleRemoveRow(index)} 
                   style={{ color: "#ff5c5c", textDecoration: "underline" }}>Remove Occurrence</a>
                </TableCell>
              </TableRow>
            ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              
              </>
            </Grid>
          </Grid >

          <Grid style={webStyle.OccurbuttonOccurmaincontainer}>
            <Box style={{ marginTop: '50px',
               marginBottom: '40px' }}>
              <Button style={webStyle.OccurSubmitBtn as React.CSSProperties}>
                Submit
              </Button>
              <Button variant="outlined" style={webStyle.OccurCancelBtn as React.CSSProperties} >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Box >
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  
    OccurmaintableHead: {
        backgroundColor: "#F5F5F5",
      },
    OccurlabelStyle100: {
        fontSize: "22px",
        color: "black",
        fontFamily:"Open Sans",
        fontWeight: 500,
      },
    Occurheadermandatory: {
        alignItems: "baseline",
        display: "flex",
        MarginTop:"30px",
        gap: "4px",
      },
      OccurtableOccurmaincontainer: { 
        marginTop: "15px" ,
        border: "1px solid #979797",
         borderRadius: "10px", 
        },
    OccurinformationSubContainerTable: {
        width: "90%" ,
        marginTop: "24px",
        },
    OccurinputmainFieldTable: {
        background: 'transparent',
        width: "70%",
        border: '0.5px solid #979797'
      },
 
      OccurRemovebutton:{
        fontFamily: 'Open Sans',
        border:"1px solid #ff5c5c", 
        backgroundColor: "White", 
        color: "black", 
        width: "145px", 
        fontSize: "16px",
        fontWeight: 600, 
        textTransform: "none",
        paddingTop: "12px",
        paddingBottom:"12px",
        borderRadius:"10px"
      },
    
      OccurmySelect:{
        border:"none",
        background:"none",
        padding:"15px",
         width:"135",
      },
      OccurmyBox:{
        border:"1px solid #979797",
        width: "145px",
        bottom:"6px",
        borderRadius: "12px",
        marginTop: 10, 
        marginLeft: 0,
      },
      Occurinputtext:{
        border: '0.5px solid #979797',
        background: 'transparent', 
     width:"100%"
      },
  Editbutton:{
    fontFamily: 'Open Sans', 
    backgroundColor: "FDD100", 
    color: "black", 
    padding: "15px 20px 15px 20px", 
    borderRadius: "10px", 
    width: "200px", 
    fontSize: "16px", 
    fontWeight: 600, 
    textTransform: "none"
  },
  
  OccurCancelBtn: {
    fontWeight: 600,
    fontFamily: 'Open Sans',
    width: "170px",
    fontSize: "16px",
    textTransform: "none",
    backgroundColor: "white",
    color: "black",
  },
  OccurSubmitBtn: {
    fontFamily: 'Open Sans',
    backgroundColor: "FDD100",
    marginRight: "20px",
    color: "black",
    fontSize: "16px",
    width: "170px",
    fontWeight: 600,
    textTransform: "none",
    
  },
  TCell: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    color: "black",
    fontWeight: 600
  },
  TableC: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    color: "black",
    fontWeight: 600
  },

  Occurmaincontainer: {
    height: "64px",
    background: "#F3F4F5",
    marginRight: "-20px"
  },
  OccurinformContainer: {
    padding: "0 32px",
    alignItems: "baseline",
    flexDirection: "column"
  },
  OccurinformationSubject: {
    width: "800px",
    marginTop: "24px",
  },
  OccurleftSide: {
    padding: "19px 0px 21px 38px"
  },
  OccurnullContainer: {
    padding: "0px 44px 0px 0px"
  },

  OccurinformSecondContainerHead: {
    marginTop: "24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "initial",
    width: "90%"
  },
  OccurtopdescThirty: {
    marginTop: "30px",
  },
  container: {
    height: "100%",
    width: "100%",
  },
 
  OccurabelStyleoptionhead: {
    color: "black",
    fontWeight: 500,
    fontSize: "22px",
    fontFamily: "Open Sans"
  },
  OccurTopstyleHeader: {
    fontSize: "18px",
    color: "#c8ccc9",
    fontWeight: 700,
    fontFamily: "Open Sans"
  },
  OccurblurvalueStyle: {
    color: "black",
    marginTop: "10px",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: 400
  },
  OccurrowarrowDisplay: {
    alignItems: "center",
    display: "flex",
  },
  OccurHeadStyleTop1: {
    color: "black",
    fontFamily: "Open Sans",
    fontSize: "18px",
  },
  OccurHeadtopStyle22: {
    color: "black",
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Open Sans"
  },
  inputField: {
    width: "60%",
    border: '0.5px solid #979797',
    background: 'transparent',
  },
  inputFieldTabless: {
    width: "70%",
    border: '0.5px solid #979797',
    background: 'transparent',
  },
  OccurmarginLeft0: {
    marginLeft: 0,
    marginTop: 10
  },
 
  OccurbuttonOccurmaincontainer: { display: "flex", gap: "20px", margin: "0 40px" },

  OccurheaderMain10: {
    display: "flex",
    alignItems: "baseline",
    gap: "4px",
    MarginTop: "30px"
  },

};
// Customizable Area End
