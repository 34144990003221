import React from "react";
import { Link } from "react-router-dom";
// Customizable Area Start
import {
  Box,
  styled
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { kpimainlogo, footerImage, modalImage } from "./assets";
import {
  visibility_eye,
  visibility_eye_off,
} from "../../forgot-password/src/assets";
import Modal from "@material-ui/core/Modal";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        color: "#custom color",
        "&$checked": {
          color: "#fdd100",
        },
      },
    },
  },
});

export default class EmailLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      // Customizable Area Start
      <Box style={webStyle.parentBox} className="auth-wrapper">
        <Box id="loginBlock" style={webStyle.subBox}>
          <Box
            className="center-block-header text-center"
            style={webStyle.headerBlock}
          >
            <Box className="header-logo">
              <img
                src={kpimainlogo}
                alt="logo"
                style={{ width: "160px", height: "171px" }}
              />
            </Box>
            <Box>
              <h3>Welcome!</h3>
              <span>Enter your Email Id and password to login</span>
            </Box>
          </Box>
          <Box className="form-block">
            <form>
              <Box className="form-field">
                <span className="label font15">E-Mail</span>
                <Box className="control">
                  <input
                    type="text"
                    data-test-id="txtInputEmail"
                    defaultValue={this.state.email}
                    onChange={(e) => this.handleEmailChange(e)}
                    className={`control-input padd10Radius10 ${
                      this.state.errorMessage ? "error" : ""
                    }`}
                    placeholder="Enter Your Email ID"
                    name="email"
                    id="email-control"
                    style={{marginBottom:'6px'}}
                     onKeyDown={(e:any) => {
                      if (e.key === "Enter") {
                        this.login();
                      }}}
                  />
                  <span style={{ color: "red" }}>
                    {this.state.errorMessage}
                  </span>
                </Box>
              </Box>
              <Box className="form-field marginTop10">
                <span className="label font15">Password</span>
                <Box className="control" style={{ position: "relative" }}>
                  <input
                    type={this.state.showPassword ? "text" : "password"}
                    data-test-id="txtInputPassword"
                    value={this.state.password}
                    onChange={(e) => this.handlePasswordChange(e)}
                    className={`control-input padd10Radius10 ${
                      this.state.passworderrorMessage ? "error" : ""
                    }`}
                    placeholder="Enter Your Password "
                    name="password"
                    id="password-control"
                    onKeyDown={(e:any) => {
                      if (e.key === "Enter") {
                        this.login();
                      }}}
                  />
                  {this.state.showPassword ? (
                    <img
                      src={visibility_eye}
                      alt="icon-eye"
                      className="icon-eye"
                      data-test-id="visibility"
                      onClick={this.toggleShowPassword}
                    />
                  ) : (
                    <img
                      src={visibility_eye_off}
                      alt="icon-eye"
                      className="icon-eye"
                      data-test-id="visibilityhidden"
                      onClick={this.toggleShowPassword}
                    />
                  )}
                  <span style={{ color: "red" }}>
                    {this.state.passworderrorMessage}
                  </span>
                </Box>
              </Box>
              <ForgetBox className="form-field two-column">
                <Box className="control checkbox-wrapper"></Box>

                <Box className="control">
                  <Box onClick={() => this.setNavigateMe()} style={{ color: "#6191C7",cursor:'pointer' }}>
                    Forgot Password?{" "}
                  </Box>
                </Box>
              </ForgetBox>
              <Box className="form-field text-center">
                < Box className="control">
                  <button
                    type="button"
                    data-test-id="btnEmailLogIn"
                    onClick={() => this.login()}
                    disabled={
                      this.state.email === undefined ||
                      this.state.password === undefined
                    }
                    className="button submit"
                  >
                    Sign In
                  </button>
                </Box>
              </Box>
            </form>
            <Modal
              open={this.state.openModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <Box
                style={webStyle.modalContainer}
                id="modal"
                className="center-block-header text-center"
              >
                <Box className="header-logo" id="signupBloc2">
                  <img
                    src={modalImage}
                    alt="logo"
                    style={{ height: "140px", marginBottom: "5%" }}
                  />
                </Box>
                <h3>Login Successful!</h3>
                <p>You have been logged in successfully.</p>
                <Box className="form-field text-center" id="signupBlock4">
                  <Box className="control" id="signupfieldcontrol2" >
                      <button
                      onClick={() =>
                        this.handleLoginContinue()
                      }
                        type="button"
                        style={webStyle.modalBtn}
                        data-test-id="btnmodalcontinue"
                        
                      >
                        Continue
                      </button>
                  </Box>
                </Box>
              </Box>
            </Modal>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  parentBox: {
    position: "relative",
    height: "auto",
    overflowY: "hidden",
    padding: "0px",
    margin: "0px",
    backgroundImage: `url(${footerImage})`,
    backgroundRepeat: "no-repeat",
    minHeight: "100vh",
    backgroundPosition: "bottom center",
    display: "flex",
    alignItems: "center",
    backgroundSize: "contain",
    paddingBottom: "50px",
    fontFamily: "open sans",
    justifyContent: "center",
  },
  subBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    overflow: "auto",
    height: "100%",
    margin: "100px 0 200px",
  },
  headerBlock: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  modalContainer: {
    top: "50%",
    left: "50%",
    transform: `translate(-${"50%"}, -${"50%"})`, // Adjust the transform to center the modal
    position: "absolute",
    width: 400,
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px",
    padding: "16px",
    borderRadius: "10px"
  },
  modalBtn: {
    width: "181px",
    height: "50px",
    backgroundColor: "#FFCC00",
    borderRadius: "5px",
    boxShadow: "none",
    border: "none",
    marginBottom: "10px",
    cursor:'pointer',
  },
  linkText: {
    textDecoration: "underline",
    color: "#6191C7"
  },
  welcomeText: {
    fontSize: "30px",
    fontWeight: "bold",
    color: "#000"
  },
  subHeaderText: {
    fontSize: "20px"
  }
} as any;
const ForgetBox=styled(Box)({
  marginTop:"20px"
})
// Customizable Area End
