import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 386,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 212,
    display: 'block',
    maxWidth: 346,
    overflow: 'hidden',
    width: '100%',
  },
}));

const SignUpSliderOption = ({ swiperData }: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = swiperData.length;

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div className={`signup-slider-wrapper ${classes.root}`}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        autoplay={true}
      >
        {swiperData.map((step: any, index: number) => (
          <div key={step.label} className='slider-img-box'>
            {Math.abs(activeStep - index) <= 2 ? (
              <img className={classes.img} src={step.image} alt={step.label} />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <Paper square elevation={0} className={classes.header}>
        <Typography>{swiperData[activeStep].text}</Typography>
      </Paper>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
        nextButton={null}
        backButton={null}
      />
    </div>
  );
}

export default SignUpSliderOption;
