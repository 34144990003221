import React from "react";
// Customizable Area Start
import { Link } from "react-router-dom";
import "./landingpage.css";
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";
import { KpiLogo, kpimainlogo } from "../../dashboard/src/assets";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    return (
      <>
      <div className="landing-top">
        <div className="landing-header">
          <div className="landing-header-main">
            <div className="site-logo">
              <a href="#">
                <img className="desktop-logo" src={KpiLogo} alt="logo" />
                <img className="mobile-logo" src={kpimainlogo} alt="logo" />
              </a>
            </div>
            <div className="navigation">
              <nav className="main-nav">
                <ul>
                  <li>
                    <a href="#">About us</a>
                  </li>
                  <li>
                    <a href="#">Pricing</a>
                  </li>
                  <li>
                    <a href="#">Products</a>
                  </li>
                  <li>
                    <a href="#">Support</a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="header-button">
              <Link
                to="/EmailAccountLoginBlock"
                data-testid="login"
              >
                <button className="button">Login</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="landing-wrapper">
          <div className="content-wrapper">
            <div className="landing-wrapper">
              <img className="landing-logo" src={kpimainlogo} alt="logo" />
              <h4>Coming Soon</h4>
            </div>
          </div>
        </div>
      </div>
    </>
    );
  }
}
