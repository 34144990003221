import React from "react";
// Customizable Area Start
import {
	Box,
	Button,
	Typography,
	Grid,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { footerImage, SiteStats, Dashboard, DataReport } from "../src/assets";
import SignUpLandingPageController, { Props } from "./SignUpLandingPageController.web";
import { Link } from "react-router-dom"
import SignUpSliderOption from "./SignUpSliderOption";
// Customizable Area End
export default class signUpLandingPage extends SignUpLandingPageController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	// Customizable Area Start
	// Customizable Area End
	render() {
		return (
			// Customizable Area Start
			<SignUpContainer>
				<Grid container justifyContent="center">
					<Grid item xs={6}>
						<Box className='boxFirst'>
							<Typography className='welcomeText'>Welcome!</Typography>
							<SignUpSliderOption swiperData={this.state.swiperData} />
							<Link to="/EmailAccountRegistrationWeb" className="auth-links">
								<Button
									variant="contained"
									className={"signupUserButton"}
									disableElevation
								>
									Sign Up
								</Button>
							</Link>
							<Box className='maineBoxLine'>
								<Box className='oneLine'></Box>
								<Typography className='ortext'>Or</Typography>
								<Box className='oneLine'></Box>
							</Box>
							<Link to="/EmailAccountLoginBlock" className="auth-links">

								<Button
									variant="contained"
									className={"loginUserButton"}
									disableElevation
								>
									Log In
								</Button>
							</Link>
						</Box>
					</Grid>
				</Grid>
			</SignUpContainer>
			// Customizable Area End
		);
	}
}
// Customizable Area Start
const SignUpContainer = styled(Box)({
	padding: "0px",
	margin: "0px",
	backgroundImage: `url(${footerImage})`,
	backgroundRepeat: 'no-repeat',
	minHeight: '100vh',
	backgroundPosition: 'bottom center',
	display: 'flex',
	alignItems: 'center',
	backgroundSize: 'contain',
	paddingBottom: '50px',
	fontFamily: 'open sans',
	"& .boxFirst": {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		width: '100%',
		margin: '50px 0 150px'
	},
	"& .welcomeText": {
		fontSize: "30px",
		lineHeight: "30px",
		fontWeight: "bolder !important",
		color: "#000000",
		width: "100%",
		textAlign: "center",
	},
	"& .mainImageBox": {
		height: "100%",
		width: "100%",
		padding: "30px 40px 30px 40px"
	},
	"& .mainImage": {
		height: "100%",
		width: "100%"
	},

	"& .imageBox": {
		height: "245px"
	},

	"& .imageFirst": {
		height: "100%",
		width: "100%"
	},

	"& .newText": {
		fontSize: "19px !important",
		fontWeight: "300 !important",
		color: "#6a6a6a !important",
		textAlign: "center"
	},
	"& .signupUserButton": {
		width: "100%",
		backgroundColor: "#ffcc00 !important",
		margin: "40px 0px 16px 0px !important",
		padding: "18px",
		fontWeight: "600 !important",
		'& .MuiButton-label': {
			fontSize: '16px',
			lineHeight: '20px',
			fontWeight: 600,
			textTransform:'capitalize'
		}
	},
	"& .loginUserButton": {
		width: "100%",
		backgroundColor: "#37494f !important",
		margin: "16px 0 0px !important",
		padding: "18px",
		fontWeight: "600 !important",
		color: "white !important",
		'& .MuiButton-label': {
			fontSize: '16px',
			lineHeight: '20px',
			fontWeight: 600,
			textTransform:'capitalize'
		}
	},
	"& .maineBoxLine": {
		width: "100%",
		maxWidth: '386px',
		height: "10px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	"& .oneLine": {
		height: "1px",
		width: "50%",
		backgroundColor: "#000000",
	},
	"& .ortext": {
		margin: "0px 5px 0px 5px !important",
		color: "#000000",
		fontSize: "14px"
	},
	"@media only screen and (max-width: 600px)": {
		"& .boxFirst": {
			padding: "100px 15% 50px 15%",
		},
		"& .mainImageBox": {
			padding: "30px 20px 30px 20px"
		}
	},
	"@media only screen and (max-width: 360px)": {
		"& .boxFirst": {
			padding: "50px 15px 20px 15px"
		},
		"& .mainImageBox": {
			padding: "30px 10px 30px 10px"
		}
	}
})
// Customizable Area End