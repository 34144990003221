import React from "react";
import DashboardController, { Props } from "./DashboardController";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import "./dashboard.css";
import { profile } from "./assets";
import { Divider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { MuiThemeProvider } from '@material-ui/core/styles';


export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  },
  overrides: {
    MuiAccordionDetails: {
      root: {
        padding: "7px 16px 8px", 
      }
    }
  },
});

  

export default class Department extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
 //istanbul ignore next
  render() {
    const { isLoadingEmployees, isLoadingDesignations  } = this.state;
   
    return(
        <>
        <div className="content-wrapper">
            <div className="department-header">
                <h2>Departments</h2>
            </div>

<div style={webStyle.root}>
  {Array.isArray(this.state.departmentListData) && this.state.departmentListData.length > 0 ? (
    this.state.departmentListData.map((department, index) => (
      <Paper
        key={department.id}
        elevation={8}
        onMouseEnter={() => this.handleMouseEnter(index)}
        onMouseLeave={this.handleMouseLeave}
        style={{
          ...webStyle.accordion,
          boxShadow: (this.state.hoveredIndex === index || this.state.expandedDepartment === department.id) 
            ? 'rgba(0, 0, 0, 0.24) 0px 3px 8px' 
            : 'none',
          transition: 'box-shadow 0.3s ease-in-out',
        }}
      >
        <Accordion
          onChange={() => this.handleAccordionToggle(department.id)}
          data-test-id="onclickuserforkpi"
          expanded={this.state.expandedDepartment === department.id}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${department.id}-content`}
            id={`panel${department.id}-header`}
          >
            <Typography style={webStyle.heading as React.CSSProperties}>
              {department.name}
            </Typography>
          </AccordionSummary>
          <Divider />

          {isLoadingDesignations && (
            <span style={{ marginLeft: '10px', display: 'inline-flex' }}>
              <div className="dot-loader">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </div>
            </span>
          )}

          {isLoadingDesignations ? null : (
            Array.isArray(this.state.designationListData) && this.state.designationListData.length > 0 ? (
              this.state.designationListData.map((designation) => (
                <Accordion
                  key={designation.id}
                  onChange={() => this.handleDesignationToggle(designation.id)}
                  expanded={this.state.expandedDesignation === designation.id}
                  style={{
                    ...webStyle.accordion2,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-designation-${designation.id}-content`}
                    id={`panel-designation-${designation.id}-header`}
                  >
                    <Typography style={webStyle.FontColor}>{designation.name}</Typography>
                  </AccordionSummary>

                  {isLoadingEmployees && (
                    <span style={{ marginLeft: '10px', display: 'inline-flex' }}>
                      <div className="dot-loader">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </span>
                  )}

                  {isLoadingEmployees ? null : (
                    Array.isArray(this.state.employeeListData) && this.state.employeeListData.length > 0 ? (
                      this.state.employeeListData.map((employee) => (
                        <MuiThemeProvider theme={theme} key={employee.id}>
                          <AccordionDetails>
                            <span className="profile-img">
                              <img src={profile} alt="profile" width={16} height={20} />
                            </span>
                            <Typography style={webStyle.FontColor}>
                              {employee.first_name} {employee.last_name}
                            </Typography>
                          </AccordionDetails>
                        </MuiThemeProvider>
                      ))
                    ) : (
                      <Typography style={webStyle.Typography}>No employees available.</Typography>
                    )
                  )}
                </Accordion>
              ))
            ) : (
              <Typography style={webStyle.Typography}>No designations available.</Typography>
            )
          )}
        </Accordion>
      </Paper>
    ))
  ) : (
    <Typography style={webStyle.Typography}>No departments available.</Typography>
  )}
</div>
        </div>      
        </>
    )
  }
}

const webStyle={
    root: {
        width: '97%',
        marginLeft: "15px",
        cursor:'pointer' 
        
      },
      heading: {
        fontSize:"18px",
        fontWeight: "bold",
        color:"black"
      },
      accordion: {
        cursor : "pointer",
        margin: "5px 0px", // Adjust the gap as needed
        borderRadius: "20px", // Add border radius
        "&:last-child": {
          marginBottom: 0 // Remove margin bottom for the last accordion
        },
        
      },

      accordion2: {
        margin: "5px 0px", // Adjust the gap as needed
        
      },
    Typography: {
      padding:"10px 18px",fontSize: "15px", color:"Black"
    },
    FontColor: {
      color:'Black',
      fontSize:"18px",
    }
}