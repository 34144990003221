import React from "react";
// Customizable Area Start
import { success } from "./assets";
import ForgotPasswordController, { Props } from "./ForgetPasswordController.web";
// Customizable Area End


export default class SuccessMessageScreen extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        //Customizable Area End
     }

    render()
     {
        return (
            //Customizable Area Start
            <>
               <div className="center-block-header final-screen text-center">
                    <h3>Congratulations!</h3>
                    <p>your password has been Reset sucessfully.</p> 
                    <div className="header-logo">
                        <img src={success} alt='logo' />
                    </div>
                </div>
            </>
            //Customizable Area End
            )
    }
}