import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-hot-toast";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: any;
  isButtonDisabled: boolean;
  passwordData: { newPassword: string, confirmNewPassword: string };
  successMessage: string;
  showPassword: boolean;
  showCPassword: boolean;
  invalidPasswordMessage: string;
  invalidConfirmPasswordMessage: string;
  openModal: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpTokenId: any;
  ResetPwdAPICallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      invalidConfirmPasswordMessage: "",
      invalidPasswordMessage: "",
      showPassword: false,
      showCPassword: false,
      successMessage: "",
      passwordData: {
        newPassword: "",
        confirmNewPassword: ""
      },
      isButtonDisabled: true,
      token: "",
      openModal: false
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    //Customizable Area Start
    //Customizable Area End
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = await message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.otpTokenId) {
        this.getOtp(responseJson)
      }
            if (apiRequestCallId === this.ResetPwdAPICallId) {
        if (responseJson.message) {
          this.setState({ openModal: true })
        }
        else {
          if (responseJson.errors[0].message) {
            this.setState({ invalidPasswordMessage: configJSON.vliadPwdError })
          }
          else {
            toast.error(responseJson.errors[0].token)
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getOtp = (responseJson: any) => {
    if (responseJson && responseJson.meta && responseJson.data) {
      window.localStorage.setItem("otpAuthToken", responseJson.meta.token)
      this.props.navigation.navigate("OTPInputAuthScreen")
    }
    else {
      toast.error(responseJson.errors[0].otp)
      this.setState({ invalidPasswordMessage: 'Email not registered, please contact admin or check email' })
    }
  }

  toggleShowPassword = (pass: boolean, cpass: boolean) => {
    this.setState({
      showPassword: pass,
      showCPassword: cpass
    });
  };


  isValidEmail = (email: any) => {
    const atIndex = email.indexOf("@");
    const dotIndex = email.lastIndexOf(".");

    if (atIndex <= 0 || dotIndex <= atIndex || dotIndex === email.length - 1) {
      return false;
    }
    return true;
  };

  isValidPhoneNumber = (phoneNumber: any) => {
    const phoneNumberRegex = /^\+\d{12}$/;
    return phoneNumberRegex.test(phoneNumber);
  };

  isvalidPassword = (password: any) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);

  }

  handleSubmit = (event: any) => {
    event.preventDefault();
    const val = event.target.email.value;
    let value;
    if (this.isValidPhoneNumber(val)) {
      value = { full_phone_number: val.toString() };
      localStorage.setItem("userInput", JSON.stringify(value));
    } else if (this.isValidEmail(val)) {
      value = { email: val.toString() };
      localStorage.setItem("userInput", JSON.stringify(value));
    } else {
      this.setState({ invalidPasswordMessage: "Please enter a valid email" });
    }

    if (value) {
      this.sendOtpRequest(value);
    }
  };

  handleInputChange = (event: any) => {
    if (event.target) {
      this.setState({ isButtonDisabled: event.target.value === "" });

      if (event.target.value && this.isValidPhoneNumber(event.target.value) || this.isValidEmail(event.target.value)) {

        this.setState({ invalidPasswordMessage: "" })
      }


    }
  };

  handlePasswordChange = (event: any) => {
    const { name, value } = event.target;
    const isValid = this.isvalidPassword(value);
    this.setState((prevState) => ({
      passwordData: {
        ...prevState.passwordData,
        [name]: value,
        token: localStorage.getItem("otpAuthToken")
      },
      invalidPasswordMessage: isValid ? "" : this.state.invalidPasswordMessage,
      invalidConfirmPasswordMessage: ""
    }));

  };
  handleContinue = () => {
    this.setState({
      openModal: false,
    })
    window.history.pushState({}, "", "/EmailAccountLoginBlock")
    window.location.reload()
  }


  handleNewPasswordSubmit = (event: any) => {
    event.preventDefault();
    const { newPassword, confirmNewPassword } = this.state.passwordData;


    if (newPassword !== confirmNewPassword) {
      this.setState({ invalidConfirmPasswordMessage: "New and confirm password does not match" });
      return false;
    }

    else {
      this.ResetPasswordCallapi(this.state.passwordData)
    }



  };
  sendOtpRequest(values: { email?: string; full_phone_number?: string }) {
    const sendOtpHeader = {
      "Content-Type": "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    //GO TO REQUEST STATE
    this.otpTokenId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendOtpEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(sendOtpHeader)
    );
    const data = {
      attributes: values
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  ResetPasswordCallapi = async (values: any) => {
    if (
      this.state.passwordData.newPassword === null ||
      this.state.passwordData.newPassword.length === 0 &&
      this.state.passwordData.confirmNewPassword === null ||
      this.state.passwordData.confirmNewPassword.length === 0
    ) {
      this.setState({ invalidPasswordMessage: "Please enter your new Password" })
      return false;

    }

    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const data = {
      token: values.token,
      new_password: values.newPassword,
      password_confirmation: values.confirmNewPassword
    }

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ResetPwdAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetNewPasswordEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
