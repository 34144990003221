import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { imgBell } from "./assets";
import { Link } from "react-router-dom"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import "../../dashboard/src/dashboard.css"
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box style={{ background: "#F3F4F5", height: "64px", marginTop: "32px"}} >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid className="first" style={{
              padding: '19px 0px 21px 38px',
              display: "flex",
              width: "100%",
              maxWidth: "11%",
              justifyContent: "space-between",
            }} item>
              <p>
                <Link to="/Dashboard">
                  <ArrowBackIosIcon />
                </Link>
              </p>
              <p style={{ color: "black", fontSize: "18px", fontWeight: 400 }}>
                Notifications
              </p>
            </Grid>
          </Grid>
          <Grid container  alignItems="center">
            <div className="notification-items" data-testid="notification-first">
              <div className="notification-item">
                <h5>Congratulations!</h5>
                <div className="notification-content">
                  <p>Your Leave request has been approved.</p>
                </div>
                <div className="notification-detail">
                  <span>Oct 20, 2022 (08:00 pm)</span>
                  <a href="#">clear</a>
                </div>
              </div>
            </div>
            <div className="notification-items" id="notify">
              <div className="notification-item">
                <h5>Congratulations!</h5>
                <div className="notification-content">
                  <p>Your Leave request has been approved.</p>
                </div>
                <div className="notification-detail">
                  <span>Oct 20, 2022 (08:00 pm)</span>
                  <a href="#">clear</a>
                </div>
              </div>
            </div>
            <div className="notification-items" data-testid="leave-notification">
              <div className="notification-item">
                <h5>Congratulations!</h5>
                <div className="notification-content">
                  <p>Your Leave request has been approved.</p>
                </div>
                <div className="notification-detail">
                  <span>Oct 20, 2022 (08:00 pm)</span>
                  <a href="#">clear</a>
                </div>
              </div>
            </div>
            <div className="notification-items" id="notification-item">
              <div className="notification-item">
                <h5>Congratulations!</h5>
                <div className="notification-content">
                  <p>Your Leave request has been approved.</p>
                </div>
                <div className="notification-detail">
                  <span>Oct 20, 2022 (08:00 pm)</span>
                  <a href="#">clear</a>
                </div>
              </div>
            </div>
          </Grid>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  itemWrapper: {
    border: "1px solid #767676",
    display: "flex",
    width: "70%",
    flexDirection: "row" as "row",
    marginTop: 10,
    padding: 16,
    paddingTop: 10,
    cursor: "pointer",
  },
  itemHeadingWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row" as "row",
  },
  iconStyle: {
    width: 20,
    height: 26,
    marginTop: 6,
  },
  itemHeading: {
    color: "#000",
    flex: 1,
    fontWeight: 700,
  },
  itemHeadingRead: {
    color: "#6200EE",
    flex: 1,
    fontWeight: 700,
  },
  contents: {
    fontSize: 16,
    color: "#767676",
  },
  okButton: {
    backgroundColor: "#ccc",
    color: "#000",
  },
  deleteButton: {
    backgroundColor: "#FF0000",
  },
};
// Customizable Area End
