import React from "react";
// Customizable Area Start
import {
  Button,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Box
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Customizable Area End
import AddDkrController, { Props } from "./AddDkrController";
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  }
});
export default class ReportDkr extends AddDkrController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box 
        style={webStyle.Reportdkrmaincontainer}>
          <Grid 
          container justifyContent="space-between" alignItems="center">
            <Grid 
            className="first" style={webStyle.ReportdkrleftSide} item>
              <Grid 
              container spacing={2} alignItems="center">
                <Grid 
                item style={webStyle.ReportdkrrowarrowDisplay}>
                  <ArrowBackIosIcon 
                  style={{ fontSize: "24px", color: "black" }} data-test-id="nextButton1" onClick={this.navigateToEditTemplate2} />
                  <span
                    style={{ ...webStyle.ReportdkrHeadStyleTop1, fontWeight: 400 }}>
                    Edit Template
                  </span>
                </Grid>
                <Grid 
                item style={webStyle.ReportdkrrowarrowDisplay}>
                  <ArrowBackIosIcon 
                  style={{ ...webStyle.ReportdkrHeadStyleTop1, color: "#B2B2B2", fontSize: "16px" }} />
                  <span style={{ ...webStyle.ReportdkrlabelStyleoptionhead as React.CSSProperties, fontWeight: 700, fontSize: "18px" }}>
                    Add DKR Details
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid 
            className="second" 
            style={webStyle.ReportdkrnullContainer} item />
          </Grid>
          <Grid
            container
            alignItems="center"
            style={webStyle.ReportdkrinformContainer as React.CSSProperties}>
            <Grid
              className="first"
              item
              style={webStyle.ReportdkrinformSecondContainerHead}
              xs={12}>
              <div>
                <FormControl 
                component="fieldset">
                  <FormLabel 
                  component="legend" style={webStyle.ReportdkrheaderMain10}>
                    <span style={webStyle.ReportdkrHeadtopStyle22 as React.CSSProperties}>KPI Details</span>
                  </FormLabel>

                </FormControl>
              </div>
              <div 
              className="form-field text-center" id="signupBlock4">
                <div 
                className="control" 
                id="signupfieldcontrol2">
                  <Button 
                  style={webStyle.Editbutton as React.CSSProperties} data-test-id="EditButton" onClick={this.navigateToEditKpi}>
                    Edit KPI Details
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.ReportdkrinformationSubject}
              xs={12} spacing={6}>
              <FormControl 
              component="fieldset">
                <FormLabel 
                component="legend" 
                style={webStyle.ReportdkrheaderMain10}>
                  <span style={{ ...webStyle.ReportdkrlabelStyleoptionhead as React.CSSProperties, fontSize: "22px", fontWeight: 500 }}>Type</span>
                </FormLabel>
                <span 
                style={{ ...webStyle.ReportdkrblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>Report</span>
              </FormControl>
            </Grid>
            <Grid
              className="second"
              item
              style={webStyle.ReportdkrinformationSubject}
              xs={12}>
              <FormControl 
              component="fieldset">
                <FormLabel component="legend" style={{ ...webStyle.ReportdkrheaderMain10, marginTop: "30px" }}>
                  <span style={{ ...webStyle.ReportdkrlabelStyleoptionhead as React.CSSProperties, fontWeight: 500, fontSize: "22px" }}>Basic Details</span>
                </FormLabel>
                <Grid container style={{ display: "flex" }} xs={12}>
                  <Grid item xs={3}>
                    <div className="form-block" id="signupBlock3">
                      <form>
                        <div
                          id="signupform"
                          className="form-field"
                          style={webStyle.ReportdkrtopdescThirty}>
                          <span style={webStyle.ReportdkrTopstyleHeader as React.CSSProperties}>Title</span>
                          <div
                            id="signupfieldcontrol"
                            className="control"
                            style={webStyle.ReportdkrmarginLeft0}>
                            <span style={webStyle.ReportdkrblurvalueStyle as React.CSSProperties}>Report</span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Grid>
                  <>
                    <Grid item xs={6}>
                      <div className="form-block" id="signupBlock3">
                        <form>
                          <div
                            className="form-field"
                            style={webStyle.ReportdkrtopdescThirty}
                            id="signupform"
                          >
                            <span style={webStyle.ReportdkrTopstyleHeader as React.CSSProperties}>Description</span>
                            <div
                              className="control"
                              style={webStyle.ReportdkrmarginLeft0}
                              id="signupfieldcontrol"
                            >
                              <span style={{ ...webStyle.ReportdkrblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>1 Missed Submission :8 <br />2 Missed Submission:10 <br />3 Missed Submission:0</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="form-block" 
                      id="signupBlock3">
                        <form>
                          <div
                            id="signupform"
                            className="form-field"
                            style={{ ...webStyle.ReportdkrtopdescThirty, width: "max-content" }}
                          >
                            <span style={webStyle.ReportdkrTopstyleHeader as React.CSSProperties}>Points (Weightage)</span>
                            <div
                              id="signupfieldcontrol"
                              className="control"
                              style={webStyle.ReportdkrmarginLeft0}
                            >
                              <span style={{ ...webStyle.ReportdkrblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>10 points</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Grid>
                  </>
                </Grid>
              </FormControl>
            </Grid >



          </Grid >
          <Divider light 
          style={{ marginTop: "20px", marginBottom: "20px" }} />
          <Grid
            alignItems="center"
            container
            style={webStyle.ReportdkrinformContainer as React.CSSProperties}
          >
            <Grid
              item
              className="first"
              style={webStyle.ReportdkrinformSecondContainerHead}
              xs={12}
            >
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.ReportdkrheaderMain10}>
                    <span style={webStyle.ReportdkrHeadtopStyle22 as React.CSSProperties}>Daily KPI Report</span>
                  </FormLabel>

                </FormControl>
              </div>
            </Grid>
            <Grid
              item
              className="first"
              style={webStyle.ReportdkrinformationSubContainerTable}
              xs={12}  >
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.Reportdkrheadermandatory}>
                    <span style={{ ...webStyle.labelStyle10 as React.CSSProperties, fontWeight: 500 }}>Applicable Occurrence</span>
                  </FormLabel>
                </FormControl>
                <TableContainer style={webStyle.ReportdkrtablemainContainer}>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow style={webStyle.ReportdkrmaintableHead}>
                        <TableCell 
                        style={{ fontFamily: "Open Sans", fontSize: "14px", color: "black", fontWeight: 600, paddingLeft: "70px", width: "33%" }}>Occurrence Title</TableCell>
                        <TableCell style={{ fontFamily: "Open Sans", fontSize: "14px", 
                          color: "black", fontWeight: 600, width: "33%" }}>
                          Deductible Value
                        </TableCell>
                        <TableCell style={{ width: "18%" }}>
                          <Button onClick={this.handleAddRow} style={{ fontFamily: 'Open Sans', 
                            backgroundColor: "FDD100", color: "black", marginRight: "20px", width: "220px", fontSize: "16px", fontWeight: 600, textTransform: "none", paddingTop: "6px", paddingBottom: "6px", borderRadius: "10px" }}>
                            Add Occurrence
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.rows.map((row: any, index: any) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row" style={{ fontFamily: "Open Sans", fontSize: "14px", color: "black", paddingLeft: "65px" }}>{index + 1}. Missed Occurrence</TableCell>
                          <TableCell>
                            <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"
                                  id="signupform"
                                >
                                  <Box
                                    className="control"
                                    style={webStyle.ReportdkrmarginLeft0}
                                    id="signupfieldcontrol"
                                  >
                                    <input
                                      type="text"
                                      data-test-id="txtInputEmail"
                                      style={{ ...webStyle.inputmainTable, backgroundColor: "White", width: "30%", color: "Black", textAlign: "start", fontFamily: "Open Sans", fontWeight: 400, fontSize: "14px" }}
                                      placeholder={row.points}
                                      className="control-input"
                                      name="email"
                                      id="pronounce-control"
                                    />
                                  </Box>
                                </Box>
                              </form>
                            </Box>
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <a data-test-id="clickforRemoveReport"
                            onClick={() => this.handleRemoveRow(index)} 
                            style={{ color: "#ff5c5c", textDecoration: "underline" }}>Remove Occurrence</a>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

              </>
            </Grid>
          </Grid >

          <Grid style={webStyle.ReportdkrbuttonmainContainer}>
            <Box style={{ marginTop: '50px', marginBottom: '40px' }}>
              <Button 
              style={webStyle.ReportdkrSubmitBtn as React.CSSProperties}>
                Submit
              </Button>
              <Button variant="outlined" 
              style={webStyle.ReportdkrCancelBtn as React.CSSProperties} >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Box >
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  

  ReportdkrlabelStyleoptionhead: {
    color: "black",
    fontWeight: 500,
    fontSize: "22px",
    fontFamily: "Open Sans"
  },
  ReportdkrTopstyleHeader: {
    fontSize: "18px",
    color: "#c8ccc9",
    fontWeight: 700,
    fontFamily: "Open Sans"
  },
  ReportdkrblurvalueStyle: {
    color: "black",
    marginTop: "10px",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: 400
  },
  ReportdkrrowarrowDisplay: {
    alignItems: "center",
    display: "flex",
  },
  ReportdkrheaderMain10: {
    display: "flex",
    alignItems: "baseline",
    gap: "4px",
    MarginTop: "30px"
  },
  ReportdkrSubmitBtn: {
    fontFamily: 'Open Sans',
    marginRight: "20px",
    color: "black",
    fontSize: "16px",
    width: "170px",
    backgroundColor: "FDD100",
    fontWeight: 600,
    textTransform: "none",

  },

  inputmainTable: {
    background: 'transparent',
    border: '0.5px solid #979797',
    width: "70%",
  },
  Editbutton: {
    backgroundColor: "FDD100",
    color: "black",
    fontFamily: 'Open Sans',
    padding: "15px 20px 15px 20px",
    width: "200px",
    fontSize: "16px",
    borderRadius: "10px",
    fontWeight: 600,
    textTransform: "none"
  },


  ReportdkrHeadStyleTop1: {
    color: "black",
    fontFamily: "Open Sans",
    fontSize: "18px",
  },
  ReportdkrHeadtopStyle22: {
    color: "black",
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Open Sans"
  },

  ReportdkrmarginLeft0: {
    marginLeft: 0,
    marginTop: 10
  },

  ReportdkrbuttonmainContainer: { display: "flex", gap: "20px", margin: "0 40px" },
  ReportdkrCancelBtn: {
    fontWeight: 600,
    fontFamily: 'Open Sans',
    textTransform: "none",
    backgroundColor: "white",
    fontSize: "16px",
    width: "170px",
    color: "black",
  },
  informatSubContainerTable: { marginTop: "24px", width: "98%" },
  ReportdkrinformSecondContainerHead: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "24px",
    alignItems: "initial",
    width: "98%"
  },
  ReportdkrtopdescThirty: {
    marginTop: "30px",
  },
  container: {
    height: "100%",
    width: "100%",
  },


  TableC: {
    fontSize: "14px",
    color: "black",
    fontWeight: 600,
    fontFamily: "Open Sans",
  },

  labelStyle10: {
    fontSize: "22px",
    fontFamily: "Open Sans",
    fontWeight: 500,
    color: "black",
  },
  Reportdkrheadermandatory: {
    display: "flex",
    MarginTop: "30px",
    alignItems: "baseline",
    gap: "4px",
  },
  ReportdkrtablemainContainer: {
    marginTop: "15px",
    borderRadius: "10px",
    border: "1px solid #979797",
  },
  ReportdkrinformationSubContainerTable: {
    marginTop: "24px",
    width: "98%",
  },

  Reportdkrmaincontainer: {
    background: "#F3F4F5",
    height: "64px",
    marginRight: "-20px"
  },
  ReportdkrinformContainer: {
    padding: "0 32px",
    flexDirection: "column",
    alignItems: "baseline",
  },
  ReportdkrinformationSubject: {
    marginTop: "24px",
    width: "800px",
  },
  ReportdkrleftSide: {
    padding: "19px 0px 21px 38px"
  },
  ReportdkrnullContainer: {
    padding: "0px 44px 0px 0px"
  },
  ReportdkrmaintableHead: {
    backgroundColor: "#F5F5F5",
  },
};
// Customizable Area End
