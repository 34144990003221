import React from "react";
// Customizable Area Start
import {
  Button,
  Typography,
  FormControl,
  FormLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  Box
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Customizable Area End
import AddDkrController, { Props } from "./AddDkrController";
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  }
});
export default class ChecklistView extends AddDkrController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.Checkviewmaincontainer}>
          <Grid 
          justifyContent="space-between" 
          container 
          alignItems="center">
            <Grid 
            item
            style={webStyle.CheckviewleftSide} 
            className="first" 
            >
              <Grid 
              container 
              alignItems="center"
              spacing={2} 
              >
                <Grid item style={webStyle.CheckviewrowarrowDisplay}>
                  <ArrowBackIosIcon style={{ fontSize: "24px", color: "black" }} data-test-id="nextButton1" onClick={this.navigateToEditTemplate2} />
                  <span
                    style={{...webStyle.CheckviewHeadStyleTop1,fontWeight: 400}}>
                    Edit Template
                  </span>
                </Grid>
                <Grid 
                item 
                style={webStyle.CheckviewrowarrowDisplay}>
                  <ArrowBackIosIcon style={{...webStyle.CheckviewHeadStyleTop1,color:"#B2B2B2",fontSize:"16px"}} />
                  <span style={{...webStyle.CheckviewlabelStyleoptionhead as React.CSSProperties,fontWeight:700,fontSize:"18px"}}>
                    View KPI Details
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="second" style={webStyle.CheckviewnullContainer} item />
          </Grid>
          <Grid
            alignItems="center"
            container
            style={webStyle.CheckviewinformContainer as React.CSSProperties}>
            <Grid
              className="first"
              item
              style={webStyle.CheckviewinformSecondContainerHead}
              xs={12}>
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.CheckviewheaderMain10}>
                    <span style={webStyle.CheckviewHeadtopStyle22 as React.CSSProperties}>KPI Details</span>
                  </FormLabel>

                </FormControl>
              </div>
              <div className="form-field text-center" id="signupBlock4">
                <div className="control" id="signupfieldcontrol2">
                  <Button style={webStyle.CheckviewEditbutton as React.CSSProperties} data-test-id="EditButton" onClick={this.navigateToEditKpi}>
                    Edit KPI Details
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.CheckviewinformationSubject}
              xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" style={webStyle.CheckviewheaderMain10}>
                  <span style={{ ...webStyle.CheckviewlabelStyleoptionhead as React.CSSProperties, fontSize: "22px" ,fontWeight: 500}}>Type</span>
                </FormLabel>
                <span style={{...webStyle.CheckviewblurvalueStyle as React.CSSProperties,fontWeight:400}}>Checklist</span>
              </FormControl>
            </Grid>
            <Grid
  className="second"
  item
  style={webStyle.CheckviewinformationSubject}
  xs={12}
>
  <FormControl component="fieldset">
    <FormLabel component="legend" style={{ ...webStyle.CheckviewheaderMain10, marginTop: "30px" }}>
      <span style={{ ...webStyle.CheckviewlabelStyleoptionhead as React.CSSProperties, fontWeight: 500, fontSize: "22px" }}>
        Basic Details
      </span>
    </FormLabel>
    
    {Array.isArray(this.state.showTemplateData) && (
          this.state.showTemplateData.map((template:any, index:any) => (
    <Grid container style={{ display: "flex" }} spacing={6} xs={12}>
      <Grid item xs={4}>
        <div className="form-block" id="signupBlock3">
          <form>
            <div className="form-field" id="signupform" style={webStyle.CheckviewtopdescThirty}>
              <span style={webStyle.CheckviewTopstyleHeader as React.CSSProperties}>Title</span>
              <div className="control" id="signupfieldcontrol" style={webStyle.CheckviewmarginLeft0}>
                <span style={webStyle.CheckviewblurvalueStyle as React.CSSProperties}>{template.attributes.name}</span>
              </div>
            </div>
          </form>
        </div>
      </Grid>

      <Grid item xs={6}>
        <div className="form-block" id="signupBlock3">
          <form>
            <div className="form-field" id="signupform" style={webStyle.CheckviewtopdescThirty}>
              <span style={webStyle.CheckviewTopstyleHeader as React.CSSProperties}>Description</span>
              <div className="control" id="signupfieldcontrol" style={webStyle.CheckviewmarginLeft0}>
                <span style={{ ...webStyle.CheckviewblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>
                {template.attributes.description}
                </span>
              </div>
            </div>
          </form>
        </div>
      </Grid>

      <Grid item xs={2}>
        <div className="form-block" id="signupBlock3">
          <form>
            <div className="form-field" id="signupform" style={{ ...webStyle.CheckviewtopdescThirty, width: "max-content" }}>
              <span style={webStyle.CheckviewTopstyleHeader as React.CSSProperties}>Points (Weightage)</span>
              <div className="control" id="signupfieldcontrol" style={webStyle.CheckviewmarginLeft0}>
                <span style={{ ...webStyle.CheckviewblurvalueStyle as React.CSSProperties, fontWeight: 400 }}>{template.attributes.weightage} points</span>
              </div>
            </div>
          </form>
        </div>
      </Grid>
    </Grid>
          )))}
  </FormControl>
</Grid>


        

          </Grid >
          <Divider light style={{ marginTop: "20px" , marginBottom:"20px"}} />
          <Grid
            container
            alignItems="center"
            style={webStyle.CheckviewinformContainer as React.CSSProperties}
          >
            <Grid
              className="first"
              item
              style={webStyle.CheckviewinformSecondContainerHead}
              xs={12}
            >
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.CheckviewheaderMain10}>
                    <span style={webStyle.CheckviewHeadtopStyle22 as React.CSSProperties}>Daily KPI Report</span>
                  </FormLabel>

                </FormControl>
              </div>
              <div className="form-field text-center" id="signupBlock4">
                <div className="control" id="signupfieldcontrol2">
                  <Button style={webStyle.CheckviewEditbutton as React.CSSProperties} data-test-id="EditButton" onClick={this.navigateToEditKpi}>
                    Edit KPI Details
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.CheckviewinformationSubject}
              xs={12}
            >
             <Box>
                <Typography style={webStyle.CheckviewTypography1}>Reminder Description</Typography>
                <span style={{...webStyle.CheckviewblurvalueStyle as React.CSSProperties,fontWeight:400}}>Reminder</span>
              </Box>
              
            </Grid>
            <Grid
              className="first"
              item
              style={webStyle.CheckviewinformationSubContainerTable}
              xs={12}  >
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend" style={webStyle.Checkviewheadermandatory}>
                    <span style={{...webStyle.CheckviewlabelStyle100 as React.CSSProperties,fontWeight:500}}>Applicable DKRs</span>
                  </FormLabel>
                </FormControl>
                <TableContainer style={webStyle.CheckviewtablemainContainer}>
                  <Table aria-label="customized table">
                  <TableHead>
                      <TableRow style={webStyle.CheckviewmaintableHead}>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"35px",width:"8%"}}><span style={{fontWeight:600}}>Sr.</span><span style={{fontWeight:600}}>No.</span></TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",fontWeight:600,width:"15%"}}>Checklist Options</TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",fontWeight:600,width:"32%"}}>
                        Checklist Options Description
                        </TableCell>
                        <TableCell style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",fontWeight:600,width:"10%"}}>
                        Value
                        </TableCell>
                        <TableCell style={{paddingLeft:"30px",width:"22%"}}>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {Array.isArray(this.state.showTemplateData) &&
  this.state.showTemplateData.map((template: any, index: any) => (
                    <TableBody>
                      {template.attributes.dkr_information.data.attributes.applicable_dkrs.data.map(
        (occurrence: any, idx: any) => (
              <TableRow>
                 <TableCell component="th" scope="row" style={{fontFamily:"Open Sans",fontSize:"14px",color:"black",paddingLeft:"65px"}}>{idx + 1}.</TableCell>
                 <TableCell component="th" scope="row" style={{fontFamily:"Open Sans",fontSize:"14px",color:"black"}}>{occurrence.attributes.title}</TableCell>

                <TableCell>
                   <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  id="signupform"
                                  className="form-field"
                                >
                                  <Box
                                    className="control"
                                    style={webStyle.CheckviewmarginLeft0}
                                    id="signupfieldcontrol"
                                  >
                                 <span style={{fontFamily:"Open Sans",
                                  fontSize:"14px",color:"black"}}>{occurrence.attributes.description}</span>

                                  </Box>
                                </Box>
                              </form>
                            </Box>
                </TableCell>
                <TableCell>
                   <Box className="form-block" id="signupBlock3">
                              <form>
                                <Box
                                  className="form-field"  id="signupform"
                                >
                                  <Box
                                    style={webStyle.CheckviewmarginLeft0}
                                    className="control"
                                    id="signupfieldcontrol"
                                  >
                          <span style={{
                            fontFamily:"Open Sans",fontSize:"14px",color:"black"
                            }}>{occurrence.attributes.value} points</span>
                                  </Box>
                                </Box>
                              </form>
                            </Box> </TableCell> <TableCell>
                </TableCell>
              </TableRow>
              ))}
                    </TableBody>
                    ))}
                  </Table>
                </TableContainer>
              
              </>
            </Grid>
          </Grid >

          <Grid style={webStyle.CheckviewbuttonmainContainer}>
            <Box style={{ marginTop: '50px', marginBottom: '40px' }}>
              <Button style={webStyle.CheckviewSubmitBtn as React.CSSProperties}>
                Submit
              </Button>
              <Button variant="outlined" style={webStyle.CheckviewCancelBtn as React.CSSProperties} >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Box >
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
   
    CheckviewTypography1:{
      color: 'black', 
      fontSize: "18px", 
      fontFamily: 'Open Sans', 
      fontWeight: 700, 
      marginBottom: '15px'
     },
    CheckviewmaintableHead: {
        backgroundColor: "#F5F5F5",
      },
    CheckviewlabelStyle100: {
      color: "black",
      fontSize: "22px",
      fontWeight: 500,
      fontFamily:"Open Sans",
      marginTop: '30px',
      marginBottom: '10px'
      },
    Checkviewheadermandatory: {
        alignItems: "baseline",
        MarginTop:"30px",
        display: "flex",
        gap: "4px",
      },
      CheckviewtablemainContainer: { 
        marginTop: "15px" ,
        borderRadius: "10px", 
        border: "1px solid #979797",
        },
    CheckviewinformationSubContainerTable: {
        width: "98%" ,
        marginTop: "24px",
        },
  
    Tcell:{
        fontFamily:"Open Sans",
        fontSize:"14px",
        color:"black"
      },
     
  CheckviewEditbutton:{
    fontFamily: 'Open Sans', 
    color: "black", 
    padding: "15px 20px 15px 20px", 
    borderRadius: "10px", 
    backgroundColor: "FDD100", 
    width: "200px", 
    fontWeight: 600, 
    textTransform: "none",
    fontSize: "16px", 
  },
  Captured: {
    fontSize: "14px",
    fontFamily: "Open Sans",
    color: "black",
    width: "20%"
  },

  CheckviewCancelBtn: {
    fontWeight: 600,
    fontSize: "16px",
    textTransform: "none",
    width: "170px",
    fontFamily: 'Open Sans',
    backgroundColor: "white",
    color: "black",
  },
  CheckviewSubmitBtn: {
    backgroundColor: "FDD100",
    color: "black",
    fontFamily: 'Open Sans',
    fontSize: "16px",
    width: "170px",
    fontWeight: 600,
    marginRight: "20px",
    textTransform: "none",
    
  },
  TCell: {
    fontFamily: "Open Sans",
    color: "black",
    fontSize: "14px",
    fontWeight: 600
  },
  TableC: {
    fontSize: "14px",
    color: "black",
    fontFamily: "Open Sans",
    fontWeight: 600
  },

  Checkviewmaincontainer: {
    height: "64px",
    marginRight: "-20px",
    background: "#F3F4F5",
  },
  CheckviewinformContainer: {
    padding: "0 32px",
    flexDirection: "column",
    alignItems: "baseline",
  },
  CheckviewinformationSubject: {
    marginTop: "24px",
    width: "60%",
    marginBottom: "30px"
  },
  CheckviewleftSide: {
    padding: "19px 0px 21px 38px"
  },
  CheckviewnullContainer: {
    padding: "0px 44px 0px 0px"
  },

  CheckviewinformSecondContainerHead: {
    marginTop: "24px",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "initial",
    width: "98%"
  },
  CheckviewtopdescThirty: {
    marginTop: "25px",
  },
  container: {
    width: "100%",
    height: "100%",
  },

  CheckviewlabelStyleoptionhead: {
    color: "black",
    fontWeight: 500,
    fontSize: "22px",
    fontFamily: "Open Sans"
  },
  CheckviewTopstyleHeader: {
    fontSize: "18px",
    color: "#c8ccc9",
    fontWeight: 700,
    fontFamily: "Open Sans"
  },
  CheckviewblurvalueStyle: {
    color: "black",
    marginTop: "10px",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: 400
  },
  CheckviewrowarrowDisplay: {
    alignItems: "center",
    display: "flex",
  },
  CheckviewHeadStyleTop1: {
    color: "black",
    fontFamily: "Open Sans",
    fontSize: "18px",
  },
  CheckviewHeadtopStyle22: {
    color: "black",
    fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Open Sans"
  },
  inputField: {
    width: "60%",
    border: '0.5px solid #979797',
    background: 'transparent',
  },
  inputFieldTabless: {
    width: "70%",
    border: '0.5px solid #979797',
    background: 'transparent',
  },
  CheckviewmarginLeft0: {
    marginLeft: 0,
    marginTop: 10
  },
  CheckviewbuttonmainContainer: { display: "flex", gap: "20px", margin: "0 40px" },
  themeColorback: {
    color: "#FDD100",
    fontFamily: "Open sans",
    fontWeight: 400,
    fontSize: "14px"
  },
  CheckviewheaderMain10: {
    display: "flex",
    alignItems: "baseline",
    gap: "4px",
    MarginTop: "30px"
  },
 
};
// Customizable Area End
