import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import toast from "react-hot-toast";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  firstName: any;
  phone: string;
  countryCode: any;
  lastName: any;
  email: any;
  employId: any;
  department: any;
  selectedDepartment: string;
  designation: any;
  role: string;
  reportingTo: any;
  country: any;
  selectedCountry: any;
  state: any;
  selectedState: any;
  city: any;
  selectedCity: any;
  doj: any;
  employtype: any;
  companyName: any;
  timeZone: any;
  selectedtimeZone: any;
  shiftTime: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SingleUserUploadController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCountriesId: string = "";
  createEmployeId: string = "";
  getCitiesId: string = "";
  getDepartmentListId: string = "";
  getTimeZoneId: string = "";
  getStatesOfCountriesId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      firstName: "",
      phone: "",
      countryCode: "",
      lastName: "",
      email: "",
      employId: "",
      department: [],
      selectedDepartment: "",
      designation: "",
      role: "",
      reportingTo: "",
      country: [],
      state: [],
      city: [],
      selectedCountry: "",
      selectedState: "",
      selectedCity: "",
      doj: "",
      employtype: "",
      companyName: "",
      timeZone: [],
      selectedtimeZone: "",
      shiftTime: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.errors) {
          let msg: any = Object.values(responseJson.errors[0])[0];
          toast.error(msg);
        } else {
          switch (apiRequestCallId) {
            case this.getCountriesId:
              this.setState({ country: responseJson.countries });
              break;
            case this.getCitiesId:
              this.getCitiesResponse(responseJson);
              break;
            case this.getStatesOfCountriesId:
              this.getStateResponse(responseJson);
              break;
            case this.getTimeZoneId:
              this.getTimezoneResponse(responseJson);
              break;
            case this.getDepartmentListId:
              this.getDepartmentResponse(responseJson);
              break;
            case this.createEmployeId:
              this.employeeCreatedResponse(responseJson);
              break;
            default:
              break;
          }
        }
      }
    }
    // Customizable Area End
  }

  // web events
  handlePhoneNumberChange = (phone: string, countryData: any) => {
    this.setState({ countryCode: countryData, phone: phone });
  };
  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState({ [name]: value } as any);
  };

  handleChangeCompanyName = (e: any) => {
    this.setState({ companyName: e });
    this.getDepartmentList(e);
  };
  handleSelectCountry = (e: any) => {
    this.setState({ selectedCountry: e.target.value });
    this.getStates(e.target.value);
    this.getTimeZone(e.target.value);
  };
  handleSelectState = (e: any) => {
    this.setState({ selectedState: e.target.value });
    this.getCities(e.target.value);
  };
  handleSelectCity = (e: any) => {
    this.setState({ selectedCity: e.target.value });
  };

  handleReset = () => {
    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      phone: "",
      selectedDepartment: "",
      designation: "",
      role: "",
      reportingTo: "",
      selectedCountry: "",
      selectedState: "",
      selectedCity: "",
      doj: "",
      employtype: "",
      companyName: "",
      selectedtimeZone: "",
      shiftTime: "",
      employId: ""
    });
  };
  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getCountries();
  }
  getStateResponse = (responseJson: any) => {
    if (responseJson) {
      const stateNamesInEnglish = Object.values(responseJson.states).map(
        (state: any) => state
      );
      this.setState({ state: stateNamesInEnglish });
    }
  };
  employeeCreatedResponse = (responseJson: any) => {
    this.setState({ employId: responseJson.data.attributes.employee_id });
    toast.success(responseJson.meta.message);
  };

  getTimezoneResponse = (responseJson: any) => {
    if (responseJson) {
      const stateNamesInEnglish = Object.values(responseJson.time_zone).map(
        (state: any) => state
      );
      this.setState({ timeZone: stateNamesInEnglish });
    }
  };
  getDepartmentResponse = (responseJson: any) => {
    if (responseJson) {
      const stateNamesInEnglish = Object.values(
        responseJson.department_list
      ).map((state: any) => state);
      this.setState({ department: stateNamesInEnglish });
    }
  };
  getCitiesResponse = (responseJson: any) => {
    if (responseJson) {
      const stateNamesInEnglish = Object.values(responseJson.cities).map(
        (state: any) => state
      );
      this.setState({ city: stateNamesInEnglish });
    }
  };
  getStates = (value: any) => {
    const httpsHeaderForStates = {
      "Content-Type": configJSON.dashboarContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStatesOfCountriesId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.stateEndpoint}?country=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(httpsHeaderForStates)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getDepartmentList = (value: any) => {
    const httpsHeaderForStates = {
      "Content-Type": configJSON.dashboarContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDepartmentListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.departmentEndpoint}?company_name=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(httpsHeaderForStates)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getTimeZone = (value: any) => {
    const httpsHeaderForStates = {
      "Content-Type": configJSON.dashboarContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTimeZoneId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.timeZoneEndpoint}?country=${value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(httpsHeaderForStates)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCountries = () => {
    const httpsHeaderForStates = {
      "Content-Type": configJSON.dashboarContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountriesId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.countryEndpoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(httpsHeaderForStates)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCities = (value: any) => {
    const httpsHeaderForStates = {
      "Content-Type": configJSON.dashboarContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCitiesId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cityEndpoint}?state=${value}&country=${
        this.state.selectedCountry
      }`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(httpsHeaderForStates)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleCreateEmployee = () => {
    const httpHeader = {
      "Content-Type": configJSON.dashboarContentType
    };
    const {
      employtype,
      shiftTime,
      companyName,
      doj,
      selectedCity,
      selectedCountry,
      selectedState,
      selectedtimeZone,
      firstName,
      lastName,
      email,
      countryCode,
      phone,
      selectedDepartment,
      designation,
      role,
      reportingTo
    } = this.state;

    let httpBody = {
      data: {
        attributes: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          work_full_phone_number: countryCode + phone,
          department_name: selectedDepartment,
          designation: designation,
          role: role,
          reporting_to: reportingTo,
          country: selectedCountry,
          state: selectedState,
          city: selectedCity,
          date_of_joining: doj,
          employee_type: employtype,
          company_name: companyName,
          zone: selectedtimeZone,
          shifting_time: shiftTime
        }
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createEmployeId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createEmployEndpoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(httpHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
