import React from "react";

import {
  Box,
  Button,
  Typography,
  Grid,
  Tooltip,
  Modal,
  Badge, styled, Paper
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";




// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";


// Customizable Area End

import GamificationController, {
  Props,
  configJSON,
} from "./GamificationController";
import { arrow_left, cross, info_sqaure_bold, info_square, message_dots } from "./assets";
export default class Gamification extends GamificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  showStatus = (statusName: string) => {
    let statusLabel = '';
    if (statusName === 'Attendance') {
      statusLabel = 'Absent';
    } else if (statusName === 'Timing') {
      statusLabel = 'Late';
    } else if (statusName === 'Report') {
      statusLabel = 'Missed';
    } else {
      statusLabel = "";
    }

    return (
      <span
        style={{
          color: '#FF5C5C',
          fontFamily: 'Open Sans',
          fontSize: '16px',
          fontWeight: 600,
        }}
      >
        {statusLabel}
      </span>
    );
  };
  // Customizable Area End

  render() {
    const { open, data, statusName } = this.state;
    return (
      // Customizable Area Start
      <>
        <HeaderSection>
          <img data-test-id="goback" onClick={() => this.Goback("EmployeeDashboard")} style={{ height: '14px', width: '7px' }} src={arrow_left} alt="<" />
          <Typography style={{
            fontFamily: 'Open Sans',
            fontSize: '18px',
            fontWeight: 400,
            color: '#000000',
            lineHeight: '20px',
          }}>
            KPI Gamecard</Typography>
        </HeaderSection>
        <Box style={{ padding: "20px 25px" }}>
          <Grid container spacing={2}>
            {data.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Box
                  style={{
                    display: "flex",
                    cursor: 'pointer',
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: item.chat ? '#FFFDF6' : "#F3F3F3",
                    padding: "14px 20px",
                    borderRadius: "8px",
                    border: item.chat ? '0.5px solid #FFCC00' : '0.5px solid #0000003E',
                  }}
                  onClick={() => this.subDashboard(("ViewKpiDetails"), item.label)}
                >
                  <LbaelParentBox >
                    <LabelTypography>
                      {item.label}
                    </LabelTypography>
                    <WeightagePointsTypography >
                      <span>Weightage - {item.weightage} Points</span>
                      <StyledTooltip
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        title={
                          <div>
                            No Extra Leave: 10 Pts<br />
                            1 Extra Leave: 5 Pts<br />
                            2+ Extra Leaves: 0 Pts
                          </div>} placement="right-start"
                      >
                        <StyledInfoSquare
                          src={info_square}
                          alt="i"
                        />
                      </StyledTooltip>
                    </WeightagePointsTypography>
                  </LbaelParentBox>
                  <CommentBox >
                    {!item.chat ? (
                      <img
                        style={{
                          height: '19px',
                          width: '21px',
                          filter: 'unset',
                          cursor: "pointer",
                        }}
                        src={message_dots}
                        alt="messages"
                        data-test-id="handleOpen2"
                        onClick={(event) => {
                          event.stopPropagation();
                          this.handleOpen(item.label);
                        }}
                      />
                    ) : (
                      <StyledBadge data-test-id="handleOpen"
                        badgeContent={1}
                        overlap="circular"
                        onClick={() => this.handleOpen(item.label)}
                      >
                        <StyledImageContainer
                          style={{ filter: 'invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(1)' }}
                          src={message_dots}
                          alt="messages"
                          data-test-id="handleOpen1"
                          onClick={(event) => {
                            event.stopPropagation();
                            this.handleOpen(item.label);
                          }}
                        />
                      </StyledBadge>
                    )}
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#FFCC00",
                        color: "#000000",
                        borderRadius: "10px",
                        fontWeight: 600,
                        textTransform: "none",
                        padding: '6px 12px',
                        cursor: 'pointer',
                        boxShadow: 'none',
                      }}
                    >
                      <span style={{
                        fontFamily: "Open Sans",
                        fontSize: "14px",
                        fontWeight: 500,
                        color: '#000000',
                      }}> Points - {item.points}</span>
                    </Button>
                  </CommentBox>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Modal open={open}
          onClose={this.handleClose}>
          <Box style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'white',
            padding: '26px 38px',
            borderRadius: '8px',
          }}>
            <ModalHeaderBox >
              <Typography style={{
                fontFamily: 'Open Sans',
                fontSize: '18px',
                fontWeight: 700,
                color: '#000000'
              }}>{statusName}</Typography>
              <CrossButton
                data-test-id="handleClose"
                onClick={this.handleClose}
              ><img src={cross} alt='X' /></CrossButton>
            </ModalHeaderBox>
            <Box style={{ margin: '24px 0 20px 0' }}>
              <Typography style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '6px' }}><span style={{
                fontFamily: 'Open Sans',
                fontSize: '16px',
                fontWeight: 400,
                color: '#000000',
              }}>Date</span> <span style={{
                fontFamily: 'Open Sans',
                fontSize: "16px",
                fontWeight: 600,
                color: '#000000',
              }}
              >03-03-2024</span></Typography>
              <Typography style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '6px' }}><span style={{
                fontFamily: 'Open Sans',
                fontSize: '16px',
                fontWeight: 400,
                color: '#000000',
              }} >Time of Arrival</span><span style={{
                fontFamily: 'Open Sans',
                fontSize: "16px",
                fontWeight: 600,
                color: '#000000'
              }}>10:15 AM</span></Typography>
              <Typography style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '6px' }}><span style={{
                fontFamily: 'Open Sans',
                fontSize: '16px',
                fontWeight: 400,
                color: '#000000',
              }}>Status</span>

                {this.showStatus(statusName)}
              </Typography>
            </Box>
            <Box>
              <ManagerRemarkTypography>Manager's Remark</ManagerRemarkTypography>
              <DummyModalContentBox ><StyledSpan >Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur.</StyledSpan></DummyModalContentBox>
            </Box>
          </Box>
        </Modal>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const HeaderSection = styled(Paper)({
  padding: '20px 40px 20px 32px',
  height: '64px',
  fontFamily: 'Open Sans',
  marginBottom: '20px',
  background: '#F3F4F5',
  display: 'flex',
  alignItems: 'center',
  gap: '21px',
});
const StyledImageContainer = styled('img')({
  height: '19px',
  width: '21px',
  cursor: "pointer",
});
const StyledTooltip = styled(Tooltip)({
})
const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    backgroundColor: '#FFCC00',
    color: '#000000',
    minWidth: '14px',
    height: '14px',
    padding: '0',
    fontSize: '10px',
    fontWeight: 600,
    position: 'absolute',
    top: '0',
    left: '7px',
    cursor: 'pointer',
  },
});
const StyledInfoSquare = styled('img')({
  marginLeft: '10px',
  cursor: 'pointer',
  height: '17px',
  width: '17px',
  transition: '0.3s',
  '&:hover': {
    content: `url(${info_sqaure_bold})`,
    cursor: 'pointer',
  },
});
const ModalHeaderBox = styled(Box)({
  display: "flex",
  justifyContent: 'space-between',
  alignItems: 'center',
})
const DummyModalContentBox = styled(Box)({
  background: '#F7F7F7',
  padding: '14px',
  borderRadius: '8px',
  border: '0.25px solid #00000047'
})
const ManagerRemarkTypography = styled(Typography)({
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 700,
  color: '#000000',
  marginBottom: '5px',
})
const LabelTypography = styled(Typography)({
  cursor: 'pointer',
  color: '#000000',
  fontFamily: 'Open Sans',
  fontSize: '18px',
  fontWeight: 700
})
const LbaelParentBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '350px'
})
const WeightagePointsTypography = styled(Typography)({
  color: '#000000',
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 500,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})
const CrossButton = styled(Button)({
  background: '#EBEBEB',
  padding: '11px',
  borderRadius: '6px',
  minWidth: '40px'

})
const CommentBox=styled(Box)({
  display: 'flex',
   justifyContent: 'center', 
   alignItems: 'center',
    gap: '20px'
})
const StyledSpan = styled('span')({
  fontFamily: 'Open Sans',
   fontSize: '16px',
    fontWeight: 400, 
    color: '#000000'
})