import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { kpimainlogo, kpipasswordlogo, kpiverifylogo, imgPasswordInVisible } from "./assets";
import toast from 'react-hot-toast';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  confirmPassword: string;
  email: string;
  emailVerified: number;
  logoImage: any;
  header: any;
  otp: string;
  timer: number;
  isTimerRunning:boolean;
  subHeader: any;
  showPassword: boolean;
  showConfirmPassword: boolean;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  errorMessage: string;
  passworderrorMessage: string;
  confirmPassworderrorMessage: string;
  token: string;
  openModal: boolean;

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apisendOtpCallId: string = "";
  apiverifyOtpCallId: string = "";
  apisetPasswordCallId: string = "";
  labelTitle: string = "";
  timerId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      passworderrorMessage: "",
      confirmPassworderrorMessage: "",
      errorMessage: "",
      email: "",
      password: "",
      confirmPassword: "",
      enablePasswordField: true,
      otp: "",
      emailVerified: 0,
      timer: 30,
      isTimerRunning: true,
      logoImage: kpimainlogo,
      header: "Welcome!",
      subHeader: "Enter your Email ID to signup",
      showPassword: false,
      showConfirmPassword: false,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      token: "",
      openModal: false
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.setInitial()
    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.emailVerified !== prevState.emailVerified) {
      this.setInitial()
    }
  }

  setInitial = () => {
    if (this.state.emailVerified === 2) {
      this.setState({
        header: "Password Setup",
        subHeader: "Enter new password",
        logoImage: kpiverifylogo
      })
    } else if (this.state.emailVerified === 1) {
      this.setState({
        header: "Verify email",
        subHeader: "Enter 6 digit code sent to your Email ID",
        logoImage: kpipasswordlogo
      })
      this.startTimer();
    } else {
      this.setState({
        header: "Welcome!",
        subHeader: "Enter your Email ID to signup",
        logoImage: kpimainlogo
      })
    }
  }
  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };
  setConfirmPassword = (text: string) => {
    this.setState({
      confirmPassword: text,
    });
  };
  formatTimer = (seconds: any) => {
    return seconds < 10 ? `0${seconds}` : seconds;
  };
  toggleShowPassword = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword,
    }));
  };
  toggleConfirmShowPassword = () => {
    this.setState(prevState => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  };
  handleResendClick = () => {
    this.sendOtp()
    this.setState({ timer: 30, isTimerRunning: true }, () => {
      this.startTimer();
    });
  };
  startTimer = () => {
    this.timerId = setInterval(() => {
      this.setState(
        (prevState) => ({
          timer: prevState.timer - 1,
        }),
        () => {
          if (this.state.timer === 0) {
            clearInterval(this.timerId);
            this.setState({ isTimerRunning: false });
          }
        }
      );
    }, 1000);
  };
  async componentWillUnmount() {
    clearInterval(this.timerId);
  }

  handleOtpChange = (otp: any) => {
    this.setState({ otp });
  };

  // Web Event Handling
  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  handleOtpResponse = (errors: any, meta: any) => {
    if (errors) {
      toast.error(errors.failed_login);
    } else {
      this.setState({
        token: meta.token,
        emailVerified: 1,
      });
      toast.success("OTP sent successfully");
    }
  }

  handleOtpVerificationResponse = (responseJson: any) => {
    const { errors, message } = responseJson;
    if (errors) {
      toast.error(errors.otp);
    } else {
      toast.success(message);
      this.setState({
        token: responseJson.token,
        emailVerified: 2,
      });
    }
  }

  handleSetPasswordResponse = (errors: any) => {
    if (errors) {
      toast.error(errors[0]?.token);
    } else {
      this.setState({
        openModal: true,
      });
    }
  }
  async sendOtp(): Promise<boolean> {
    const emailSchema = Yup.string().email("Email not registered")
    const isValid = emailSchema.isValid(this.state.email).then((isValid: any) => { return isValid });

    if (await isValid) {
      this.setState({ errorMessage: "" })
    } else {
      this.setState({ errorMessage: "Email not registered" })
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      attributes: {
        email: this.state.email,
      }
    };
    const httpBody = {
      data: attrs,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apisendOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendOtpEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  async verifyOtp(): Promise<boolean> {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": this.state.token
    };
    const httpBody = {
      data: {
        pin: this.state.otp
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiverifyOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpConfirmation
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  async setPasswordCall(): Promise<boolean> {
    const passwordSchema = Yup.string()
      .min(8, 'Password should be a minimum of 8 characters long.')
      .matches(/[a-z]/, 'Password should contain at least one lowercase letter.')
      .matches(/[A-Z]/, 'Password should contain at least one uppercase letter.')
      .matches(/\d/, 'Password should contain at least one digit.')
      .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password should contain at least one special character.')
      .required('Password is required.');
    const isValid = async (password: any) => {
      try {
        await passwordSchema.validate(password, { abortEarly: false });
        return true;
      } catch (errors) {
        return errors;
      }
    };
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ confirmPassworderrorMessage: configJSON.errorForPasswordAndConfirmPasswordNotValid })
      return false
    }
    const validationResult :any = await isValid(this.state.password); if (validationResult === true) {
      
      this.setState({ confirmPassworderrorMessage: "", passworderrorMessage: "" })
    } else {
      this.setState({ passworderrorMessage:validationResult.errors[0],confirmPassworderrorMessage: validationResult.errors[0]})
      return false;
    }
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": this.state.token
    };
    const attrs = {
      attributes: {
        password: this.state.password,
      }
    };
    const httpBody = {
      data: attrs,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apisetPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setPassword
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = await message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        const { errors, meta } = responseJson;
        if (apiRequestCallId === this.apisendOtpCallId) {
          if (errors || meta) {
            this.handleOtpResponse(errors, meta);
          } else {
            toast.success(responseJson.account.message);
          }
        } else if (apiRequestCallId === this.apiverifyOtpCallId) {
          this.handleOtpVerificationResponse(responseJson);
        } else if (apiRequestCallId === this.apisetPasswordCallId) {
          this.handleSetPasswordResponse(errors);
        }
      }
    }
    // Customizable Area End
  }

}
