import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import toast from "react-hot-toast";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Row {
  id: number;
  title: string;
  description: string;
}
export interface CalculationRow {
  id: string;
  min: number;
  max: number;
  weightage:number
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: string
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  type: string;
  description: string;
  automatic: boolean;
  runRate: boolean;
  analytics: boolean;
  goaltype: string;
  tableRow:Row[];
  calculationType:CalculationRow[];
  offset:number;
  progressPercentage: number;
  chartData:any;
  storedLabel:string;
  checklistsOptions:any;
  checked:boolean;
  open:boolean;
  pointsAchieved:number;
  newPointsAchieved:number;
  submissions:any[];
  toggle:boolean;
 
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddDkrDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      type: "prorata",
      description: "",
      automatic: false,
      runRate: false,
      analytics: false,
      goaltype: "number",
      storedLabel:'',
      open:false,
      pointsAchieved:120,
      newPointsAchieved:0,
      tableRow: [
        { id: 1, title: "Captured DKR", description: "Today's Sales" },
        { id: 2, title: "Gamified DKR", description: "Total Sales" }
      ],
      calculationType:[
        { id: "1", min: 0, max: 90,weightage:2 },
        { id: "2", min: 90.1, max: 100,weightage:8 }

      ],
      offset: 2 * Math.PI * 50, 
      progressPercentage: 150,
      chartData:{
        series: [
          {
            name: 'Goal',
            data: [0, 100, 200, 400,400,600,1000,2500,2300,1800,1500,1000,600,400,200,100, 0],
          },
        ],
        options: {
          chart: {
            type: 'area',
            height: 350,
            toolbar: {
              show: false,
            },
          },
          colors: ['#FFCC00'],
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.4,
              opacityTo: 0.4,
              stops: [0, 100],
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: 2,
            colors: ['#FFCC00'],
          },
          xaxis: {
            categories: Array.from({ length: 16 }, (_, i) => i * 2 + 1).slice(0, 32),
            title: {
              text: 'Days of Month',
            },
          },
          yaxis: {
            title: {
              text: 'Goal',
            },
            labels: {
              formatter: function (value:any) {
                return value / 1000 + 'k';
              },
            },
            tickAmount: 4,
            min: 0,
            max: 4000,
          },
          grid: {
            borderColor: '#e7e7e7',
          },
        },
      },
      checklistsOptions : [
        { name: 'Vision Posted'},
        { name: 'Core Values Posted'},
        { name: 'Daily Pledge'},
        { name: 'P&L / SET Done',},
        { name: 'Budget Tracking'},
      ],
      checked:false,
      submissions : [
        { date: "01-03-2024", report: "Submitted", attendance: "Present", timing: "On Time" },
        { date: "02-03-2024", report: "Submitted", attendance: "Present", timing: "On Time" },
        { date: "03-03-2024", report: "Missed", attendance: "Absent", timing: "Late" },
        { date: "04-03-2024", report: "Submitted", attendance: "Present", timing: "On Time" },
        { date: "05-03-2024", report: "Missed", attendance: "Absent", timing: "Late" },
        { date: "06-03-2024", report: "Submitted", attendance: "Present", timing: "On Time" },
    ],
    toggle:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const storedLabel = await getStorageData('selectedLabel');
    if (storedLabel) {
      this.setState({ storedLabel });
    }
    const randomProgress = 92
    this.setProgress(randomProgress);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.errors) {
          let message: string;
          const error = responseJson.errors[0];

          if (typeof error === 'string') {
            message = error;
            toast.error(message);
          }
        }
      }
    }
    // Customizable Area End
  }

  // web events
  handleChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: string;
  }>) => {
    this.setState({
      type: event.target.value
    });
  };
  handleAddItem = () => {
    const newItem = { id: '', min: 0, max: 0, weightage: 0 }; // You can adjust the initial values as needed
    this.setState(prevState => ({
      calculationType: [...prevState.calculationType, newItem]
    }));
  };

  handleRemoveItem = (index:number) => {
    this.setState(prevState => ({
      calculationType: prevState.calculationType.filter((_, i) => i !== index)
    }));
  };
 
  // Customizable Area Start
  setProgress(progress :any) {
    const radius = 50;
    const circumference = 2 * Math.PI * radius;
    const progressOffset = ((100 - progress) / 100) * circumference;
    this.setState({ offset: progressOffset, progressPercentage: progress });
  }


  backNavigate =(pathChange:any)=>{
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pathChange);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  handleChangeChecbox =(event:any)=>{
    this.setState(event.target.checked);
  }
  handleOpen = () => {
    this.setState({  open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  
 
  handleInputChange = (event:any)=>{
  const inputValue=event?.target.value
  this.setState({newPointsAchieved:inputValue})
  }
  handleCloseSubmit = () => {
    this.setState({ open: false });
    this.setState({pointsAchieved:this.state.newPointsAchieved})
  };
  
  GoToDailySubmissions =(pathChange:any)=>{
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pathChange);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };
  toggleEffect = () => {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));
  };
  // Customizable Area End
}
