import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  templateAdd:any
  tagTemplateModal:boolean,
  isduplicateOpen:boolean,
  isOpen:boolean,
  selectedTemplateData:any,
  selectedTemplateName:string,
  selectedTemplateCheckBox:number,
  getTemplateName:any,





  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TemplateAddedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTemplateAddedResponseID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      templateAdd:[],
      tagTemplateModal:false,
      isduplicateOpen:false,
      isOpen:false,
      selectedTemplateData:[],
      selectedTemplateName:'',
      selectedTemplateCheckBox:2,
      getTemplateName:[],






      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
   const dId= localStorage.getItem("DepartmentNavigationId");
   this.getTemplateResponses(dId)
    // Customizable Area End
  }



    // Customizable Area Start
    async receive(from: string, message: Message) {
      // Customizable Area Start
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(apiRequestCallId === this.getTemplateAddedResponseID) {
          this.getTemplateAddedDetail(responseJson)
      }
      }
      // Customizable Area End
    }
    // Customizable Area End
  navigateToEditTemplate2=()=>{
    this.props.navigation.navigate("EditTemplate2")
  };
  getTemplateAddedDetail = (responseJson: any) => { 
    if (responseJson) {
      this.setState({ templateAdd: responseJson.data });
    } 
  }
  getTemplateResponses = (departmentID:any) => {

    const fetchTemplateAddedReponseHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token:localStorage.getItem("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTemplateAddedResponseID = requestMessage.messageId;
    const endpoint = `bx_block_gamification/template_filter?department_id=${departmentID}`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
      
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchTemplateAddedReponseHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area Start
  // Customizable Area End
}
