import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Grid,
  Modal,
  styled,
  Button,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, withStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { version_update, terms_condition, privacy, email_notification, BigCross, RightArrow } from "./assets";
import Switch from '@material-ui/core/Switch';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import SettingsController, {
  Props,
  configJSON,
} from "./SettingsController";


export default class Settings extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, modalTitle, privacyPolicyContent, termsOfUseContent, loading } = this.state;

    function formatPrivacyPolicyText(text: string) {
      return text
        .replace(/\r\n/g, "<br />") 
        .replace(/ {2,}/g, (spaces) => "&nbsp;".repeat(spaces.length)) 
        .replace(/\\"/g, '"');
    }
    const PrivacyPolicyComponent = () => (
      <div dangerouslySetInnerHTML={{ __html: formatPrivacyPolicyText(privacyPolicyContent) }} />
    );
    const termsOfUseComponent = () => (
      <div dangerouslySetInnerHTML={{ __html: formatPrivacyPolicyText(termsOfUseContent) }} />
    );

    const accordions = [
      {
        title: 'Email Notification',
        content: 'General Accordion Content',
        icon: <img src={email_notification} alt="logo" width={24} height={24} />,
      },
      {
        title: 'Change Password',
        content: 'Change Password Accordion Content',
        icon: <img src={version_update} alt="logo" width={24} height={24} />,
      },
      {
        title: 'Privacy Policy',
        content: 'Privacy Policy Accordion Content',
        icon: <img src={privacy} alt="logo" width={24} height={24} />,
      },
      {
        title: 'Terms Of Use',
        content: 'Terms Of Use Accordion Content',
        icon: <img src={terms_condition} alt="logo" width={24} height={24} />
      },
    ]

    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <ParentBox >
          <InnerGrid container justifyContent="space-between" alignItems="center" >
            <NewStyledGrid className="first" item>
              <SettingsTypography>Settings</SettingsTypography>
            </NewStyledGrid>
          </InnerGrid>

          {loading ? (
            <CircularProgressBox >
              <CircularProgress style={{ color: '#FDD001' }} />
            </CircularProgressBox>
          ) : (
            <>
              <ParentGrid container alignItems="center" >
                {accordions.map((accordion: any, index: number) => (
                  <Grid key={index} className="first" item style={{ width: '100%' }}>
                    <Accordion
                      expanded={false}
                      style={webStyle.accordion}
                      className="accordion-wrapper"
                    >
                      <AccordionSummary
                        onClick={() => this.handleClickAccordion(accordion.title)}
                        expandIcon={
                          accordion.title === 'Email Notification' && (
                            <AntSwitch
                              data-test-id="accordionClickId"
                              checked={this.state.emailNotification}
                              onClick={this.emailNotificationFunction}
                              name="checked"
                              id={`switch-${index}`}
                            />
                          )
                        }
                        aria-controls={`panel${index + 1}-content`}
                        id={`panel${index + 1}-header`}
                        className="accordion-border accordion-wrapper"
                      >
                        <span style={{ marginRight: '12px' }} className="img-wrapper">
                          {accordion.icon}
                        </span>
                        <Typography style={webStyle.heading as React.CSSProperties}>
                          {accordion.title}
                        </Typography>
                      </AccordionSummary>
                    </Accordion>
                  </Grid>
                ))}
                <ChangePasswordImageBox data-test-id="ChangePasswordImageBoxId" onClick={() => this.navigateToPassword('ChangePassword')}>
                  <img src={RightArrow} alt=">" />
                </ChangePasswordImageBox>
                <PrivacyPolicyImageBox data-test-id="PrivacyPolicyImageBoxId" onClick={() => this.handleOpen('Privacy Policy')}>
                  <img src={RightArrow} alt=">" />
                </PrivacyPolicyImageBox>
                <TermsOfUseImageBox data-test-id="TermsOfUseImageBoxId" onClick={() => this.handleOpen('Terms of Use')}>
                  <img src={RightArrow} alt=">" />
                </TermsOfUseImageBox>
              </ParentGrid>
            </>
          )}

        </ParentBox>
        {modalTitle == "Terms Of Use" ? (
          <Modal open={open} >
            <ModalBox>
              <ModalTitleBox>
                <HeadingTypography>{modalTitle}</HeadingTypography>
                <img onClick={this.handleClose}
                  style=
                  {{
                    position: 'absolute',
                    right: 0,
                    cursor: 'pointer', paddingRight: '51px'
                  }}
                  src={BigCross}
                  alt='X' />
              </ModalTitleBox>
              <ContentBox data-test-id="ContextBoxId" >
                <ContentTypography>
                  {termsOfUseComponent()}
                </ContentTypography>
              </ContentBox>
              <SubmitBoxContainer >
                <SubmitBox
                  data-test-id="handleCloseSubmitId"
                  onClick={this.handleClose}
                  style={{ cursor: 'pointer' }}>
                  <IAgreeTypography> I Agree</IAgreeTypography></SubmitBox>
              </SubmitBoxContainer>
            </ModalBox>
          </Modal>) : (<Modal open={open} >
            <ModalBox >
              <ModalTitleBox >
                <HeadingTypography>{modalTitle}</HeadingTypography>
                <CrossImageStyled data-test-id="closeModalId" onClick={this.handleClose}  src={BigCross} alt='X' />
              </ModalTitleBox>
              <ContentBox>
                <ContentTypography>
                  {PrivacyPolicyComponent()}
                </ContentTypography>
              </ContentBox>
            </ModalBox>
          </Modal>)
        }
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  heading: {
    fontSize: "18px",
    fontWeight: "500",
    color: '#000'
  },
  accordion: {
    marginBottom: "20px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "12px",
    "&:last-child": {
      marginBottom: 0
    },
    '& .MuiButtonBase-root': {
      padding: '16px 40px',
      '& .MuiAccordionSummary-content': {
        alignItems: 'center',
        margin: 0
      },
    }
  },
};

const AntSwitch = withStyles(theme => ({
  root: {
    width: 46,
    height: 24,
    padding: 0,
    display: "flex",
    "& .MuiIconButton-label": {
      margin: 0
    }
  },
  switchBase: {
    padding: 2,
    "&$checked": {
      transform: "translateX(22px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#FDD001",
        borderColor: 'transparent'
      }
    }
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: "none",
    margin: '2px'
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 1,
    opacity: 1,
    backgroundColor: "#bcbeca"
  },
  checked: {}
}))(Switch);

const ModalBox = styled(Box)({
  position: 'fixed',
  padding: '55px 0px 55px 51px',
  maxWidth: '634px',
  width: '100%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  borderRadius: '8px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '10px',
    position: 'absolute',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#D8D8D8',
    borderRadius: '5px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#D8D8D8',
  },
  scrollbarWidth: 'thin',
  scrollbarColor: '#D8D8D8',
});
const SubmitBox = styled(Button)({
  maxWidth: '210px',
  width: '100%',
  background: '#FFCC00',
  padding: '14px',
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  borderRadius: '8px',
  marginTop: '40px',
  '&:hover': {
    backgroundColor: '#FFCC00',
    color: '#000000',
    cursor: 'pointer',
  },
})
const IAgreeTypography = styled(Typography)({
  color: '#000000',
  fontWeight: 600,
  fontSize: '16px',
  cursor: 'pointer',
  lineHeight: '20px'
})
const ContentBox = styled(Box)({
  height: '350px',
  overflow: 'auto',
  marginBottom: '22px',
})
const HeadingTypography = styled(Typography)({
  fontSize: '24px',
  fontWeight: 600,
  color: '#000000',
  lineHeight: '20px',
  textAlign: 'center',
  flex: 1,
  marginBottom: '45px',
  paddingRight: '51px',
})
const ContentTypography = styled(Typography)({
  color: '#000000',
  fontSize: '16px',
  fontWeight: 600,
  marginBottom: '40px',
  lineHeight: '22px',
  paddingRight: '51px'
})
const ModalTitleBox = styled(Box)({
  display: "flex",
  justifyContent:
    'space-between',
  position: 'relative'
})
const ChangePasswordImageBox = styled(Box)({
  position: 'absolute',
  right: '64',
  top: '100',
  cursor: "pointer",
})
const PrivacyPolicyImageBox = styled(Box)({
  position: 'absolute',
  right: '64',
  bottom: '118',
  cursor: "pointer",
})
const TermsOfUseImageBox = styled(Box)({
  position: 'absolute',
  right: '64',
  bottom: '40',
  cursor: "pointer",
})
const SubmitBoxContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%', paddingRight: '51px'
})
const SettingsTypography = styled(Typography)({
  color: "black",
  fontSize: "18px",
  fontWeight: 400
})
const ParentBox = styled(Box)({
  background: "#F3F4F5",
  height: "64px",
  position: "relative"
})
const CircularProgressBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100px'
})
const ParentGrid = styled(Grid)({
 padding: '0 32px',
  position: 'relative'
})
const NewStyledGrid=styled(Grid)({
   padding: '19px 0px 21px 38px'
})
const InnerGrid=styled(Grid)({
   marginBottom: '40px'
})
const CrossImageStyled=styled('img')({
  position: 'absolute',
   right: 0,
    cursor: 'pointer',
     paddingRight: '51px'
})
// Customizable Area End
